import React from 'react'
import { Link } from 'react-router-dom'

import Container from '../../components/ui/container'
import Icon from '../../components/ui/icon'
import Typography from '../../components/ui/typography'

import Theme from '../../context/theme'
import ParkLeisureHelp from './ParkLeisureHelp'
import ParkHolidaysHelp from './ParkHolidaysHelp'

const Help = () => {

  const { theme: {isPl2k} } = React.useContext(Theme);

  return (
  <Container style={{ marginTop: 32 }}>
    <Link to="/login" style={{ textDecoration: 'underline' }}>
      <Typography beforeEnhancer={<Icon name="navigate_before" />}>
        Back to Login
      </Typography>
    </Link>
    <br />

    <Typography as="h1">Need help logging in?</Typography>
    <Typography as="p">
      If you have are having trouble logging in, please note you will need to
      register your account when you log in to the new website for the first
      time. All website user accounts from the previous website are no longer
      valid.
    </Typography>

    <div>
      <Typography as="h3">
        If you had access to the old website, follow these steps to update your
        password:
      </Typography>
      <ol>
        <li>
          <Typography>
            Click on the “Register a New Account” link below the log in details.
          </Typography>
        </li>
        <li>
          <Typography>
            On the Register a New Account page, fill in the required fields and
            click ‘Create account’
          </Typography>
        </li>
        <li>
          <Typography>
            An email will be sent your inbox, click the provided link in the
            email you receive to verify your account.
          </Typography>
        </li>
      </ol>
    </div>

    <div>
      <Typography as="h3">
        If this is your first time to visit the website, follow these steps:
      </Typography>
      <ol>
        <li>
          <Typography>
            Click on the “Register a New Account” link below the log in details.
          </Typography>
        </li>
        <li>
          <Typography>
            On the Register a New Account page, fill in the required fields –
            ensuring you use the same email address you booked your holiday with
            and click ‘Create account’
          </Typography>
        </li>
        <li>
          <Typography>
            An email will be sent your inbox, click the provided link in the
            email you receive to verify your account.
          </Typography>
        </li>
      </ol>
    </div>

    <div>
      <Typography as="h3">
        If you have registered an account, but unable to log in, follow these
        steps:
      </Typography>
      <ol>
        <li>
          <Typography>
            Click on the “Reset my account password” link below the log in
            details.
          </Typography>
        </li>
        <li>
          <Typography>
            An email will be sent your inbox, click the provided link in the
            email you receive to update your password.
          </Typography>
        </li>
        { !isPl2k ?
          <li>
          <Typography>
            If you continue to have problems logging in, please contact us or
            our Reservations Team on 0343 178 7070
          </Typography>
          </li>
          :
          null
        }
      </ol>
    </div>

    <div>
      <Typography as="h3">Still experiencing issues?</Typography>
      <Typography as="p">
        Please contact us by sending us an email to&nbsp;
        { isPl2k ? <ParkLeisureHelp /> : <ParkHolidaysHelp/> }
      </Typography>
    </div>
  </Container>
)
}

export default Help
