function ownerBalance(price) {
  let priceSuffix = ''
  if (isNaN(price)) return '0.00'
  if (price > 0) {
    priceSuffix = 'CREDIT'
  } else if (price < 0) {
    priceSuffix = 'DEBIT'
  }

  return `${Math.abs(price).toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
  })} ${priceSuffix}`
}

export default ownerBalance
