export const BASE_MY_PH_URL = process.env.REACT_APP_MY_PH_BASE_URL || 'https://my.parkleisure.com/'

export const BASE_MY_PL_URL = process.env.REACT_APP_MY_PL_BASE_URL || 'https://my.parkleisure.com/'

export const PL_PRIVACY_POLICY =
  `${process.env.REACT_APP_PL_BASE_URL}/privacy-policy` ||
  'https://www.parkleisure.co.uk/privacy-policy'

export const PL_TNC =
  `${process.env.REACT_APP_PL_BASE_URL}/terms-and-conditions` ||
  'https://www.parkleisure.co.uk/terms-and-conditions'

export const PL_HELP = 'ownersloungesupport@parkleisure.co.uk'

export const PH_HELP = 'onlinesupport@parkholidays.com'
