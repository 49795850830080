export const MY_PARK_LEISURE = 'My Park Leisure'
export const MY_PARK_HOLIDAYS = 'My Park Holidays'
export const OWNERSHIP = 'ownership'
export const CORPORATE = 'corporate'
export const CORPORATE_DARK = 'corporateDark'
export const PL2KBLUE = 'pl2kBlue'
export const OWNERS_ACCOUNT_LOGO = 'owners account logo'

export const PH_PREFERENCES = ['BULK EMAIL', 'PHONE CALL', 'SMS', 'POST']
export const PL_PREFERENCES = ['PL BULK EMAIL', 'PL PHONE CALL', 'PL SMS', 'PL POST']

// Assets
export const PL_OWNERS_ACCOUNT_LOGO =
  'https://parkholidays.s3.amazonaws.com/static_assets/svg_logo/PL-ownersaccount-logo-small.svg'
export const PH_LOGO =
  'https://assets.parkholidays.com/static_assets/svg_logo/white_redflag.svg'

export const PL_LOGO_TRANSPARENT = "https://parkholidays.s3.amazonaws.com/static_assets/pl-logo-transparent.png"
