import React from 'react';
import PropTypes from 'prop-types';
import { IoIosWarning, IoIosCard } from 'react-icons/io';
import { Error, ErrorMessage } from './style';

const icons = {
  card: IoIosCard,
  warning: IoIosWarning,
};

const ErrorComponent = ({ icon, text }) => {
  const IconComponent = icons[icon];
  return (
    <Error>
      <IconComponent size={32} />
      {typeof text === 'object' ? (
        text.map(item => <ErrorMessage key={item}>{item}</ErrorMessage>)
      ) : (
        <ErrorMessage>{text}</ErrorMessage>
      )}
    </Error>
  );
};

ErrorComponent.propTypes = {
  icon: PropTypes.oneOf(['card', 'warning']),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

ErrorComponent.defaultProps = {
  icon: 'warning',
};

export default ErrorComponent;
