import { styled } from 'styletron-react';

const sharedStyles = {
  backgroundColor: '#fff',
  borderTop: '1px solid #ddd',
};

const TransactionCardRoot = styled('div', () => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '16px 32px',
  boxSizing: 'border-box',
  ...sharedStyles,
}));

export const TransactionTableRow = styled('tr', () => ({ ...sharedStyles }));

export const TransactionTableCell = styled('td', () => ({
  padding: '20px 8px',
  ':first-child': { paddingLeft: '16px' },
  ':last-child': { paddingRight: '16px' },
}));

export const TransactionCell = styled('div', () => ({
  flexShrink: 0,
  maxWidth: '100%',
}));

export default TransactionCardRoot;
