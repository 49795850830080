/* eslint-disable import/prefer-default-export */
import { styled } from 'styletron-react';

/**
 * Base Box Component
 *
 * @returns
 */
const ContentRoot = styled('main', ({ $theme }) => ({
  ...$theme.typography.base,
  position: 'relative',
  flex: 1,
  paddingTop: $theme.mixins.toolbar.minHeight,
  backgroundColor: $theme.palette.background,
  color: $theme.palette.text,
}));

export default ContentRoot;
