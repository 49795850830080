import PropTypes from 'prop-types';
import React from 'react';

import Appbar, { AppbarBrand } from '../../components/ui/appbar';
import BrandLogo from '../../components/ui/brandLogo';
import Navbar from '../../components/ui/navbar';
import Spacer from '../../components/ui/spacer';
import MenuButton from './menu-button';

import { CORPORATE, OWNERS_ACCOUNT_LOGO } from '../../constants'

/**
 * Application Header Bar Component
 *
 * @returns
 */
const AppHeader = React.memo(({ sideMenuOpen, noMenu, onMenuButtonClick }) => {

  return (
  <Appbar color={CORPORATE}>
    <AppbarBrand to="/">
      <BrandLogo logoName={OWNERS_ACCOUNT_LOGO} width='150'/>
    </AppbarBrand>
    <Spacer />
    {!noMenu ? (
      <Navbar>
        <MenuButton color={CORPORATE} active={sideMenuOpen} onClick={onMenuButtonClick} />
      </Navbar>
    ) : null}
  </Appbar>
  )
});

export default AppHeader;

AppHeader.propTypes = {
  sideMenuOpen: PropTypes.bool,
  noMenu: PropTypes.bool,
  onMenuButtonClick: PropTypes.func,
};

AppHeader.defaultProps = {
  sideMenuOpen: true,
  noMenu: false,
  onMenuButtonClick: undefined,
};
