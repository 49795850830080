import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import FormGroup from '../ui/formgroup'
import FormField, { PasswordField } from '../ui/formfield'
import Button from '../ui/button'
import Anchor from '../ui/anchor'
import Icon from '../ui/icon'

const propTypes = {
  theme: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  notification: PropTypes.node,
  isCompleted: PropTypes.bool,
};

function LoginForm({ theme, isCompleted, notification, onSubmit, ...otherProps }) {
  const [emailAddress, setEmailAddress] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()

    setIsLoading(true)
    onSubmit(emailAddress, password)
  }  

  useEffect(() => {
    if (isCompleted && isLoading) setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted])

  return (
    <>
      {notification}
      <form onSubmit={e => handleSubmit(e)} {...otherProps} autocomplete="off">
          <FormGroup>
            <FormField
              id="email"
              type="email"
              label="Email Address"
              labelProps={{ color: 'white' }}
              placeholder="i.e. Joebloggs@gmail.com"
              fullWidth
              onChange={e => setEmailAddress(e.target.value)}
              required
              value={emailAddress}
            />
          </FormGroup>

          <FormGroup>
            <PasswordField
              autoComplete="off"
              id="password"
              type="password"
              label="Password"
              labelProps={{ color: 'white' }}
              fullWidth
              onChange={e => setPassword(e.target.value)}
              required
              value={password}
            />
          </FormGroup>

          <Button
            color="corporateDark"
            fullWidth
            align="center"
            endIcon={<Icon name="chevron-right" />}
            style={{ marginBottom: theme.spacing(2) }}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Login'}
          </Button>

          <Anchor href="/forgot-password" color="white">
            Forgot Password?
          </Anchor>
          &nbsp;&nbsp;

          <Anchor
            href="/help"
            color="inherit"
            style={{ textDecoration: 'underline' }}
          >
            Need help?
          </Anchor>
        </form>
    </>
  )
}

LoginForm.propTypes = propTypes
LoginForm.defaultProps = {
  isCompleted: false,
  notification: undefined
}

export default LoginForm
