import React from 'react';
import PropTypes from 'prop-types';

import Typography from '../../ui/typography';
import TransactionCardRoot, { TransactionCell,
  TransactionTableRow,
  TransactionTableCell } from '../style';

const formatedDate = value => new Date(value).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' });

const JobCard = ({ job }) => {
  const styles = {
    cell50: {
      flexBasis: '50%',
      width: '50%',
    },

    descriptionCell: {
      flexBasis: '100%',
      width: '100%',
      padding: '16px 0 0',
    },
  };

  const addedDate = job.addedDate ? formatedDate(job.addedDate) : 'N/A';

  return (
    <TransactionCardRoot>
      <TransactionCell $style={styles.cell50}>
        <Typography>{job.status}</Typography>
      </TransactionCell>

      <TransactionCell $style={{ ...styles.cell50, textAlign: 'right' }}>
        <Typography as="small">{job.no}</Typography>
      </TransactionCell>

      <TransactionCell $style={{ ...styles.cell50, ...styles.descriptionCell }}>
        <Typography as="small" color="corporate">
          {addedDate}
        </Typography>

        <br />

        <Typography color="corporate" weight="bold">
          {job.description}
        </Typography>
      </TransactionCell>
    </TransactionCardRoot>
  );
};

const JobRow = ({ job }) => {
  const addedDate = job.addedDate ? formatedDate(job.addedDate) : 'N/A';

  return (
    <TransactionTableRow>
      <TransactionTableCell>
        <Typography>{job.status}</Typography>
      </TransactionTableCell>

      <TransactionTableCell>
        <Typography color="corporate">{addedDate}</Typography>
      </TransactionTableCell>

      <TransactionTableCell>
        <Typography color="corporate" weight="bold">
          {job.description}
        </Typography>
      </TransactionTableCell>

      <TransactionTableCell $style={{ textAlign: 'right' }}>
        <Typography as="small">{job.no}</Typography>
      </TransactionTableCell>
    </TransactionTableRow>
  );
};

const MaintenanceJob = ({ item, isMobile }) => (isMobile ? <JobCard job={item} /> : <JobRow job={item} />);

export default MaintenanceJob;

const jobType = PropTypes.shape({
  no: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
  addedDate: PropTypes.string,
});

MaintenanceJob.propTypes = {
  item: jobType.isRequired,
  isMobile: PropTypes.bool,
};

MaintenanceJob.defaultProps = { isMobile: false };

JobCard.propTypes = { job: jobType.isRequired };
JobRow.propTypes = { job: jobType.isRequired };
