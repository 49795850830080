import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from './style';
import { Label } from '../../style';

export default ({ label, children }) => {
  return (
    <FormControl>
      <Label>{label}</Label>
      {children}
    </FormControl>
  );
};
