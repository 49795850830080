/** @flow */
import React from 'react'
import PropTypes from 'prop-types'

/** Components */
import Button, { IconButton } from '../button'
import Icon from '../icon'

const styles = {
  buttonBorder: { border: '1px solid #E0E0E0' },
  buttonMargin: { marginLeft: -1 },
  prevButton: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
  nextButton: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
}

/**
 * Navigation Bar
 *
 * @param {*} props
 * @returns
 */
const Pagination = ({
  onChange,
  currentPage,
  pageCount,
  size,
  ...otherProps
}) => {
  let offset = 0
  if (window.innerWidth >= 600) offset = 2
  if (window.innerWidth >= 768) offset = 3

  const rangeStart = currentPage - offset > 1 ? currentPage - offset : 2
  const rangeEnd =
    currentPage + offset > pageCount - 1 ? pageCount - 1 : currentPage + offset

  const pages = []
  for (let i = rangeStart; i <= rangeEnd; i++) {
    pages.push(i)
  }

  const wrapperStyles = {
    ...(otherProps.style || {}),
    display: 'inline-flex',
    alignItems: 'center',
  }

  return (
    <div style={wrapperStyles}>
      <IconButton
        variant="text"
        color="corporate"
        size={size}
        style={{
          ...styles.buttonBorder,
          ...styles.prevButton,
          width: '44px',
          height: '38px',
        }}
        disabled={currentPage === 1}
        onClick={() => onChange(currentPage - 1)}
      >
        <Icon name="chevron-left" size={24} />
      </IconButton>

      <Button
        color="corporate"
        variant={currentPage === 1 ? 'contained' : 'text'}
        size={size}
        rounded={false}
        // disabled={currentPage === (i + 1)}
        style={{
          ...(currentPage === 1 ? {} : styles.buttonBorder),
          ...styles.buttonMargin,
        }}
        onClick={() => onChange(1)}
      >
        1
      </Button>

      {rangeStart > 2 ? (
        <Button
          color="corporate"
          variant="text"
          size={size}
          rounded={false}
          disabled
          style={{ ...styles.buttonBorder, ...styles.buttonMargin }}
        >
          ...
        </Button>
      ) : null}

      {pages.map(page => (
        <Button
          key={page}
          color="corporate"
          variant={currentPage === page ? 'contained' : 'text'}
          size={size}
          rounded={false}
          // disabled={currentPage === page}
          style={{
            ...(currentPage === page ? {} : styles.buttonBorder),
            ...styles.buttonMargin,
          }}
          onClick={() => onChange(page)}
        >
          {page}
        </Button>
      ))}

      {rangeEnd < pageCount - 1 ? (
        <Button
          color="corporate"
          variant="text"
          size={size}
          rounded={false}
          disabled
          style={{ ...styles.buttonBorder, ...styles.buttonMargin }}
        >
          ...
        </Button>
      ) : null}

      {pageCount > 1 ? (
        <Button
          color="corporate"
          variant={currentPage === pageCount ? 'contained' : 'text'}
          size={size}
          rounded={false}
          // disabled={currentPage === (i + 1)}
          style={{
            ...(currentPage === pageCount ? {} : styles.buttonBorder),
            ...styles.buttonMargin,
          }}
          onClick={() => onChange(pageCount)}
        >
          {pageCount}
        </Button>
      ) : null}

      <IconButton
        variant="text"
        color="corporate"
        size={size}
        style={{
          ...styles.buttonMargin,
          ...styles.buttonBorder,
          ...styles.nextButton,
          width: '44px',
          height: '38px',
        }}
        disabled={currentPage === pageCount}
        onClick={() => onChange(currentPage + 1)}
      >
        <Icon name="chevron-right" size={24} />
      </IconButton>
    </div>
  )
}

/** Component Property Types */
Pagination.propTypes = {
  currentPage: PropTypes.number,
  pageCount: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
  onChange: PropTypes.func,
}

Pagination.defaultProps = {
  currentPage: 1,
  size: 'small',
  onChange: () => {},
}

export default Pagination
