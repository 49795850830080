import { useStyletron } from '@parkholidays/legacy_components'
import { Auth } from 'aws-amplify'
import React, { useState } from 'react'
import Button from '../../components/ui/button'
import Typography from '../../components/ui/typography'
// import OwnersLogo from '../../components/owners-logo'
import ContentRoot, { ContentContainer } from './style'


const VerifyAccount = () => {
  const [, theme] = useStyletron()
  const [error, setError] = useState({})
  const [message, setMessage] = useState(
    'Verifying your account, please wait...',
  )
  const queryString = new URLSearchParams(window.location.search)
  const username = queryString.get('username')
  const confirmationCode = queryString.get('code')
  const hideResendButtonErrorCodes = [
    'AliasExistsException',
    'LimitExceededException',
    'NotAuthorizedException',
    'ResourceNotFoundException',
    'UserNotFoundException',
  ]

  React.useEffect(() => {
    Auth.confirmSignUp(username, confirmationCode)
      .then(async data => {
        window.location.href = `/login`
      })
      .catch(err => {
        setError(err)
      })
    return () => {}
  }, [username, confirmationCode])

  const resendVerificationEmail = () => {
    setError({})
    setMessage('Please wait. Sending new verification email...')
    Auth.resendSignUp(username).then(() => {
      setMessage(
        'A new verification email has been sent to your inbox. Please try again.',
      )
    })
  }

  const hasError = Object.keys(error).length > 0
  const hideResendVerificationButton = !hideResendButtonErrorCodes.includes(
    error.code,
  )

  return (
    <ContentRoot>
      <ContentContainer $color="corporate" $theme={theme}>
        {/* <OwnersLogo width="200" style={{ marginBottom: theme.spacing(2) }} /> */}
        {!hasError && <Typography as="h4">{message}</Typography>}

        {hasError && (
          <>
            <Typography as="h2">Error</Typography>
            <Typography as="h4">{error.message}</Typography>
            {hideResendVerificationButton && (
              <Button onClick={() => resendVerificationEmail()}>
                Resend verification email
              </Button>
            )}
          </>
        )}
      </ContentContainer>
    </ContentRoot>
  )
}

export default VerifyAccount
