import { withStyle } from 'styletron-react'

import CardRoot from '../card/styled'

const styles = ({ $color, theme }) => {
  const size = 10
  const borderColor =
    $color === 'default'
      ? theme.palette.paper
      : { ...theme.palette, ...theme.palette.common }[$color]

  const transparentBorder = `${size}px solid transparent`
  const coloredBorder = `${size}px solid ${borderColor}`

  return {
    top: {
      top: `-${size}px`,
      left: `50%`,
      marginLeft: `-${size / 2}px`,
      borderLeft: transparentBorder,
      borderRight: transparentBorder,
      borderBottom: coloredBorder,
    },
    bottom: {
      bottom: `-${size}px`,
      left: `50%`,
      marginLeft: `-${size / 2}px`,
      borderLeft: transparentBorder,
      borderRight: transparentBorder,
      borderTop: coloredBorder,
    },
    left: {
      top: '50%',
      left: `-${size}px`,
      marginTop: `-${size}px`,
      borderTop: transparentBorder,
      borderBottom: transparentBorder,
      borderRight: coloredBorder,
    },
    right: {
      top: '50%',
      right: `-${size}px`,
      marginTop: `-${size}px`,
      borderTop: transparentBorder,
      borderBottom: transparentBorder,
      borderLeft: coloredBorder,
    },
  }
}

const triangle = props => ({
  width: 0,
  height: 0,
  ...styles({ ...props })[props.position],
})

const PopoverRoot = withStyle(
  CardRoot,
  ({ $trianglePosition, $theme, ...otherProps }) => ({
    position: 'relative',
    overflow: 'visible',
    ':before': {
      content: '""',
      position: 'absolute',
      ...triangle({
        position: $trianglePosition,
        theme: $theme,
        ...otherProps,
      }),
    },
  }),
)

export default PopoverRoot
