import useIsPl2k from '../context/theme/hooks/useIsPl2k'

const useConvertedPreferenceName = () => {
  const isPl2k = useIsPl2k()
  const updatePreferenceName = (preferenceName) => {
    if (isPl2k)
      switch (preferenceName) {
        case 'email':
          return 'PL BULK EMAIL'
        case 'phone':
          return 'PL PHONE CALL'
        case 'sms':
          return 'PL SMS'
        case 'post':
          return 'PL POST'
        default:
          return `PL ${preferenceName.toUpperCase()}`
      }
    return preferenceName.toUpperCase()
  }
  return updatePreferenceName
}

export default useConvertedPreferenceName
