import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { LoadingWrapper, LoadingSpinner, LoadingMessage } from './style';
import THEME from '../../theme';

const Loading = ({ text }) => {
  return (
    <LoadingWrapper>
      <LoadingSpinner>
        <AiOutlineLoading3Quarters size={38} fill={THEME.colors.green} />
      </LoadingSpinner>
      <LoadingMessage>{text}</LoadingMessage>
    </LoadingWrapper>
  );
};

Loading.propTypes = {
  text: PropTypes.string,
};

Loading.defaultProps = {
  text: 'Loading',
};

export default Loading;
