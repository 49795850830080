import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import FormGroup from '../../ui/formgroup'
import FormField from '../../ui/formfield'
import Select from '../../ui/select'
import Button from '../../ui/button'
import Typography from '../../ui/typography'
import Icon from '../../ui/icon'
import Grid from '../../ui/grid'
import Container from '../../ui/container'

const Search = ({
  filters,
  handleDateRangeChange,
  handleCustomDateRangeChange,
  handleSearch,
  showCustomDateRange,
  showing,
}) => {
  const { startDate, endDate, defaultStartDate } = filters
  const [dropdownValue, setDropdownValue] = React.useState('all')

  return (
    <Container fluid>
      <Grid alignItems="center">
        <Grid item xs={12} md="auto" style={{ flexShrink: 0, flexGrow: 0 }}>
          <Typography
            as="small"
            color="corporate"
            weight="extra-bold"
            transform="uppercase"
            beforeEnhancer={
              <Icon name="search" size={12} style={{ marginRight: 8 }} />
            }
          >
            Search
          </Typography>
        </Grid>
        <Grid item xs={12} md="auto">
          <FormGroup style={{ marginBottom: 0 }}>
            <Select
              id="search_date"
              name="search_date"
              label="search date"
              labelProps={{ srOnly: true }}
              value={dropdownValue}
              fullWidth
              options={[
                { label: 'All', value: 'all' },
                { label: 'Last 30 Days', value: 30 },
                { label: 'Last 90 Days', value: 90 },
                { label: 'Last 180 Days', value: 180 },
                { label: 'Specific Date Range', value: 'custom' },
              ]}
              onChange={e => {
                handleDateRangeChange(e)
                setDropdownValue(e.target.value)
              }}
            />
          </FormGroup>
        </Grid>

        {showCustomDateRange && (
          <Grid item xs={12} md={9}>
            <div
              style={{ display: 'flex', alignItems: 'center', width: '100%' }}
            >
              <FormField
                name="startDate"
                type="date"
                value={format(startDate, 'yyyy-MM-dd')}
                min={format(defaultStartDate, 'yyyy-MM-dd')}
                max={format(new Date(), 'yyyy-MM-dd')}
                fullWidth
                onChange={e => handleCustomDateRangeChange(e)}
              />
              <span style={{ padding: '0 10px' }}>To</span>
              <FormField
                name="endDate"
                type="date"
                value={format(endDate, 'yyyy-MM-dd')}
                min={format(startDate, 'yyyy-MM-dd')}
                max={format(new Date(), 'yyyy-MM-dd')}
                fullWidth
                onChange={e => handleCustomDateRangeChange(e)}
              />
            </div>
          </Grid>
        )}

        <Grid item xs={12} md={3} xl={2}>
          <Button
            type="button"
            color="corporate"
            fullWidth
            onClick={e => handleSearch(e)}
          >
            Search
          </Button>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 8 }}>
          <Typography as="strong">
            Please note, recent payments can take up to 48 hours to appear on
            your account.
          </Typography>
          <br />
          <Typography as="p" color="red">
            {'Only the previous 2 years of transactions are available. '}
            {'For a full statement, please contact your '}
            <Typography as="strong">Admin Manager</Typography>
            {' or call '}
            <Typography as="strong">01424 234234</Typography>
            {' and ask for the '}
            <Typography as="strong">Owner Admin Team</Typography>.
          </Typography>
          <Typography as="p" color="corporate" noMargins>
            Showing: &nbsp;
            <Typography as="strong">{showing}</Typography>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

Search.propTypes = {
  filters: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    defaultStartDate: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  handleCustomDateRangeChange: PropTypes.func.isRequired,
  showCustomDateRange: PropTypes.bool.isRequired,
  showing: PropTypes.string.isRequired,
}

export default Search
