import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'styletron-react'

import HolidayExtrasCard, {
  HolidayExtrasContent,
  HolidayExtrasHidden,
  HolidayExtrasImage,
} from '../HolidayExtrasCard/HolidayExtrasCard'

import Typography from '../ui/typography'
import Anchor from '../ui/anchor'
import Icon from '../ui/icon'
import Grid from '../ui/grid'
import Button from '../ui/button'

const cancellationProtectionCardPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  extra: PropTypes.object.isRequired,
  included: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

/**
 * Cancellation protect Plan Card
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
const CancellationProtectionCard = ({ extra, included, onChange }) => {
  const [css] = useStyletron()
  return (
    <HolidayExtrasCard>
      <HolidayExtrasImage image="protection" />
      <HolidayExtrasContent>
        <Typography as="h3" color="corporate" weight="bold">
          Cancellation Protection Plan
        </Typography>
        <Typography as="p" noMargins>
          {extra.longDescription}
        </Typography>
      </HolidayExtrasContent>
      <HolidayExtrasHidden>
        {/* <Typography as="p">{extra.longDescription}</Typography> */}

        <Grid alignItems="center">
          <Grid item xs={6}>
            <Typography as="h1" color="rose" weight="bold" noMargins>
              {`£${parseFloat(extra.unitPrice).toFixed(2)}`}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Anchor
              native
              href="https://www.parkholidays.com/cancellation-protection-plan"
              endIcon={<Icon name="chevron-right" />}
              className={css({ display: 'inline-block', marginLeft: 'auto' })}
              target="_blank"
            >
              Full Details
            </Anchor>
          </Grid>

          <Grid item xs={12} className={css({ marginBottom: 0 })}>
            <Button color="corporate" fullWidth onClick={onChange}>
              {`${included ? 'Remove' : 'Add'} Cancellation Protection`}
            </Button>
          </Grid>
        </Grid>
      </HolidayExtrasHidden>
    </HolidayExtrasCard>
  )
}

CancellationProtectionCard.propTypes = cancellationProtectionCardPropTypes
export default CancellationProtectionCard
