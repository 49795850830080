import PropTypes from 'prop-types'
import React, { memo, useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'

import BrandLogo from '../../components/ui/brandLogo'
import Divider from '../../components/ui/divider'
import Icon from '../../components/ui/icon'
import { LinkButton } from '../../components/ui/button'
import Notification from '../../components/ui/notification'
import Typography from '../../components/ui/typography'

import LoginForm from '../../components/login-form'
import PasswordResetForm from '../../components/password-reset-form'

import AuthContext from '../../context/auth'
import Theme from '../../context/theme'
import ContentRoot, { ContentContainer } from './style'

const styles = {
  content: {
    width: '100%',
    maxWidth: '320px',
  },
}

/**
 *  Login Page Component
 *
 * @param {*} props
 * @returns
 */
const Login = memo(({ location }) => {
  const { theme } = useContext(Theme)
  const { from } = location.state || {}
  const auth = useContext(AuthContext)

  /** STATE */
  const [cognitoUser, setCognitoUser] = useState(null)
  const [isCompleted, setIsCompleted] = useState(false)
  const [notification, setNotification] = useState(null)

  /** CUSTOM METHODS */
  const handleInvalidForm = (errorMessage) => setNotification({
    context: 'error',
    title: 'Error',
    messages: [errorMessage],
  })

const handleLogin = async (emailAddress, password) => {
  const status = await auth.signIn(emailAddress, password)
  setIsCompleted(true)

  if (!status.ok) {
    setNotification({
      context: 'error',
      pale: true,
      messages: [status.message].concat(
        status.attemptsExceeded
          ? ['You account is now locked, please try again in 5 minutes.']
          : [],
      ),
    })
  } else if (status.newPasswordRequired) {
    setNotification(null)
    setCognitoUser(status.data)
  }
}

  const handleChangePassword = async (newPassword) => {
    const status = await auth.completeNewPassword(cognitoUser, newPassword)
    setIsCompleted(true)
    if (!status.ok) handleInvalidForm(status.message)
  }

  if (!auth.isGuest && !auth.forcePasswordChange)
    return <Redirect to={from && from.pathname !== '/logout' ? from : '/'} />

  return (
    <ContentRoot
      $theme={theme}
      style={{ backgroundColor: theme.palette.corporate }}
    >
      <ContentContainer $theme={theme}>
        <BrandLogo width="200" />
        {auth.forcePasswordChange ? (
          <PasswordResetForm
            isCompleted={isCompleted}
            notification={notification ? <Notification {...notification} style={{ marginBottom: theme.spacing(2) }} /> : null}
            onSubmit={handleChangePassword}
            onInvalid={() => handleInvalidForm('Your new password and confirmation do not match')}
          />
        ) : (
          <LoginForm
            theme={theme}
            isCompleted={isCompleted}
            notification={notification ? <Notification {...notification} style={{ marginBottom: theme.spacing(2) }} /> : null}
            onSubmit={handleLogin}
            style={styles.content}
          />
        )}

        {!auth.forcePasswordChange ? (
          <>
            <Divider
              color="white"
              $style={{
                maxWidth: '320px',
                marginTop: '32px',
                marginBottom: '32px',
              }}
            />

            <div style={styles.content}>
              <Typography as="h4" color="white">
                New to My {theme.isPl2k ? "Park Leisure" : "Park Holidays"}?
              </Typography>

              <LinkButton
                to="/register"
                color="white"
                variant="outlined"
                fullWidth
                align="center"
                endIcon={<Icon name="chevron-right" />}
              >
                Register
              </LinkButton>
            </div>
          </>
        ) : null}
      </ContentContainer>
    </ContentRoot>
  )
})

export default Login

Login.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({ from: PropTypes.object }),
  }),
}
Login.defaultProps = { location: {} }
