import React, { useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'

// import UserInvite from './UserInvite'
import PasswordReset from './PasswordReset'
// import SecondaryUsers from './SecondaryUsers'
import MarketingPreferences from './MarketingPreferences'

import Notification from '../../components/Notification/Notification'
import Loading from '../../components/ui/loading'
import Container from '../../components/ui/container'
import Card, { CardContent } from '../../components/ui/card'
import Typography from '../../components/ui/typography'

import { GET_INITIAL_DATA } from './graphql'

const Profile = () => {
  const client = useApolloClient()
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState({
    accessLevels: [],
    accountGroup: {},
    epPreferences: [],
  })

  const [notification, setNotification] = useState({
    title: '',
    messages: [],
  })

  /**
   * DONT add profile to the useEffect 2nd parameter
   * This would cause getInitialData to run multiple times
   * This function is only required to run once
   */
  useEffect(() => {
    let mounted = true
    const getInitialData = async () => {
      try {
        const { data } = await client.query({ query: GET_INITIAL_DATA })
        const epPreferences = [
          ...profile.epPreferences,
          ...data.contactPreferences,
        ]
        const accessLevels = [...profile.accessLevels, ...data.allAccessLevels]
        const accountGroup = { ...profile.accountGroup, ...data.accountGroup }

        if (mounted)
          setProfile({
            ...profile,
            ...{ epPreferences, accessLevels, accountGroup },
          })
      } catch (err) {
        if (mounted)
          setNotification({ title: 'Error', messages: err.graphQLErrors })
        console.log(err)
      }

      if (mounted) setLoading(false)
    }

    getInitialData()
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  const { /*accountGroup, accessLevels,*/ epPreferences } = profile
  // const { secondaryUsers } = accountGroup

  if (loading) return <Loading />

  return (
    <Container style={{ marginBottom: 16 }}>
      <Notification
        title={notification.title}
        messages={notification.messages}
      />

      <Card style={{ marginTop: 16 }} rounded={false}>
        <CardContent>
          <Typography as="h3" weight="bold" noMargins>
            Account Details
          </Typography>
        </CardContent>
      </Card>

      <MarketingPreferences epPreferences={epPreferences} />
      <PasswordReset />
      {/* <SecondaryUsers users={secondaryUsers} accessLevels={accessLevels} />
      <UserInvite accessLevels={accessLevels} /> */}
    </Container>
  )
}

export default Profile
