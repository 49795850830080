// pages
import {
  AccountActivity,
  BalancePayment,
  // MarkdownPage,
  ForgotPassword, Help, Home,
  // HolidayOverview,
  Login,
  Logout, Markdown, ParkServices, PasswordReset,
  Profile, Register,
  // HolidaysPayment,
  VerifyAccount
} from './views'

const authRoutes = [
  {
    path: '/login',
    component: Login,
    hideLayout: true,
    isPublic: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    hideLayout: true,
    isPublic: true,
  },
  {
    path: '/reset-password/:username/:code',
    component: PasswordReset,
    hideLayout: true,
    isPublic: true,
  },
  {
    path: '/register/:token?',
    component: Register,
    isPublic: true,
  },
  {
    path: '/verify',
    component: VerifyAccount,
    hideLayout: true,
    isPublic: true,
  },
  {
    path: '/logout',
    component: Logout,
    isPublic: true,
  },
]

const publicRoutes = [
  {
    path: '/help',
    component: Help,
    isPublic: true,
  },
  {
    path: '/privacy-policy',
    component: Markdown,
    props: {
      pageTitle: 'Privacy Policy',
      markdownSlug: 'legal/master/Privacy Policy',
      returnTo: '/register',
    },
    isPublic: true,
  },
  {
    path: '/privacy-policy/processors',
    component: Markdown,
    props: {
      pageTitle: 'Privacy Policy Processors',
      markdownSlug: 'legal/master/Processors',
      returnTo: '/privacy-policy',
    },
    isPublic: true,
  },
  {
    path: '/terms-conditions',
    component: Markdown,
    props: {
      pageTitle: 'Terms and Conditions',
      markdownSlug: 'legal/master/my.parkholidays.com/Terms and Conditions',
      returnTo: '/register',
    },
    isPublic: true,
  }
]

const privateRoutes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/owner/account/:customerNo',
    component: AccountActivity,
  },
  {
    path: '/owner/payment/:customerNo',
    component: BalancePayment,
  },
  {
    path: '/owner/services/:customerNo',
    component: ParkServices,
  },
  // {
  //   path: '/bookings/:bookingNo',
  //   component: HolidayOverview,
  // },
  // {
  //   path: '/bookings/:bookingNo/add-extras',
  //   component: AddExtras,
  // },
  // {
  //   path: '/bookings/:bookingNo/make-payment',
  //   component: HolidaysPayment,
  // },
]

export default [...publicRoutes, ...authRoutes, ...privateRoutes]
