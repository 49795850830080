import React from 'react'
import PropTypes from 'prop-types'

import Pagination from '../ui/pagination'
import Notification from '../ui/notification'

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  itemComponent: PropTypes.func.isRequired,
  itemKey: PropTypes.string.isRequired,
  customMessage: PropTypes.string,
}

const defaultProps = { isMobile: false, customMessage: undefined }

const PagedResults = ({
  items,
  isMobile,
  itemComponent: ItemComponent,
  itemKey,
  currentPage,
  customMessage,
  onPageChange,
}) => {
  const itemCount = items ? items.length : []
  const perPage = isMobile ? 10 : 25
  const pageCount = Math.ceil(itemCount / perPage)
  const pageStart = perPage * (currentPage - 1)
  const pageEnd = pageStart + perPage
  const columnCount = items.length > 1 ? Object.keys(items[0]).length : 1

  const Pages = () => (
    <>
      {items
        .map(item => (
          <ItemComponent key={item[itemKey]} item={item} isMobile={isMobile} />
        ))
        .filter((_, index) => index + 1 >= pageStart && index + 1 <= pageEnd)}
    </>
  )

  const Controls = () => (
    <Pagination
      perPage={perPage}
      currentPage={currentPage}
      pageCount={pageCount}
      onChange={page => onPageChange(page)}
      style={{ margin: '16px auto' }}
    />
  )

  const NoResults = () => (
    <Notification
      messages={[customMessage ? customMessage : 'No results currently found']}
    />
  )

  const RenderedResults = () => {
    if (items.length === 0) return <NoResults />
    if (isMobile) {
      return (
        <>
          {pageCount > 1 ? (
            <div style={{ textAlign: 'center' }}>
              <Controls />
            </div>
          ) : null}
          <Pages />
          {pageCount > 1 ? (
            <div style={{ textAlign: 'center' }}>
              <Controls />
            </div>
          ) : null}
        </>
      )
    }

    return (
      <table
        style={{
          borderCollapse: 'collapse',
          wordWrap: 'break-word',
          // tableLayout: 'fixed',
          width: '100%',
        }}
      >
        {pageCount > 1 ? (
          <tbody>
            <tr>
              <td colSpan={columnCount} align="center">
                <Controls />
              </td>
            </tr>
          </tbody>
        ) : null}

        <tbody>
          <Pages />
        </tbody>

        {pageCount > 1 ? (
          <tfoot>
            <tr>
              <td colSpan={columnCount} align="center">
                <Controls />
              </td>
            </tr>
          </tfoot>
        ) : null}
      </table>
    )
  }

  return <RenderedResults />
}

PagedResults.propTypes = propTypes
PagedResults.defaultProps = defaultProps
export default PagedResults
