import { gql } from 'apollo-boost'

export const GET_OWNER_DETAILS = gql`
  query ownerDetails($customerNo: CustomerNo!, $parkId: ID!) {
    ownerDetails(customerNo: $customerNo) {
      balance
    }
    park(id: $parkId) {
      id
      name
      logo {
        path
        alternateText
      }
    }
  }
`

export const GET_BOOKING_DETAILS = gql`
  query booking($bookingNo: ID!) {
    booking(id: $bookingNo) {
      arrivalDate
      departureDate
      noOfNights
      bookingPrice
      outstandingAmount
      totalExtras
      park {
        code
        name
        address {
          addressLine1
          addressLine2
          town
          county
          postcode
        }

        logo {
          path
          alternateText
        }
      }
    }
  }
`
