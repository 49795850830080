import React from "react";
import PropTypes from "prop-types";
import { ArrowContainer } from "react-tiny-popover";
import {
  Label,
  Button,
  CardSchemes,
  CardScheme,
  PaymentAmount,
  ExpirySecurityBlock,
  ExpiryBlock,
  SecurityBlock,
  SecurityCodeMessage
} from "./style";
import FormControl from "./components/form-control";
import Input from "./components/input";
import Helper from "./components/helper";
import SchemeHelper from "./components/scheme-helper";
import THEME from "./theme";
// import CSC from './images/csc.png';

/*
Not using name attribute on payment fields as these should be 
encrypted before posting
*/
const PaymentForm = ({
  transactionAmount,
  cardDetails,
  cardScheme,
  handleChange,
  handleSubmit,
  minTransactionAmount
}) => {
  const { cardNumber, cardholderName, expiryMonthYear, csc } = cardDetails;

  return (
    <div>
      <CardSchemes>
        <CardScheme>{THEME.cardSchemes.Visa}</CardScheme>
        <CardScheme>{THEME.cardSchemes.Mastercard}</CardScheme>
      </CardSchemes>

      <Label>Payment amount</Label>
      <PaymentAmount>
        {new Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: "GBP"
        }).format(transactionAmount)}
      </PaymentAmount>

      <form onSubmit={e => handleSubmit(e)}>
        <FormControl label="Name on Card">
          <Input
            id="cardholderName"
            type="text"
            maxLength="30"
            minLength="3"
            value={cardholderName}
            onChange={e => handleChange("cardholderName", e)}
            required
          />
        </FormControl>

        <FormControl label="Card number">
          <Input
            id="cardNumber"
            type="text"
            minLength="13"
            maxLength="16"
            value={cardNumber}
            pattern="[0-9]*"
            onChange={e => handleChange("cardNumber", e)}
            required
            endEnhancer={
              cardScheme !== "" && <SchemeHelper scheme={cardScheme} />
            }
          />
        </FormControl>

        <ExpirySecurityBlock>
          <ExpiryBlock>
            <FormControl label="Expiry date">
              <Input
                id="expiryMonthYear"
                value={expiryMonthYear}
                onChange={e => handleChange("expiryMonthYear", e)}
                required
                placeholder="MM / YY"
                $style={{
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0"
                }}
              />
            </FormControl>
          </ExpiryBlock>

          <SecurityBlock>
            <FormControl label="Security code">
              <Input
                id="csc"
                type="text"
                maxLength="4"
                value={csc}
                pattern="[0-9]*"
                onChange={e => handleChange("csc", e)}
                required
                $style={{
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0"
                }}
                endEnhancer={
                  <Helper
                    position="top"
                    align="end"
                    content={({ position, targetRect, popoverRect }) => (
                      <ArrowContainer
                        position={position}
                        targetRect={targetRect}
                        popoverRect={popoverRect}
                        arrowColor="#fff"
                        arrowSize={20}
                        arrowStyle={{
                          position: "absolute",
                          borderLeft: "0",
                          borderRight: "0",
                          borderTop: "0",
                          height: "20px",
                          width: "20px",
                          bottom: "12px",
                          backgroundColor: "#fff",
                          transform: "rotate(45deg)",
                          left: "245.266px",
                          zIndex: "2",
                          boxShadow: "3px 1px 0px #ccc"
                        }}
                      >
                        <SecurityCodeMessage>
                          <p>
                            The <strong>3/4 digits</strong> on the back of your
                            card.
                          </p>

                          {/* <img
                            src={CSC}
                            alt="Back of your card"
                            style={{ width: '280px' }}
                          /> */}
                        </SecurityCodeMessage>
                      </ArrowContainer>
                    )}
                  />
                }
              />
            </FormControl>
          </SecurityBlock>
        </ExpirySecurityBlock>

        <Button
          type="submit"
          disabled={transactionAmount < minTransactionAmount}
        >
          <svg
            width="24"
            height="24"
            fill="#fff"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginRight: "8px" }}
          >
            {/* eslint-disable-next-line max-len */}
            <path d="M896 64q-169 0-323 66t-265.5 177.5-177.5 265.5-66 323 66 323 177.5 265.5 265.5 177.5 323 66 323-66 265.5-177.5 177.5-265.5 66-323-66-323-177.5-265.5-265.5-177.5-323-66zm0-64q182 0 348 71t286 191 191 286 71 348-71 348-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71zm-400 832q16 0 16 16v480q0 16-16 16h-32q-16 0-16-16v-480q0-16 16-16h32zm400 64q53 0 90.5 37.5t37.5 90.5q0 35-17.5 64t-46.5 46v114q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-114q-29-17-46.5-46t-17.5-64q0-53 37.5-90.5t90.5-37.5zm0-768q209 0 385.5 103t279.5 279.5 103 385.5-103 385.5-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103zm-352 480v96q0 14 9 23t23 9h64q14 0 23-9t9-23v-96q0-93 65.5-158.5t158.5-65.5 158.5 65.5 65.5 158.5v96q0 14 9 23t23 9h64q14 0 23-9t9-23v-96q0-146-103-249t-249-103-249 103-103 249zm864 736v-512q0-26-19-45t-45-19h-896q-26 0-45 19t-19 45v512q0 26 19 45t45 19h896q26 0 45-19t19-45z" />
          </svg>
          {`Pay ${new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "GBP"
          }).format(transactionAmount)}`}
        </Button>
      </form>
    </div>
  );
};

export default PaymentForm;

PaymentForm.propTypes = {
  transactionAmount: PropTypes.number.isRequired,
  minTransactionAmount: PropTypes.number.isRequired,
  cardDetails: PropTypes.shape({
    cardNumber: PropTypes.string.isRequired,
    cardholderName: PropTypes.string.isRequired,
    expiryMonthYear: PropTypes.string.isRequired,
    csc: PropTypes.string.isRequired
  }).isRequired,
  cardScheme: PropTypes.oneOf(["Visa", "Mastercard", ""]),
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

PaymentForm.defaultProps = {
  cardScheme: ""
};
