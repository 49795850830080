import { styled } from 'styletron-react';

/** Root Accordian Component */
const AccordianRoot = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

/** Root Accordian Item Component */
export const AccordianItemRoot = styled('div', ({ $color, $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  ...$theme.styler({ color: $color !== 'default' ? $color : 'background' }),
}));

/** Accordian Toggle Component */
export const AccordianItemToggle = styled('a', ({ $theme }) => ({
  ...$theme.typography.base,
  cursor: 'pointer',
  padding: `${$theme.spacing(2)}px ${$theme.spacing(3)}px`,
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  width: 'auto',
}));

/** Accordian Content Component */
export const AccordianItemContent = styled('div', ({ $open, $theme }) => ({
  display: $open ? 'block' : 'none',
  padding: `${$theme.spacing(1)}px ${$theme.spacing(2)}px`,
}));

export default AccordianRoot;
