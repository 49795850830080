import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'styletron-react'

import HolidayExtrasCard, {
  HolidayExtrasContent,
  HolidayExtrasHidden,
  HolidayExtrasImage,
} from '../HolidayExtrasCard/HolidayExtrasCard'

import Typography from '../ui/typography'
import Select from '../ui/select'
import Button from '../ui/button'

const petsCardPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  extra: PropTypes.object.isRequired,
  includedQty: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

/**
 * Pets Card
 *
 * @param {*} { extra, included, onChange }
 */
const PetsCard = ({ extra, includedQty, onSubmit }) => {
  const [quantity, setQuantity] = React.useState(includedQty)
  const [css] = useStyletron()

  React.useEffect(() => {
    if (quantity !== includedQty) setQuantity(includedQty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includedQty])

  return (
    <HolidayExtrasCard>
      <HolidayExtrasImage image="pets" />
      <HolidayExtrasContent>
        <Typography as="h3" color="corporate" weight="bold">
          Pets
        </Typography>

        <Typography as="p" noMargins>
          {extra.longDescription}
        </Typography>
      </HolidayExtrasContent>

      <HolidayExtrasHidden>
        <Typography as="h1" color="rose" weight="bold">
          {`£${extra.unitPrice}`}
        </Typography>

        <Select
          label="No. of Pets"
          value={quantity}
          menuPlacement="top"
          options={[...new Array(extra.maxQty + 1)].map((_, n) => ({
            label: n.toString(),
            value: n,
          }))}
          onChange={e => setQuantity(e.target.value)}
          fullWidth
        />

        <Button
          color="corporate"
          fullWidth
          onClick={() => onSubmit(quantity)}
          disabled={!extra.bookable || (quantity === 0 && includedQty === 0)}
          className={css({ marginTop: 10 })}
        >
          {(() => {
            if (includedQty > 0) {
              if (quantity === 0) return 'Remove'
              else return 'Update'
            }

            return 'Add'
          })()}
          {' Pets'}
        </Button>
      </HolidayExtrasHidden>
    </HolidayExtrasCard>
  )
}

PetsCard.propTypes = petsCardPropTypes
export default PetsCard
