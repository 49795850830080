import React, { useState, useContext } from 'react'
import PasswordRules from '../../components/Auth/PasswordRules'

import Notification from '../../components/ui/notification'
import Grid from '../../components/ui/grid'
import Typography from '../../components/ui/typography'
import FormField from '../../components/ui/formfield'
import FormLabel from '../../components/ui/formlabel'
import Button, { IconButton } from '../../components/ui/button'
import Icon from '../../components/ui/icon'
import Card, { CardHeader, CardContent } from '../../components/ui/card'

import AuthContext from '../../context/auth'

const initialPasswordReset = {
  currentPassword: {
    show: false,
    value: '',
  },
  newPassword: {
    show: false,
    value: '',
  },
  confirmNewPassword: {
    show: false,
    value: '',
  },
}

const PasswordReset = () => {
  const auth = useContext(AuthContext)

  // Does a deep clone which ES6 spread operator cannot
  const [passwordResetForm, setPasswordResetForm] = useState(
    JSON.parse(JSON.stringify(initialPasswordReset)),
  )

  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState({
    title: '',
    messages: [],
  })

  const passwordResetChange = e => {
    const newPasswordResetForm = { ...passwordResetForm }
    newPasswordResetForm[e.target.name].value = e.target.value
    setPasswordResetForm(newPasswordResetForm)
  }

  const handleClick = element => {
    const newPasswordResetForm = { ...passwordResetForm }
    newPasswordResetForm[element].show = !passwordResetForm[element].show
    setPasswordResetForm(newPasswordResetForm)
  }

  const isFormValid = () => {
    const { newPassword, confirmNewPassword } = passwordResetForm

    if (confirmNewPassword.value !== newPassword.value) {
      setNotification({
        context: 'error',
        title: 'Error',
        messages: ['Your new password and confirmation do not match'],
      })
      return false
    }

    return true
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (isFormValid()) {
      setLoading(true)
      try {
        const currentPassword = passwordResetForm.currentPassword.value
        const newPassword = passwordResetForm.newPassword.value

        const response = await auth.changePassword(currentPassword, newPassword)
        if (response.ok)
          setNotification({
            context: 'success',
            title: 'Success',
            messages: [
              'Your password has been updated and will take effect next time you login',
            ],
          })
        else
          setNotification({
            context: 'error',
            title: 'Error',
            messages: [response.message],
          })
      } catch (err) {
        setNotification({
          context: 'error',
          title: 'Error',
          messages: [err.message],
        })
      }
      setLoading(false)
      setPasswordResetForm({ ...initialPasswordReset })
    }
  }

  const { currentPassword, newPassword, confirmNewPassword } = passwordResetForm

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Notification {...notification} style={{ marginBottom: 16 }} />

        <Card color="white" style={{ marginBottom: 16 }}>
          <CardHeader style={{ paddingTop: 16 }}>
            <Typography as="h2">Change Password</Typography>
          </CardHeader>

          <CardContent>
            <Grid alignItems="center">
              <Grid item xs={12} md={4}>
                <FormLabel htmlFor="currentPassword">
                  Current Password
                </FormLabel>
              </Grid>

              <Grid item xs={12} md={8}>
                <FormField
                  id="currentPassword"
                  type={currentPassword.show ? 'text' : 'password'}
                  name="currentPassword"
                  value={currentPassword.value}
                  onChange={passwordResetChange}
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,30}$"
                  required
                  fullWidth
                  afterEnhancer={
                    <IconButton
                      type="button"
                      color="white"
                      tabIndex="-1"
                      onClick={() => handleClick('currentPassword')}
                      style={{
                        borderColor: 'rgb(217, 217, 217)',
                        borderLeft: 0,
                      }}
                    >
                      <Icon name={currentPassword.show ? 'eye-slash' : 'eye'} />
                    </IconButton>
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormLabel htmlFor="newPassword">New Password</FormLabel>
              </Grid>

              <Grid item xs={12} md={8}>
                <FormField
                  id="newPassword"
                  type={newPassword.show ? 'text' : 'password'}
                  name="newPassword"
                  value={newPassword.value}
                  onChange={passwordResetChange}
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,30}$"
                  required
                  fullWidth
                  afterEnhancer={
                    <IconButton
                      type="button"
                      color="white"
                      tabIndex="-1"
                      onClick={() => handleClick('newPassword')}
                      style={{
                        borderColor: 'rgb(217, 217, 217)',
                        borderLeft: 0,
                      }}
                    >
                      <Icon name={newPassword.show ? 'eye-slash' : 'eye'} />
                    </IconButton>
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormLabel htmlFor="confirmNewPassword">
                  Confirm New Password
                </FormLabel>
              </Grid>

              <Grid item xs={12} md={8}>
                <FormField
                  id="confirmNewPassword"
                  type={confirmNewPassword.show ? 'text' : 'password'}
                  name="confirmNewPassword"
                  value={confirmNewPassword.value}
                  onChange={passwordResetChange}
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,30}$"
                  required
                  fullWidth
                  afterEnhancer={
                    <IconButton
                      type="button"
                      color="white"
                      tabIndex="-1"
                      onClick={() => handleClick('confirmNewPassword')}
                      style={{
                        borderColor: 'rgb(217, 217, 217)',
                        borderLeft: 0,
                      }}
                    >
                      <Icon
                        name={confirmNewPassword.show ? 'eye-slash' : 'eye'}
                      />
                    </IconButton>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <PasswordRules />
              </Grid>

              <Grid item xs={12} md={6} mdOffset={3} lg={4} lgOffset={4}>
                <Button type="submit" color="corporate" disabled={loading}>
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </>
  )
}

export default PasswordReset
