import React, {useContext} from 'react'
import Image from '../../../components/ui/image'
import Theme from '../../../context/theme'
import { PH_LOGO } from '../../../constants'

const ParkHolidaysBrandLogo = ({width}) => {
  const {
    theme: { spacing },
  } = useContext(Theme)

  return (
    <Image
      src={PH_LOGO}
      alt="Park holidays logo"
      width={width}
      style={{ marginBottom: spacing(2) }}
    />
  )
}

export default ParkHolidaysBrandLogo
