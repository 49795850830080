import React from 'react'
import useIsPl2k from '../context/theme/hooks/useIsPl2k'
import AuthContext from '../context/auth'

const useIsOwner = () => {
  const isPl2k = useIsPl2k()
  const { user } = React.useContext(AuthContext)
  const isParkHolidaysOwner = user?.scope?.owner && !isPl2k
  const isOwner = user?.scope?.owner

  return { isParkHolidaysOwner, isOwner }
}

export default useIsOwner
