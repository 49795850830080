import MarkdownComponent from 'markdown-to-jsx'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import Container from '../../components/ui/container'
import Icon from '../../components/ui/icon'
import Typography from '../../components/ui/typography'
import Theme from '../../context/theme'

const Markdown = ({ returnTo, pageTitle, markdownSlug, location }) => {
  const { theme: { isPl2k, spacing} } = React.useContext(Theme)
  const [pageContent, setPageContent] = React.useState('')

  if (isPl2k) markdownSlug += "-pl"; // Add pl suffix to markdowns on Park Leisure URL

  React.useEffect(() => {
    let cancelled = false
    // eslint-disable-next-line no-undef
    fetch(
      `https://raw.githubusercontent.com/park-holidays-uk/${encodeURI(
        markdownSlug,
      )}.md`,
      { method: 'get' },
    )
      .then(response => response.text())
      .then(content => (!cancelled ? setPageContent(content) : false))
      .catch(err => console.log(err))

    return () => {
      cancelled = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <Container $style={{ marginTop: `${spacing(2)}px` }}>
      <Link to={returnTo} style={{ textDecoration: 'underline' }}>
        <Typography beforeEnhancer={<Icon name="navigate_before" />}>
          Go Back
        </Typography>
      </Link>
      <br />

      <Typography as="h1">{pageTitle}</Typography>

      <MarkdownComponent>{pageContent}</MarkdownComponent>
    </Container>
  )
}

Markdown.propTypes = {
  returnTo: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  markdownSlug: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
}

Markdown.defaultProps = { returnTo: '/' }

export default Markdown
