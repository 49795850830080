import React, { useEffect, createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useApolloClient } from '@apollo/react-hooks'

import AuthContext from '../auth'
import { GET_OWNER_ACCOUNTS, GET_HOLIDAY_ACCOUNTS } from './graphql'

import actions from './actions'

const initialState = {
  isLoading: false,
  holiday: [],
  ownership: [],
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.FETCH_INIT:
      return {
        ...state,
        isLoading: true,
      }
    case actions.CLEAR_ACCOUNTS:
      return {
        ...initialState,
      }
    case actions.UPDATE_STATE:
    case actions.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: { ...initialState.status },
        ...action.payload,
      }
    default:
      return state
  }
}

const Context = createContext(null)

export const AccountsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const auth = useContext(AuthContext)

  const client = useApolloClient()

  const fetchOwnershipAccounts = async () => {
    // console.log('fetchOwnershipAccounts starting')
    const { data } = await client.query({
      query: GET_OWNER_ACCOUNTS,
    })
    // console.log('GET_OWNER_ACCOUNTS', JSON.stringify(data))
    return data ? data.userOwnerDetails || [] : []
  }

  const fetchHolidaysAccounts = async () => {
    // console.log('fetchHolidaysAccounts starting')
    const { data } = await client.query({
      query: GET_HOLIDAY_ACCOUNTS,
    })
    // console.log('GET_HOLIDAY_ACCOUNTS', JSON.stringify(data))
    return data ? data.userHolidayDetails || [] : []
  }

  useEffect(() => {
    const fetchAccounts = async () => {
      dispatch({ type: actions.FETCH_INIT })
      return await Promise.all([
        fetchOwnershipAccounts(),
        fetchHolidaysAccounts(),
      ])
        .then(([ownership, holiday]) => {
          const payload = { ownership, holiday }
          dispatch({ type: actions.FETCH_SUCCESS, payload })
        })
        .catch(error => console.log(error))
    }

    if (auth.user) fetchAccounts()
    else dispatch({ type: actions.CLEAR_ACCOUNTS })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user])

  return <Context.Provider value={state}>{children}</Context.Provider>
}

AccountsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

export default Context
