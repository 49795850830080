import React from 'react'
import PropTypes from 'prop-types'

import Container from '../ui/container'
import Typography from '../ui/typography'

import Theme from '../../context/theme'
import SectionHeaderRoot from './styled'

const sectionHeaderPropTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}

const SectionHeader = ({ children, color }) => {
  const { theme } = React.useContext(Theme)
  return (
    <SectionHeaderRoot $color={color} $theme={theme}>
      <Container>
        <Typography as="h2" weight="semi-bold" noMargins>
          {children}
        </Typography>
      </Container>
    </SectionHeaderRoot>
  )
}

SectionHeader.propTypes = sectionHeaderPropTypes
export default SectionHeader
