import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../components/ui/button';
import { BurgerMenuIcon } from '../../components/ui/icon';
import VisibleOn, { HiddenOn } from '../../components/hoc/visible-on';

/**
 * My Account Button
 *
 */
const MenuButton = ({ color, active, onClick, ...otherProps }) => (
  <>
    <Button
      endIcon={(
        <HiddenOn
          component={<BurgerMenuIcon color={color} active={active} style={{ marginLeft: 8 }} />}
          breakpoints={['xs', 'sm']}
        />
      )}
      rounded={false}
      size="small"
      color={color}
      title={`${active ? 'close' : 'open'} menu`}
      aria-label={`${active ? 'close' : 'open'} menu`}
      onClick={onClick}
      {...otherProps}
    >
      <HiddenOn component={<>Menu</>} breakpoints={['xs', 'sm']} />
      <VisibleOn
        component={<BurgerMenuIcon color={color} active={active} />}
        breakpoints={['xs', 'sm']}
      />
    </Button>
  </>
);

export default MenuButton;

MenuButton.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

MenuButton.defaultProps = {
  active: false,
  color: 'default',
  onClick: undefined,
};
