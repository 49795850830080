import React from 'react';
import { Redirect } from 'react-router-dom';

import Theme from '../../context/theme';
import AuthContext from '../../context/auth';

import Typography from '../../components/ui/typography';

import ContentRoot from './style';

const Logout = () => {
  const { theme } = React.useContext(Theme);
  const auth = React.useContext(AuthContext);

  React.useEffect(() => {
    // eslint-disable-next-line no-return-await
    const signOut = async () => await auth.signOut();
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentRoot $theme={theme}>
      <div
        style={{
          width: '100%',
          maxWidth: '320px',
        }}
      >
        <Typography as="h2">You are being logged out.</Typography>
        <Typography noMargins>Please wait...</Typography>
        {auth.isGuest ? <Redirect to="/login" /> : null}
      </div>
    </ContentRoot>
  );
};

export default Logout;
