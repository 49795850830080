/** @flow */
import React from 'react'
import PropTypes from 'prop-types'

/** Styled Components */
import Theme from '../../../context/theme'

import VisibleOn from '../../hoc/visible-on'
import { IconButton } from '../button'
import Icon from '../icon'

import ButtonMenuRoot, { Wrapper } from './styled'

const buttonMenuPropTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  color: PropTypes.string.isRequired,
}

/**
 * Collapsable Button Menu
 *
 * @param {*} { children, color }
 * @returns
 */
const ButtonMenu = ({ children, color, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  const [showMenu, setShowMenu] = React.useState(false)
  const [isMobile, setIsMobile] = React.useState(false)
  const menuRef = React.useRef(null)
  const childrenCount = React.Children.count(children)

  const isMatchingElements = (elementA, elementB) =>
    elementA && elementB
      ? elementA === elementB || elementA.contains(elementB)
      : false

  React.useEffect(() => {
    const onWindowResized = () => {
      const isMobileSize = window.innerWidth < theme.breakpoints.width('md')
      setIsMobile(isMobileSize)
      setShowMenu(!isMobileSize)
    }

    onWindowResized()
    window.addEventListener('resize', onWindowResized)
    document.addEventListener('click', event => {
      const isClickInside = isMatchingElements(menuRef.current, event.target)
      if (!isClickInside && isMobile) setShowMenu(false)
    })

    return () => window.removeEventListener('resize', onWindowResized)
  }, [isMobile, theme.breakpoints])

  return (
    <Wrapper {...otherProps} ref={menuRef}>
      <VisibleOn
        breakpoints={['xs', 'sm']}
        component={
          <IconButton
            variant="outlined"
            color={color}
            onClick={() => setShowMenu(!showMenu)}
          >
            <Icon name="more_horiz" />
          </IconButton>
        }
      />

      <ButtonMenuRoot $show={showMenu} $isMobile={isMobile} $theme={theme}>
        {React.Children.map(children, (child, idx) =>
          React.cloneElement(child, {
            color,
            rounded: !isMobile,
            style: {
              ...(child.props.style || {}),
              marginTop: !isMobile ? 0 : -1,
              marginBottom: !isMobile ? theme.spacing(1) : 0,
              ...(isMobile
                ? {
                    ...(idx === 0
                      ? {
                          borderTopLeftRadius: theme.shape.small,
                          borderTopRightRadius: theme.shape.small,
                        }
                      : {}),
                    ...(idx === childrenCount - 1
                      ? {
                          borderBottomLeftRadius: theme.shape.small,
                          borderBottomRightRadius: theme.shape.small,
                        }
                      : {}),
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                  }
                : {}),
            },
          }),
        )}
      </ButtonMenuRoot>
    </Wrapper>
  )
}

ButtonMenu.propTypes = buttonMenuPropTypes
export default ButtonMenu
