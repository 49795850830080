import { gql } from 'apollo-boost';

const GET_BOOKING = gql`
  query($id: ID!) {
    booking(id: $id) {
      id
      bookingType
      bookingAgentCode
      arrivalDate
      noOfNights
      departureDate
      noOfAdults
      noOfChildren
      noOfPets
      parkCode
      gradeCode
      discountAmount
      standardPrice
      bookingPrice
      totalExtras
      outstandingAmount
      confirmed
      cancelled
      bookingAgentGradeTranslation {
        id
        originalLeadSource
        description
      }
      extras {
        code
        description
        longDescription
        unitPrice
        quantity
        diningOptions
        bookable
        minQty
        maxQty
      }
      park {
        id
        name
        address {
          addressLine1
          addressLine2
          town
          county
          postcode
        }
      }
      hireType {
        id
        code
        description
        bedrooms
        berths
        grade {
          id
          name
        }
        accommodationType {
          id
          name
        }
      }
    }
  }
`;

const SET_EXTRAS = gql`
  mutation UpdateExtras($input: UpdateExtrasInput!) {
    updateExtras(input: $input) {
      code
      quantity
      amount
      unitPrice
    }
  }
`;

const CARD_PAYMENT = gql`
  mutation CreateCardPayment($input: CreateCardPaymentInput!) {
    createCardPayment(input: $input) {
      reference
      parkId
      amount
      success
    }
  }
`;

const SET_PAYMENT = gql`
  mutation CreatePaymentEntry($input: CreatePaymentEntryInput!) {
    createPaymentEntry(input: $input) {
      bookingNo
      reference
      amount
      receiptNo
    }
  }
`;

export {
  CARD_PAYMENT, GET_BOOKING, SET_EXTRAS, SET_PAYMENT,
};
