import React from 'react';
import PropTypes from 'prop-types';

const EncryptCardDetailsIframe = ({
  cardDetails,
  optoDetails,
  onEncryptionComplete,
}) => {
  const { cardNumber, expiryMonthYear, csc } = cardDetails;

  const [expiryMonth, expiryYear] = expiryMonthYear.split('/');

  const { optoReq, optoHmac } = optoDetails;

  const cardEncryptionFormRef = React.useRef(null);

  const listenForCardEncryptionResponse = e => {
    if (e.data.event === '3DS-encryption-complete') {
      onEncryptionComplete(e.data);
      window.removeEventListener(
        'message',
        listenForCardEncryptionResponse,
        false,
      );
    }
  };

  React.useEffect(() => {
    if (cardEncryptionFormRef && cardEncryptionFormRef.current) {
      window.addEventListener(
        'message',
        listenForCardEncryptionResponse,
        false,
      );
      cardEncryptionFormRef.current.submit();
    }
  }, [cardEncryptionFormRef]);

  return (
    <>
      <form
        action={process.env.REACT_APP_GRAPHQL_API_OPTOMANY_ENCRYPT_CARD_URL}
        method="POST"
        target="encrypt-card-details"
        ref={cardEncryptionFormRef}
      >
        <input type="hidden" name="Pan" value={cardNumber} readOnly />
        <input type="hidden" name="ExpiryMM" value={expiryMonth} readOnly />
        <input type="hidden" name="ExpiryYY" value={expiryYear} readOnly />
        <input type="hidden" name="Cvv" value={csc} readOnly />
        <input type="hidden" name="OptoReq" value={optoReq} readOnly />
        <input type="hidden" name="OptoHmac" value={optoHmac} readOnly />
      </form>

      <iframe
        title="card encyption"
        name="encrypt-card-details"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default EncryptCardDetailsIframe;

EncryptCardDetailsIframe.propTypes = {
  cardDetails: PropTypes.shape({
    cardNumber: PropTypes.string.isRequired,
    expiryMonthYear: PropTypes.string.isRequired,
    csc: PropTypes.string.isRequired,
  }).isRequired,
  optoDetails: PropTypes.shape({
    optoReq: PropTypes.string.isRequired,
    optoHmac: PropTypes.string.isRequired,
  }).isRequired,
  onEncryptionComplete: PropTypes.func.isRequired,
};
