import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'styletron-react'

import ButtonMenu, { MenuButton } from '../ui/button-menu'
import useApplyButtonMenuColor from '../../context/theme/hooks/useApplyButtonMenuColor'

const OwnerAccountCardMenu = ({ customerNo, menuStyles, optionalStyles }) => {
  const applyColor = useApplyButtonMenuColor()
  const [css] = useStyletron()

  return (
    <ButtonMenu
      color={applyColor}
      className={css({ ...menuStyles, ...(optionalStyles || {}) })}
    >
      <MenuButton text="Make a Payment" to={`/owner/payment/${customerNo}`} />
      <MenuButton
        text="Park Services - Book &amp; Pay"
        to={`/owner/services/${customerNo}`}
      />
      <MenuButton text="Account Activity" to={`/owner/account/${customerNo}`} />
    </ButtonMenu>
  )
}

OwnerAccountCardMenu.propTypes = {
  customerNo: PropTypes.string.isRequired,
  menuStyles: PropTypes.object,
  optionalStyles: PropTypes.string,
}

export default OwnerAccountCardMenu
