/** @flow */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

/** Styled Components */
import Theme from '../../../context/theme'
import ButtonRoot, { IconButtonRoot, FabRoot, ButtonLabelRoot } from './styled'
import useHover from '../../../helpers/useHover'

import Icon from '../icon'

const STYLETRON_PROPS = [
  'variant',
  'color',
  'size',
  'align',
  'fullWidth',
  'rounded',
  'margin',
  'raised',
]

const mapProps = props =>
  Object.keys(props).reduce((prev, current) => {
    const prefix = STYLETRON_PROPS.indexOf(current) > -1 ? '$' : ''

    return {
      ...prev,
      ...{ [`${prefix}${current}`]: props[current] },
    }
  }, {})

const customButtonPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  as: PropTypes.oneOf([ButtonRoot, IconButtonRoot, FabRoot, Link]),
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  color: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
  align: PropTypes.oneOf([
    'flex-start',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
    'flex-end',
  ]),
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  margin: PropTypes.number,
  raised: PropTypes.bool,
  disabled: PropTypes.bool,
}

/**
 * Custom Styled Button
 * Base button that all other buttons will use
 *
 * @param {*} { children, as, disabled, ...otherProps }
 * @returns
 */
const CustomButton = ({ children, type, as, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  const [hoverRef, isHovered] = useHover()

  const StyledButton = as
  const newProps = {
    ...mapProps(otherProps),
    $color: otherProps.disabled ? 'textDisabled' : otherProps.color,
  }

  return (
    <StyledButton
      {...newProps}
      ref={hoverRef}
      type={type}
      $isHovered={isHovered}
      $theme={theme}
    >
      {children}
    </StyledButton>
  )
}

const buttonPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
}

/**
 * Standard Button
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
const Button = ({ children, startIcon, endIcon, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  const margin = React.Children.count(children) > 0 ? theme.spacing(1) : 0

  return (
    <CustomButton
      align={startIcon || endIcon ? 'space-between' : 'center'}
      {...otherProps}
    >
      {startIcon
        ? React.cloneElement(startIcon, {
            style: { marginRight: margin, marginBottom: 2 },
            // color: otherProps.color,
          })
        : null}

      {children ? (
        <ButtonLabelRoot $theme={theme}>{children}</ButtonLabelRoot>
      ) : null}

      {endIcon
        ? React.cloneElement(endIcon, {
            style: { marginLeft: margin, marginBottom: 2 },
            // color: otherProps.color,
          })
        : null}
    </CustomButton>
  )
}

const linkButtonPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  to: PropTypes.string.isRequired,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
  align: PropTypes.oneOf([
    'flex-start',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
    'flex-end',
  ]),
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  margin: PropTypes.number,
  raised: PropTypes.bool,
  disabled: PropTypes.bool,
}

/**
 *  Link Button
 *
 * @param {*} { children, to, ...otherProps }
 * @returns
 */
export const LinkButton = ({ children, to, native, ...otherProps }) => {
  return (
    <Button
      type={null}
      to={native ? null : to}
      href={native ? to : null}
      {...otherProps}
      $as={native ? 'a' : Link}
    >
      {children}
    </Button>
  )
}

/**
 * Close Button
 *
 * @param {*} { ...otherProps }
 * @returns
 */
export const CloseButton = ({ ...otherProps }) => (
  <CustomButton as={IconButtonRoot} {...otherProps} variant="text" size="tiny">
    <Icon name="cross" size={18} />
  </CustomButton>
)

const iconButtonPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

/**
 * Icon Button
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
export const IconButton = ({ children, ...otherProps }) => (
  <CustomButton as={IconButtonRoot} {...otherProps}>
    {children}
  </CustomButton>
)

const fabPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

/**
 * Fab
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
export const Fab = ({ children, ...otherProps }) => (
  <CustomButton as={FabRoot} {...otherProps}>
    {children}
  </CustomButton>
)

/** Component Property Types */
CustomButton.propTypes = customButtonPropTypes
CustomButton.defaultProps = {
  as: ButtonRoot,
  type: 'submit',
  color: 'default',
  variant: 'contained',
  size: 'medium',
  align: 'center',
  fullWidth: false,
  rounded: true,
  raised: false,
  disabled: false,
  margin: 0,
}

Button.propTypes = buttonPropTypes
Button.defaultProps = {
  startIcon: undefined,
  endIcon: undefined,
}

LinkButton.propTypes = linkButtonPropTypes
LinkButton.defaultProps = {
  children: undefined,
  color: 'default',
  variant: 'contained',
  size: 'medium',
  align: 'center',
  fullWidth: false,
  rounded: true,
  raised: false,
  disabled: false,
  margin: 0,
}

IconButton.propTypes = iconButtonPropTypes
Fab.propTypes = fabPropTypes

export default Button
