import React from 'react'
import Anchor from '../../components/ui/anchor'
import PropTypes from 'prop-types'
import { PL_HELP } from '../../config'

const ParkLeisureHelp = ({ href, text }) => {
  const mailToLink = href ?? PL_HELP
  return (
    <Anchor href={href ?? `mailto:${mailToLink}`}>{text ?? PL_HELP}</Anchor>
  )
}

ParkLeisureHelp.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
}

export default ParkLeisureHelp
