import { gql } from 'apollo-boost';

const GET_ACCOUNT_ACTIVITY = gql`
  query customerLedger($criteria: CustomerLedgerCriteria!) {
   customerLedger(criteria: $criteria) {
    customerBalance
    creditLimit
    customerLedgerEntries {
      entryNo
      documentType
      documentNo
      description
      postingDate
      dueDate
      open
      amount
      remainingAmount
      currencyCode
      reasonCode
    }
   }
 }
`;

export default GET_ACCOUNT_ACTIVITY;
