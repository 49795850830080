import React from 'react'
import PropTypes from 'prop-types'

import HolidayExtrasCard, {
  HolidayExtrasContent,
  HolidayExtrasHidden,
  HolidayExtrasImage,
} from '../HolidayExtrasCard/HolidayExtrasCard'

import Typography from '../ui/typography'
import Button from '../ui/button'

const earlyCheckInCardPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  extra: PropTypes.object.isRequired,
  included: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

/**
 * Early Check In Card
 *
 * @param {*} { extra, included, onChange }
 */
const EarlyCheckInCard = ({ extra, included, onChange }) => (
  <HolidayExtrasCard>
    <HolidayExtrasImage image="early-check-in" />
    <HolidayExtrasContent>
      <Typography as="h3" color="corporate" weight="bold">
        Early Check In
      </Typography>

      <Typography as="p" noMargins>
        Beat the rush and make more of your stay with a &nbsp;
        <Typography as="strong">2pm check-in</Typography>
        &nbsp;(Standard check-in from 4pm)
      </Typography>
    </HolidayExtrasContent>

    <HolidayExtrasHidden>
      <Typography as="h1" color="rose" weight="bold">
        {`£${extra.unitPrice}`}
      </Typography>
      <Button color="corporate" fullWidth onClick={onChange}>
        {`${included ? 'Remove' : 'Add'} Early Check-In`}
      </Button>
    </HolidayExtrasHidden>
  </HolidayExtrasCard>
)

EarlyCheckInCard.propTypes = earlyCheckInCardPropTypes

export default EarlyCheckInCard
