import { useApolloClient } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Notification from '../../components/ui/notification'
import { UPDATE_CONTACT_PREFERENCES } from './graphql'

import Button from '../../components/ui/button'
import Card, { CardContent, CardHeader } from '../../components/ui/card'
import Checkbox from '../../components/ui/checkbox'
import FormLabel from '../../components/ui/formlabel'
import Grid from '../../components/ui/grid'
import Typography from '../../components/ui/typography'
import useContactPreferenceOptions from '../../hooks/useContactPreferenceOptions'
import useConvertedPreferenceName from '../../hooks/useConvertedPreferenceName'

const MarketingPreferences = ({ epPreferences }) => {
  const client = useApolloClient()
  const {contactPreferences, getPrefNames}  = useContactPreferenceOptions();

  /**
   * Convert EP style preferences into a format that can be consumed by this component
   * e.g. {post: true, email: false .... }
   */
  const convertEpPreferences = preferences => {
    const formattedPreferences = {}
    preferences.forEach(preference => {
      // Only add preferences for the appropriate brand
      if (contactPreferences.includes(preference.communicationTypeCode)) {
        const prefName = getPrefNames(preference.communicationTypeCode)
        formattedPreferences[prefName] = preference.optIn
      }
    })
    return formattedPreferences
  }

  const [preferences, setPreferences] = useState(
    convertEpPreferences(epPreferences),
  )
  const [notification, setNotification] = useState({
    context: null,
    title: '',
    messages: [],
  })

  /**
   * Convert name, taking into account the brand (if Park Leisure, codes are different)
   */
  const updatePreferenceName = useConvertedPreferenceName()
  /**
   * Convert preferences back to EP format so they can be sent to the UPDATE_CONTACT_PREFERENCES
   * mutation in the correct format
   */
  const revertPreferencesToEp = () =>

    Object.keys(preferences).map(preference => ({
      marketingProductCode: 'MARKETING',
      communicationTypeCode: updatePreferenceName(preference),
      optIn: preferences[preference]
      }
    ))

  const handleChange = e => {
    setPreferences({
      ...preferences,
      ...{ [e.target.name]: e.target.value === 'true' },
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    console.log('newPrefsAre',JSON.stringify(revertPreferencesToEp()))
    try {
      await client.mutate({
        mutation: UPDATE_CONTACT_PREFERENCES,
        variables: { input: { preferences: revertPreferencesToEp() } },
      })
      setNotification({
        context: 'success',
        title: 'Success',
        messages: ['Your preferences have been updated!'],
      })
    } catch (err) {
      setNotification({ context: 'error', title: 'Error', messages: err.graphQLErrors })
    }
  }

  const { email, sms, phone, post } = preferences

  return (
    <form onSubmit={handleSubmit} style={{ paddingTop: 16, marginBottom: 16 }}>
      <Notification
        context={notification.context}
        title={notification.title}
        messages={notification.messages}
      />

      <Card color="white">
        <CardHeader style={{ paddingTop: 16 }}>
          <Typography as="h3">Marketing Preferences</Typography>
        </CardHeader>

        <CardContent>
          <Grid>
            <Grid item xs={12} md={3}>
              <Typography as="strong">Email</Typography>
            </Grid>

            <Grid item xs={4} md={3}>
              <FormLabel
                htmlFor="emailYes"
                control={
                  <Checkbox
                    id="emailYes"
                    as="radio"
                    name="email"
                    checked={email}
                    value
                    onChange={handleChange}
                  />
                }
              >
                Yes
              </FormLabel>
            </Grid>

            <Grid item xs={4} md={6}>
              <FormLabel
                htmlFor="emailNo"
                control={
                  <Checkbox
                    id="emailNo"
                    as="radio"
                    name="email"
                    checked={!email}
                    value={false}
                    onChange={handleChange}
                  />
                }
              >
                No
              </FormLabel>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography as="strong">Phone</Typography>
            </Grid>

            <Grid item xs={4} md={3}>
              <FormLabel
                htmlFor="phoneYes"
                control={
                  <Checkbox
                    id="phoneYes"
                    as="radio"
                    name="phone"
                    checked={phone}
                    value
                    onChange={handleChange}
                  />
                }
              >
                Yes
              </FormLabel>
            </Grid>

            <Grid item xs={4} md={6}>
              <FormLabel
                htmlFor="phoneNo"
                control={
                  <Checkbox
                    id="phoneNo"
                    as="radio"
                    name="phone"
                    checked={!phone}
                    value={false}
                    onChange={handleChange}
                  />
                }
              >
                No
              </FormLabel>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography as="strong">SMS</Typography>
            </Grid>

            <Grid item xs={4} md={3}>
              <FormLabel
                htmlFor="smsYes"
                control={
                  <Checkbox
                    id="smsYes"
                    as="radio"
                    name="sms"
                    checked={sms}
                    value
                    onChange={handleChange}
                  />
                }
              >
                Yes
              </FormLabel>
            </Grid>

            <Grid item xs={4} md={6}>
              <FormLabel
                htmlFor="smsNo"
                control={
                  <Checkbox
                    id="smsNo"
                    as="radio"
                    name="sms"
                    checked={!sms}
                    value={false}
                    onChange={handleChange}
                  />
                }
              >
                No
              </FormLabel>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography as="strong">Post</Typography>
            </Grid>

            <Grid item xs={4} md={3}>
              <FormLabel
                htmlFor="postYes"
                control={
                  <Checkbox
                    id="postYes"
                    as="radio"
                    name="post"
                    checked={post}
                    value
                    onChange={handleChange}
                  />
                }
              >
                Yes
              </FormLabel>
            </Grid>

            <Grid item xs={4} md={6}>
              <FormLabel
                htmlFor="postNo"
                control={
                  <Checkbox
                    id="postNo"
                    as="radio"
                    name="post"
                    checked={!post}
                    value={false}
                    onChange={handleChange}
                  />
                }
              >
                No
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              mdOffset={3}
              lg={4}
              lgOffset={4}
              style={{ paddingTop: 8 }}
            >
              <Button type="submit" color="corporate">
                Update Preferences
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  )
}

export default MarketingPreferences

MarketingPreferences.propTypes = {
  epPreferences: PropTypes.arrayOf(
    PropTypes.shape({
      marketingProductCode: PropTypes.string.isRequired,
      communicationTypeCode: PropTypes.string.isRequired,
      optIn: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
}
