import React from 'react';
import PropTypes from 'prop-types';

import { CardContent } from '../card';
import PopoverRoot from './style';

import Theme from '../../../context/theme';

const Popover = ({ children, title, trianglePosition, ...otherProps }) => {
  const { theme } = React.useContext(Theme);
  return (
    <PopoverRoot
      {...otherProps}
      $color={otherProps.color || 'default'}
      $rounded={otherProps.rounded || true}
      $elevation={0}
      $trianglePosition={trianglePosition}
      $theme={theme}
    >
      <CardContent>
        {title}
        {children}
      </CardContent>
    </PopoverRoot>
  );
};

export default Popover;

Popover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  trianglePosition: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
};

Popover.defaultProps = {
  title: undefined,
  trianglePosition: 'left',
};
