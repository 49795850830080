import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-tiny-popover';
import { HelperWrapper, HelperButton } from './style';

const Helper = ({ isOpen, position, align, content }) => {
  const [isPopoverOpen, setPopoverOpen] = React.useState(isOpen);
  return (
    <Popover
      isOpen={isPopoverOpen}
      position={position} // preferred position
      align={align}
      content={content}
      onClickOutside={() => setPopoverOpen(false)}
      containerStyle={{ overflow: 'visible' }}
      padding={-10}
    >
      <HelperWrapper>
        <HelperButton
          arial-label="aaa"
          title="bbb"
          type="button"
          role="button"
          onClick={() => setPopoverOpen(!isPopoverOpen)}
        >
          ?
        </HelperButton>
      </HelperWrapper>
    </Popover>
  );
};

Helper.propTypes = {};

Helper.defaultProps = {
  isOpen: false,
  content: <div>No content!</div>,
  position: 'top',
  align: 'center',
};

export default Helper;

// <div>Hi! I'm popover content.</div>
