const returnParkCode = (parkId) => {
  var parkCodeData = [
    {id: 1, code: "CH"},
    {id: 2, code: "LS"},
    {id: 3, code: "GS"},
    {id: 4, code: "AB"},
    {id: 5, code: "BP"},
    {id: 6, code: "DL"},
    {id: 7, code: "RH"},
    {id: 8, code: "FS"},
    {id: 9, code: "HT"},
    {id: 10, code: "LC"},
    {id: 11, code: "MF"},
    {id: 12, code: "NB"},
    {id: 13, code: "OK"},
    {id: 15, code: "RB"},
    {id: 16, code: "SF"},
    {id: 17, code: "SH"},
    {id: 18, code: "SL"},
    {id: 20, code: "SO"},
    {id: 21, code: "SP"},
    {id: 22, code: "SV"},
    {id: 23, code: "SW"},
    {id: 24, code: "WC"},
    {id: 25, code: "WS"},
    {id: 27, code: "BS"},
    {id: 28, code: "BV"},
    {id: 29, code: "TK"},
    {id: 30, code: "CM"},
    {id: 31, code: "PB"},
    {id: 32, code: "MB"},
    {id: 33, code: "DC"},
    {id: 34, code: "WM"},
    {id: 35, code: "PF"},
    {id: 36, code: "WF"},
    {id: 37, code: "SS"},
    {id: 38, code: "TB"},
    {id: 39, code: "SM"},
    {id: 40, code: "AH"},
    {id: 41, code: "BF"},
    {id: 42, code: "HM"},
    {id: 43, code: "HW"},
    {id: 44, code: "TV"},
    {id: 45, code: "PL"},
    {id: 47, code: "BM"},
    {id: 48, code: "LM"},
    {id: 49, code: "BH"},
    {id: 50, code: "BY"},
    {id: 51, code: "OB"},
    {id: 52, code: "PS"},
    {id: 53, code: "PH"},
    {id: 54, code: "MV"},
    {id: 55, code: "RV"},
    {id: 56, code: "BT"},
    {id: 57, code: "PC"},
    {id: 58, code: "AL"},
    {id: 59, code: "CY"},
    {id: 60, code: "LD"},
    {id: 61, code: "YD"}
  ];

  var codeResult = parkCodeData.find(codeData => parkId === codeData.id);

  if (codeResult) {
    return codeResult.code;
  } else {
    return "HO"; // Head office fallback
  }
}

export default returnParkCode
