import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../ui/typography';
import { ownerBalance } from '../../../helpers';
import TransactionCardRoot, { TransactionCell,
  TransactionTableRow,
  TransactionTableCell } from '../style';

const formatedDate = value => new Date(value).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' });

const capitaliseWords = string => (string
  ? string
    .split(' ')
    .map(word => `${word[0].toUpperCase()}${word.substring(1).toLowerCase()}`)
    .join(' ')
  : '');
const transactionData = transaction => {
  const ledgerPriceToArray = ownerBalance(transaction.amount).split(' ');
  const type = capitaliseWords(ledgerPriceToArray[1]);
  const reasonCode = capitaliseWords(transaction.reasonCode);

  return {
    ...transaction,
    reasonCode,
    textAmount: ledgerPriceToArray[0],
    type,
    color: type === 'Debit' ? 'error' : null,
  };
};

const TransactionCard = ({ item }) => {
  const data = transactionData(item);
  const styles = {
    cell50: {
      flexBasis: '50%',
      width: '50%',
    },

    descriptionCell: {
      flexBasis: '100%',
      width: '100%',
      padding: '16px 0',
    },
  };

  return (
    <TransactionCardRoot>
      <TransactionCell $style={styles.cell50}>
        <Typography color="corporate" weight="bold">
          {formatedDate(data.postingDate.substr(0, 10))}
        </Typography>

        <br />

        <Typography color="corporate">{data.documentType}</Typography>
      </TransactionCell>

      <TransactionCell $style={{ ...styles.cell50, textAlign: 'right' }}>
        <Typography weight="bold" color={data.color}>
          {data.textAmount}
        </Typography>

        <br />

        <Typography color={data.color}>{data.type}</Typography>
      </TransactionCell>

      <TransactionCell $style={{ ...styles.cell50, ...styles.descriptionCell }}>
        <Typography color="corporate">{data.description}</Typography>
      </TransactionCell>

      <TransactionCell $style={styles.cell50}>
        <Typography as="small">{data.reasonCode}</Typography>
      </TransactionCell>

      <TransactionCell $style={{ ...styles.cell50, textAlign: 'right' }}>
        <Typography as="small">{data.documentNo}</Typography>
      </TransactionCell>
    </TransactionCardRoot>
  );
};

const TransactionRow = ({ item }) => {
  const data = transactionData(item);
  return (
    <TransactionTableRow>
      <TransactionTableCell $style={{ whiteSpace: 'nowrap' }}>
        <Typography color="corporate" weight="bold">
          {formatedDate(data.postingDate.substr(0, 10))}
        </Typography>
      </TransactionTableCell>

      <TransactionTableCell>
        <Typography color="corporate">{data.documentType}</Typography>
      </TransactionTableCell>

      <TransactionTableCell>
        <Typography weight="bold" color={data.color}>
          {data.textAmount}
        </Typography>
      </TransactionTableCell>

      <TransactionTableCell $style={{ whiteSpace: 'nowrap' }}>
        <Typography color={data.color}>{data.type}</Typography>
      </TransactionTableCell>

      <TransactionTableCell>
        <Typography color="corporate">{data.description}</Typography>
      </TransactionTableCell>

      <TransactionTableCell $style={{ textAlign: 'right' }}>
        <Typography as="small">{data.reasonCode}</Typography>
      </TransactionTableCell>

      <TransactionTableCell $style={{ textAlign: 'right' }}>
        <Typography as="small">{data.documentNo}</Typography>
      </TransactionTableCell>
    </TransactionTableRow>
  );
};

const Transaction = ({ item, isMobile }) => (isMobile ? <TransactionCard item={item} /> : <TransactionRow item={item} />);

const sharedPropTypes = {
  item: PropTypes.shape({
    postingDate: PropTypes.string,
    documentType: PropTypes.string,
    description: PropTypes.string,
    reasonCode: PropTypes.string,
    amount: PropTypes.number,
    documentNo: PropTypes.string,
  }).isRequired,
};

Transaction.propTypes = {
  ...sharedPropTypes,
  isMobile: PropTypes.bool,
};

Transaction.defaultProps = { isMobile: false };
TransactionCard.propTypes = { ...sharedPropTypes };
TransactionRow.propTypes = { ...sharedPropTypes };

export default Transaction;
