import React from 'react'
import ParkHolidaysHead from './ParkHolidaysHead'
import ParkLeisureHead from './ParkLeisureHead'
import { MY_PARK_HOLIDAYS } from '../../constants'


const Head = ({ brand }) => {
  return brand === MY_PARK_HOLIDAYS ? (
    <ParkHolidaysHead />
  ) : (
    <ParkLeisureHead />
  )
}

export default Head
