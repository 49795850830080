import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'styletron-react'

import HolidayExtrasCard, {
  HolidayExtrasContent,
  HolidayExtrasHidden,
  HolidayExtrasImage,
} from '../HolidayExtrasCard/HolidayExtrasCard'

import Typography from '../ui/typography'
import Grid from '../ui/grid'
import Select from '../ui/select'
import Button from '../ui/button'

const cotsHighCairCardPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  includedExtras: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

/**
 * Cots and High Chair Card
 *
 * @param {*} { options, includedExtras, onSubmit }
 * @returns
 */
const CotsHighCairCard = ({ options, includedExtras, onSubmit }) => {
  const [selectedExtras, setSelectedExtras] = React.useState([])
  const [quantities, setQuantities] = React.useState({
    COT: 0,
    HIGHC: 0,
  })

  const [css] = useStyletron()

  const updateQuantities = (key, value) =>
    setQuantities(prevState => ({ ...prevState, [key]: value }))

  React.useEffect(() => {
    const newExtras = []

    options.forEach(option => {
      if (quantities[option.code] > 0)
        newExtras.push({
          ...option,
          quantity: quantities[option.code],
        })
    })

    setSelectedExtras(newExtras)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantities])

  React.useEffect(() => {
    Object.keys(quantities).forEach(key => {
      const extra = includedExtras.find(extra => extra.code === key)
      updateQuantities(key, extra ? extra.quantity : 0)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includedExtras])

  return (
    <HolidayExtrasCard>
      <HolidayExtrasImage image="highchair-cot" />
      <HolidayExtrasContent>
        <Typography as="h3" color="corporate" weight="bold">
          Cots and High Chairs
        </Typography>

        <Typography as="p" noMargins>
          Bringing a little one? Cot and high chair hire is available for the
          duration of your stay
        </Typography>
      </HolidayExtrasContent>

      <HolidayExtrasHidden>
        <Grid>
          {options.map(option => (
            <Grid key={option.code} item xs={12} lg={6}>
              <Typography as="h3" color="corporate" weight="bold" noMargins>
                {option.description}
              </Typography>

              <Typography as="h1" color="rose" weight="bold">
                {`£${option.unitPrice}`}
              </Typography>

              <Select
                label="Quantity"
                value={quantities[option.code]}
                options={[
                  ...new Array(option.maxQty - option.quantity + 1),
                ].map((_, i) => ({
                  label: i.toString(),
                  value: i,
                }))}
                fullWidth
                onChange={e => updateQuantities(option.code, e.target.value)}
                disabled={!option.bookable}
              />
            </Grid>
          ))}

          <Grid item xs={12}>
            <Button
              color="corporate"
              fullWidth
              onClick={() => onSubmit(selectedExtras)}
              disabled={
                selectedExtras.length === 0 && includedExtras.length === 0
              }
              className={css({ marginTop: 10 })}
            >
              {(() => {
                if (includedExtras.length > 0) {
                  if (selectedExtras.length === 0) return 'Remove'
                  else return 'Update'
                }

                return 'Add'
              })()}
              {' Cots and/or High Chairs'}
            </Button>
          </Grid>
        </Grid>
      </HolidayExtrasHidden>
    </HolidayExtrasCard>
  )
}

CotsHighCairCard.propTypes = cotsHighCairCardPropTypes
export default CotsHighCairCard
