import React from 'react';
import PropTypes from 'prop-types';
import { AiFillCheckCircle } from 'react-icons/ai';
import {
  SuccessWrapper,
  SuccessIcon,
  SuccessMessage,
  TransactionAmount,
} from './style';
import THEME from '../../theme';

const Success = ({ transactionAmount, text }) => {
  return (
    <SuccessWrapper>
      <SuccessIcon>
        <AiFillCheckCircle size={64} fill={THEME.colors.green} />
      </SuccessIcon>
      <TransactionAmount>
        {new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP',
        }).format(transactionAmount)}
      </TransactionAmount>
      <SuccessMessage>
        <p
          size={64}
          style={{ fontSize: THEME.sizing.scale400, color: THEME.colors.gray }}
        >
          Your payment is complete
        </p>

        <p>{text}</p>
      </SuccessMessage>
    </SuccessWrapper>
  );
};

Success.propTypes = {
  transactionAmount: PropTypes.number.isRequired,
  text: PropTypes.string,
};

Success.defaultProps = {
  text: 'Your payment is complete.',
};

export default Success;
