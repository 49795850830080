import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'styletron-react'

import HolidayExtrasCard, {
  HolidayExtrasContent,
  HolidayExtrasHidden,
  HolidayExtrasImage,
} from '../HolidayExtrasCard/HolidayExtrasCard'

import Typography from '../ui/typography'
import Anchor from '../ui/anchor'
import Divider from '../ui/divider'
import Icon from '../ui/icon'
import Grid from '../ui/grid'
import Select from '../ui/select'
import FormLabel from '../ui/formlabel'
import Button from '../ui/button'
import { Radio } from '../ui/checkbox'

const diningOptions = [
  { id: 1, label: 'Self-Catering', code: null },
  { id: 2, label: 'Breakfast Only', code: 'BK' },
  { id: 3, label: 'Half Board', code: 'HB' },
]

const diningOptionsCardPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  includedExtras: PropTypes.array.isRequired,
  noOfAdults: PropTypes.number.isRequired,
  noOfChildren: PropTypes.number.isRequired,
  noOfNights: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

/**
 * Dining option Card
 *
 * @param {*} { options, includedExtras, noOfAdults, noOfChildren, noOfNights, onSubmit }
 * @returns
 */
const DiningOptionsCard = ({
  options,
  includedExtras,
  noOfAdults,
  noOfChildren,
  noOfNights,
  onSubmit,
}) => {
  const [currentOptionCode, setCurrentOptionCode] = React.useState(null)
  const [currentData, setCurrentData] = React.useState(null)
  const [selectedExtras, setSelectedExtras] = React.useState([])
  const [quantities, setQuantities] = React.useState({
    AD: 0,
    CH: 0,
    nights: noOfNights,
  })

  const [css] = useStyletron()

  const definitions = {
    AD: 'Adults',
    CH: 'Children',
  }

  const updateQuantities = (key, value) =>
    setQuantities(prevState => ({ ...prevState, [key]: value }))

  const getOptionData = (option, maxQuantity) => ({
    code: option.code,
    unitPrice: parseFloat(option.unitPrice).toFixed(2),
    description: option.description,
    availableQuantities: [...new Array(maxQuantity + 1)].map((_, i) => i),
    bookable: option.bookable,
  })

  React.useEffect(() => {
    let data = null
    const newExtras = []

    if (currentOptionCode) {
      const filteredOptions = options.filter(
        option =>
          option.code.substring(0, 2) === currentOptionCode &&
          // eslint-disable-next-line radix
          parseInt(option.code.substring(4)) === quantities.nights,
      )

      const adultOption = filteredOptions.find(
        option => option.code.substring(2, 4) === 'AD',
      )
      const childOption = filteredOptions.find(
        option => option.code.substring(2, 4) === 'CH',
      )

      if (adultOption && childOption) {
        const partySize = noOfAdults + noOfChildren
        const availableAdults = partySize - quantities.CH
        const availableChildren =
          (quantities.AD > 0 ? partySize : partySize - 1) - quantities.AD

        data = {
          AD: getOptionData(adultOption, availableAdults),
          CH: getOptionData(childOption, availableChildren),
        }
      }
    }

    if (data) {
      ;['AD', 'CH'].forEach(type => {
        if (quantities[type] > 0) {
          newExtras.push({
            code: data[type].code,
            description: data[type].description,
            quantity: quantities[type],
            unitPrice: parseFloat(data[type].unitPrice),
            bookable: true, // data[type].bookable,
          })
        }
      })
    }

    setSelectedExtras(newExtras)
    setCurrentData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOptionCode, quantities])

  React.useEffect(() => {
    Object.keys(quantities)
      .filter(key => key !== 'nights')
      .forEach(key => {
        const extra = includedExtras.find(
          extra =>
            extra.code ===
            `${currentOptionCode || ''}${key}${quantities.nights}`,
        )
        updateQuantities(key, extra ? extra.quantity : 0)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includedExtras])

  return (
    <HolidayExtrasCard>
      <HolidayExtrasImage image="dining" />
      <HolidayExtrasContent>
        <Typography as="h3" color="corporate" weight="bold">
          Dining Options
        </Typography>
        <Typography as="p" noMargins>
          Take a break from the cooking and come dine with us with Breakfast and
          Half Board options. You can save up to 1/3 on a fantastic menu
          selection for breakfast, and dinner.
        </Typography>
      </HolidayExtrasContent>

      <HolidayExtrasHidden>
        <Typography as="p">
          We offer a range of great value and flexible catering options. Choose
          from breakfast only or half board flexi-dine options across as many
          days as you like. We recommend the adult dining options for children
          aged 10+
        </Typography>

        <div className={css({ textAlign: 'right' })}>
          <Anchor
            native
            href="https://beta.parkholidays.com/caravan-holidays/half-board-catered-inclusive"
            target="_blank"
            endIcon={<Icon name="chevron-right" />}
            className={css({ display: 'inline-block', marginLeft: 'auto' })}
          >
            Menus & Full Details
          </Anchor>
        </div>

        <Divider />

        <Typography as="p" weight="bold">
          Select your preferred dining option and the number of days.
        </Typography>

        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 16,
          })}
        >
          {diningOptions.map(option => (
            <FormLabel
              key={option.id}
              control={
                <Radio
                  name="type[]"
                  checked={option.code === currentOptionCode}
                  onChange={() => setCurrentOptionCode(option.code)}
                />
              }
              style={{ padding: '8px 0' }}
            >
              {option.label}
            </FormLabel>
          ))}
        </div>

        {currentData ? (
          <Grid>
            {['AD', 'CH'].map(type => (
              <Grid key={type} item xs={6}>
                <Typography as="h3" color="corporate" weight="bold" noMargins>
                  {`${definitions[type][0].toUpperCase()}${definitions[
                    type
                  ].substring(1)}`}
                </Typography>

                <Typography as="h1" color="rose" weight="bold">
                  {`£${currentData[type].unitPrice}`}
                </Typography>

                <Typography as="p">{currentData[type].description}</Typography>

                <Select
                  label={`No. of ${definitions[
                    type
                  ][0].toUpperCase()}${definitions[type].substring(1)}`}
                  value={quantities[type]}
                  options={currentData[type].availableQuantities.map(o => ({
                    label: o.toString(),
                    value: o,
                  }))}
                  menuPlacement="top"
                  fullWidth
                  onChange={e => updateQuantities(type, e.target.value)}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <Select
                label="No. of Nights"
                value={quantities.nights}
                fullWidth
                menuPlacement="top"
                options={[2, 3, 4, 7, 14]
                  .filter(n => n <= noOfNights)
                  .map(n => ({
                    label: n.toString(),
                    value: n,
                  }))}
                onChange={e => updateQuantities('nights', e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                color="corporate"
                fullWidth
                onClick={() => onSubmit(selectedExtras)}
                disabled={
                  selectedExtras.length === 0 && includedExtras.length === 0
                }
                className={css({ marginTop: 10 })}
              >
                {(() => {
                  if (includedExtras.length > 0) {
                    if (selectedExtras.length === 0) return 'Remove'
                    else return 'Update'
                  }

                  return 'Add'
                })()}
                {' Dining Options'}
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </HolidayExtrasHidden>
    </HolidayExtrasCard>
  )
}

DiningOptionsCard.propTypes = diningOptionsCardPropTypes
export default DiningOptionsCard
