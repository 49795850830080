import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'styletron-react'

import Icon from '../ui/icon'
import Card, { CardContent } from '../ui/card'
import Anchor from '../ui/anchor'

import Theme from '../../context/theme'

const linkCardPropTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

const LinkCard = ({ children, to }) => {
  const { theme } = React.useContext(Theme)
  const [css] = useStyletron()

  const styles = {
    card: { width: '100%' },
    cardContent: { padding: 0 },
    link: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      cursor: 'pointer',
    },
  }

  return (
    <Card elevation={5} color="white" className={css(styles.card)}>
      <CardContent className={css(styles.cardContent)}>
        <Anchor
          color="ownership"
          endIcon={<Icon name="chevron-right" />}
          className={css(styles.link)}
          to={to}
        >
          {children}
        </Anchor>
      </CardContent>
    </Card>
  )
}

LinkCard.propTypes = linkCardPropTypes
export default LinkCard
