import { gql } from 'apollo-boost';

export const GET_INITIAL_DATA = gql`
  query getInitialData($customerNo: CustomerNo!) {

    allMaintenanceJobTypes {
      code
      description
      price
    }

    customerMaintenanceJobs(customerNo: $customerNo) {
      no
      description
      unitNo
      status
      reportedBy
      typeCode
      permissionToEnterUnit
      addedDate
    }
  }
`;

export const SET_MAINTENANCE_JOB = gql`
  mutation CreateMaintenanceJob($input: CreateMaintenanceJobInput! ) {
    createMaintenanceJob(input: $input) {
      no
      description
      unitNo
      status
      reportedBy
      typeCode
      addedDate
    }
  }
`;
