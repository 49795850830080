import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'

import Theme from '../../context/theme'
import AuthContext from '../../context/auth'
import AccountsContext from '../../context/accounts'

import OffCanvas from '../../components/ui/off-canvas'
import Accordian, { AccordianItem } from '../../components/ui/accordian'
import Nav, { NavLink } from './style'
import { hasReadWriteAccess } from '../../helpers'
import useApplyColor from '../../context/theme/hooks/useApplyColor'

const accountNavPropTypes = {
  id: PropTypes.string.isRequired,
  urlPrefix: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActiveLink: PropTypes.func.isRequired,
}

const AccountNav = ({ id, text, urlPrefix, isActiveLink, onClick }) => {
  const { theme } = React.useContext(Theme)
  return (
    <NavLink
      $as={Link}
      key={id}
      $active={isActiveLink(`${urlPrefix}/${id}`)}
      $theme={theme}
      to={`${urlPrefix}/${id}`}
      onClick={onClick}
    >
      {text}
    </NavLink>
  )
}

const ownershipNavOptionsPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  accounts: PropTypes.array,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  onLinkClicked: PropTypes.func.isRequired,
  activeLink: PropTypes.func.isRequired,
}

const ownershipNavOptionsDefaultProps = {
  accounts: [],
  open: false,
  onToggle: undefined,
}

const OwnershipNavOptions = ({
  accounts,
  open,
  onToggle,
  onLinkClicked,
  activeLink,
  ...otherProps
}) => {
  const contentProps = { style: { padding: 0 } }
  const applyColor = useApplyColor();

  return accounts.length > 0 ? (
    <AccordianItem
      {...otherProps}
      title="Holiday Home Ownership"
      color={applyColor}
      contentProps={contentProps}
      open={open}
      onToggle={onToggle}
    >
      <Accordian>
        {accounts.map(account => (
          <AccordianItem
            key={`${account.parkName}_${account.plotNo}`}
            title={`${account.parkName}, ${account.plotNo}`}
            color="ownershipPale"
            contentProps={contentProps}
          >
            {[
              { name: 'Account Activity', slug: 'account' },
              { name: 'Park Services', slug: 'services' },
            ].map(section => (
              <AccountNav
                key={`${account.customerNo}_${section.name}`}
                id={account.customerNo}
                text={section.name}
                urlPrefix={`/owner/${section.slug}`}
                onClick={onLinkClicked}
                isActiveLink={activeLink}
              />
            ))}
          </AccordianItem>
        ))}
      </Accordian>
    </AccordianItem>
  ) : null
}

const holidayNavOptionsPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  bookings: PropTypes.array,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  onLinkClicked: PropTypes.func.isRequired,
  activeLink: PropTypes.func.isRequired,
}

const holidayNavOptionsDefaultProps = {
  bookings: [],
  open: false,
  onToggle: undefined,
}

const HolidayNavOptions = ({
  bookings,
  open,
  onToggle,
  onLinkClicked,
  activeLink,
  ...otherProps
}) => {
  const contentProps = { style: { padding: 0 } }

  return bookings.length > 0 ? (
    <AccordianItem
      {...otherProps}
      title="Holidays & Short Breaks"
      color="holidays"
      contentProps={contentProps}
      open={open}
      onToggle={onToggle}
    >
      <Accordian>
        {/* Bookings */}
        <AccordianItem
          title="Bookings"
          color="holidaysPale"
          contentProps={contentProps}
        >
          {bookings.map(booking => (
            <AccountNav
              key={booking.bookingNo}
              id={booking.bookingNo}
              text={`${booking.bookingNo}`}
              urlPrefix="/bookings"
              onClick={onLinkClicked}
              isActiveLink={activeLink}
            />
          ))}
        </AccordianItem>
      </Accordian>
    </AccordianItem>
  ) : null
}

const sideMenuPropTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

const sideMenuDefaultProps = { open: false }

/**
 * Side Menu
 *
 * @param {*} { open, ...otherProps }
 * @returns
 */
const SideMenu = ({ open, onClose, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  const { user } = React.useContext(AuthContext)
  const accounts = React.useContext(AccountsContext)
  const { location } = otherProps
  const newOtherProps = {
    ...otherProps,
    location: null,
    match: null,
    history: null,
  }

  const ownerAccounts = accounts.ownership.filter(owner =>
    hasReadWriteAccess(owner),
  )

  // const holidayBookings = accounts.holiday
  const hasAccounts = user && (user.scope.owner || user.scope.holiday)
  const isActiveLink = path => location.pathname === path

  const sectorOptionsStyle = {
    borderTop: `1px solid ${theme.palette.common.gray50}`,
    borderBottom: `1px solid ${theme.palette.common.gray50}`,
  }

  return (
    <OffCanvas open={open} onClose={onClose} {...newOtherProps}>
      <Nav>
        <NavLink
          $as={Link}
          $active={isActiveLink('/')}
          $theme={theme}
          to="/"
          onClick={onClose}
        >
          Home
        </NavLink>

        <NavLink
          $as={Link}
          $active={isActiveLink('/profile')}
          $theme={theme}
          to="/profile"
          onClick={onClose}
        >
          Profile
        </NavLink>

        {hasAccounts ? (
          <Accordian>
            <OwnershipNavOptions
              accounts={ownerAccounts}
              onLinkClicked={onClose}
              activeLink={isActiveLink}
              style={sectorOptionsStyle}
            />

            {/* <HolidayNavOptions
              bookings={holidayBookings}
              onLinkClicked={onClose}
              activeLink={isActiveLink}
              style={sectorOptionsStyle}
            /> */}
          </Accordian>
        ) : null}

        <NavLink $as={Link} $theme={theme} to="/logout" onClick={onClose}>
          Logout
        </NavLink>
      </Nav>
    </OffCanvas>
  )
}

SideMenu.propTypes = sideMenuPropTypes
SideMenu.defaultProps = sideMenuDefaultProps
AccountNav.propTypes = accountNavPropTypes
HolidayNavOptions.propTypes = holidayNavOptionsPropTypes
HolidayNavOptions.defaultProps = holidayNavOptionsDefaultProps
OwnershipNavOptions.propTypes = ownershipNavOptionsPropTypes
OwnershipNavOptions.defaultProps = ownershipNavOptionsDefaultProps

export default withRouter(SideMenu)
