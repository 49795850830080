import React from 'react';
import PropTypes from 'prop-types';
import { SchemeWrapper, SchemeSvg } from './style';
import THEME from '../../theme';

const SchemeHelper = ({ scheme }) => {
  return (
    <SchemeWrapper>
      <SchemeSvg>{THEME.cardSchemes[scheme]}</SchemeSvg>
    </SchemeWrapper>
  );
};

SchemeHelper.propTypes = {
  scheme: PropTypes.oneOf(['Visa', 'Mastercard']).isRequired,
};

export default SchemeHelper;
