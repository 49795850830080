import { gql } from 'apollo-boost';

export const GET_OWNER_DETAILS = gql`
  query ownerDetails($customerNo: CustomerNo!) {
    ownerDetails(customerNo: $customerNo) {
      no
      name
      address
      address2
      city
      county
      postcode
      countryRegionCode
      phoneNo
      email
      balance
      currentDealNo
    }
  }
`;

export const PAY_INVOICE = gql`
  mutation PayInvoice($input: PayInvoiceInput! ) {
    payInvoice(input: $input) {
    receiptNo
    }
  }
`;
