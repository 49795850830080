import { gql } from 'apollo-boost';

export const CHECK_PAYER_AUTH_ENROLLED = gql`
  mutation checkPayerAuthEnrolled($input: PayerAuthEnrolledInput!) {
    checkPayerAuthEnrolled(input: $input) {
      reference
      atsData
      cardEnrolled
      acsUrl
      paReq
      transactionId
    }
  }
`;

export const CREATE_PAYER_AUTH_CARD_PAYMENT = gql`
  mutation createPayerAuthCardPayment(
    $input: CreatePayerAuthCardPaymentInput!
  ) {
    createPayerAuthCardPayment(input: $input) {
      reference
      parkId
      amount
      success
      authCode
    }
  }
`;

export const GET_INITIAL_DATA = gql`
  mutation CreateOptoDetails($input: CreateOptoDetailsInput!) {
    createOptoDetails(input: $input) {
      optoReq
      optoHmac
    }
  }
`;
