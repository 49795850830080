import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import creditCardType from 'credit-card-type';
import {
  CHECK_PAYER_AUTH_ENROLLED,
  CREATE_PAYER_AUTH_CARD_PAYMENT,
  GET_INITIAL_DATA,
} from './graphql';
import PaymentForm from './payment-form';
import PayerAuthIframe from './payer-auth-iframe';
import EncryptCardDetailsIframe from './encrypt-card-details-iframe';
import { PaymentWrapper } from './style';
import Error from './components/error';
import Loading from './components/loading';
import Success from './components/success';

/*
Payer Authentication Flow
-------------------------------
1. The cardholder enters their card details on the merchant’s website and 
   presses ‘Pay’.
2. The merchant website performs an ‘enrollment check’ to see whether the 
  card entered is enrolled in the VISA, MasterCard or AMEX schemes.
3. If the card is not enrolled, the transaction is processed outside of 
   the scheme. If the card is enrolled, the details required (PAReq and 
   ACS URL) are sent to the merchant.
4. Cardholder redirected to the ACS page to enter their password or digits 
   from their password.
5. Merchant receives data (PARes) from authentication check.
6. Merchant validates PARes with axept® Gateway.
7. The merchant then decides to proceed or not depending on these results
*/

const initialPayerAuthDetails = {
  termUrl: process.env.REACT_APP_GRAPHQL_API_PAYER_AUTH_ENROLLMENT_URL,
  md: '',
  paReq: '',
  paRes: '',
  acsUrl: '',
  atsData: '',
  cardEnrolled: '',
};

// Stage constants
const INITIAL_STAGE = 0;
const CARD_CAPTURE_STAGE = 1;
const ENCRYPT_CARD_STAGE = 2;
const PAYER_AUTH_AUTHENTICATION_STAGE = 3;
const MAKE_PAYMENT_STAGE = 4;
const PAYMENT_COMPLETE_STAGE = 5;

const Payment = ({
  amount,
  minAmount,
  cardDetails: initialCardDetails,
  onSuccess,
  parkId,
  reference,
}) => {
  const client = useApolloClient();
  const [cardScheme, setCardScheme] = useState('');
  const [cardDetails, setCardDetails] = useState({});
  const [cardEncryptionDetails, setCardEncryptionDetails] = useState({});
  const [payerAuthDetails, setPayerAuthDetails] = useState(
    initialPayerAuthDetails,
  );
  const [optoDetails, setOptoDetails] = useState({
    optoReq: '',
    optoHmac: '',
  });
  const [stage, setStage] = useState(INITIAL_STAGE);
  const [authCode, setAuthCode] = useState('');
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setCardDetails(initialCardDetails);
  }, [initialCardDetails]);

  const sanitizedReference = () => {
    return reference.replace('-', '');
  };

  const reset = () => {
    setCardScheme('');
    setCardEncryptionDetails({});
    setPayerAuthDetails({ ...initialPayerAuthDetails });
  };

  const makePayment = async () => {
    setLoading(true);
    try {
      const {
        cardEnrolled,
        encryptedCardDetails,
        paRes,
        transactionId,
      } = payerAuthDetails;

      const { data } = await client.mutate({
        mutation: CREATE_PAYER_AUTH_CARD_PAYMENT,
        variables: {
          input: {
            schemeId: parseInt(cardEncryptionDetails.schemeId, 10),
            cardEnrolled,
            parkId,
            domain: 'my.parkholidays.com',
            reference: sanitizedReference(),
            amount,
            encryptedCardDetails,
            paRes,
            transactionId,
          },
        },
      });

      setLoading(false);

      if (data.createPayerAuthCardPayment.success === true) {
        setStage(PAYMENT_COMPLETE_STAGE);
        setAuthCode(data.createPayerAuthCardPayment.authCode);
      }
    } catch (err) {
      setLoading(false);
      setErrors([
        { message: `An error has ocurred: ${err.graphQLErrors[0].message}` },
      ]);
      setStage(CARD_CAPTURE_STAGE);
    }
  };

  useEffect(() => {
    if (stage === CARD_CAPTURE_STAGE) {
      reset();
    }

    if (stage === MAKE_PAYMENT_STAGE) {
      makePayment();
    }
  }, [stage]);

  useEffect(() => {
    const getInitialData = async () => {
      try {
        if (!optoDetails.optoReq || !optoDetails.optoHmac) {
          const { data } = await client.mutate({
            mutation: GET_INITIAL_DATA,
            variables: {
              input: {
                amount,
                reference: sanitizedReference(),
                parkId,
                returnUrl: process.env.REACT_APP_GRAPHQL_API_ENCRYPT_CARD_URL,
                tokenize: false,
              },
            },
          });
          setStage(CARD_CAPTURE_STAGE);
          setOptoDetails({
            ...optoDetails,
            ...{
              optoReq: data.createOptoDetails.optoReq,
              optoHmac: data.createOptoDetails.optoHmac,
            },
          });
        }
      } catch (err) {
        setErrors([
          {
            message: `We're having difficulty contacting our payment provider. 
            Please go back and try again.`,
          },
        ]);
      }
    };

    getInitialData();
  }, [amount, client, parkId, reference, optoDetails]);

  useEffect(() => {
    if (stage === PAYMENT_COMPLETE_STAGE) {
      onSuccess();
    }
  }, [stage]);

  const on3DSComplete = ({ paRes }) => {
    setPayerAuthDetails({
      ...payerAuthDetails,
      ...{ paRes },
    });

    setStage(MAKE_PAYMENT_STAGE);
  };

  const checkPayerAuthEnrolled = async encryptedCardDetails => {
    try {
      const { data } = await client.mutate({
        mutation: CHECK_PAYER_AUTH_ENROLLED,
        variables: {
          input: {
            parkId,
            reference: sanitizedReference(),
            amount,
            encryptedCardDetails,
          },
        },
      });

      setPayerAuthDetails({
        ...payerAuthDetails,
        ...{
          acsUrl: data.checkPayerAuthEnrolled.acsUrl,
          atsData: data.checkPayerAuthEnrolled.atsData,
          cardEnrolled: data.checkPayerAuthEnrolled.cardEnrolled,
          encryptedCardDetails,
          transactionId: data.checkPayerAuthEnrolled.transactionId,
          md: data.checkPayerAuthEnrolled.reference,
          paReq: data.checkPayerAuthEnrolled.paReq,
        },
      });

      const nextStage =
        data.checkPayerAuthEnrolled.cardEnrolled === 'Y'
          ? PAYER_AUTH_AUTHENTICATION_STAGE
          : MAKE_PAYMENT_STAGE;

      setStage(nextStage);
    } catch (err) {
      setStage(CARD_CAPTURE_STAGE);
      setErrors([{ message: `Can't contact your bank. Please try again` }]);
    }
  };

  const onEncryptionComplete = data => {
    if (data.errorCode !== '0') {
      setErrors([
        {
          message: `There was an error while processing your payment. 
          ${data.message}. Please check your card details and try again`,
        },
      ]);
      setStage(CARD_CAPTURE_STAGE);
    } else {
      setCardEncryptionDetails(data);
      checkPayerAuthEnrolled(data);
    }
  };

  const handlePaymentSubmit = e => {
    e.preventDefault();
    setErrors([]);
    setStage(ENCRYPT_CARD_STAGE);
  };

  const handleChange = (name, event) => {
    let inputValue =
      event.target.id === 'cardholderName'
        ? event.target.value
        : event.target.value.replace(/\D/g, '');

    if (event.target.id === 'expiryMonthYear') {
      if (inputValue.length === 1 && parseInt(inputValue, 10) > 1) {
        inputValue = `0${inputValue}`;
      }

      if (inputValue.length >= 2) {
        inputValue = `${inputValue.substr(0, 2)}/${inputValue.substr(2, 2)}`;
      }

      setCardDetails({ ...cardDetails, ...{ [name]: inputValue } });
    }

    if (event.target.id === 'cardNumber' || event.target.id === 'csc') {
      const numbersOnly = /^[0-9\b]+$/;
      if (inputValue === '' || numbersOnly.test(inputValue)) {
        setCardDetails({ ...cardDetails, ...{ [name]: inputValue } });
      }
    } else {
      setCardDetails({ ...cardDetails, ...{ [name]: inputValue } });
    }

    if (event.target.id === 'cardNumber') {
      const cardType = creditCardType(inputValue);
      if (
        cardType &&
        cardType.length &&
        ['Visa', 'Mastercard'].includes(cardType[0].niceType)
      ) {
        const { niceType } = cardType[0];
        setCardScheme(niceType);
      } else {
        setCardScheme('');
      }
    }
  };

  const getLoadingMessage = () => {
    if (stage === INITIAL_STAGE)
      return 'Please wait. Loading payment interface...';
    if (stage === PAYER_AUTH_AUTHENTICATION_STAGE)
      return 'Please wait. Authenticated card with your bank...';
    if (stage === MAKE_PAYMENT_STAGE)
      return 'Please wait. Processing payment...';
    return 'Loading...';
  };

  return (
    <PaymentWrapper>
      {loading ? (
        <Loading text={getLoadingMessage()} />
      ) : (
        <>
          {errors.length > 0 && (
            <Error icon="warning" text={errors.map(error => error.message)} />
          )}

          {stage === CARD_CAPTURE_STAGE && (
            <PaymentForm
              transactionAmount={amount}
              minTransactionAmount={minAmount}
              cardScheme={cardScheme}
              cardDetails={cardDetails}
              handleChange={handleChange}
              handleSubmit={handlePaymentSubmit}
            />
          )}

          {stage === ENCRYPT_CARD_STAGE && (
            <>
              <Loading text="Please wait, contacting your bank..." />
              <EncryptCardDetailsIframe
                cardDetails={cardDetails}
                optoDetails={optoDetails}
                onEncryptionComplete={onEncryptionComplete}
              />
            </>
          )}

          {stage === PAYER_AUTH_AUTHENTICATION_STAGE && (
            <>
              <PayerAuthIframe
                payerAuthDetails={payerAuthDetails}
                on3DSComplete={on3DSComplete}
              />
            </>
          )}

          {stage === PAYMENT_COMPLETE_STAGE && (
            <Success
              text={`Authorisation code: ${authCode}`}
              transactionAmount={amount}
            />
          )}
        </>
      )}
    </PaymentWrapper>
  );
};

export default Payment;

Payment.propTypes = {
  amount: PropTypes.number.isRequired,
  minAmount: PropTypes.number.isRequired,
  cardDetails: PropTypes.shape({
    cardNumber: PropTypes.string.isRequired,
    cardholderName: PropTypes.string.isRequired,
    expiryMonthYear: PropTypes.string.isRequired,
    csc: PropTypes.string.isRequired,
  }),
  onSuccess: PropTypes.func.isRequired,
  parkId: PropTypes.number,
  reference: PropTypes.string.isRequired,
};

Payment.defaultProps = {
  parkId: null,
  cardDetails: {
    cardNumber: '',
    cardholderName: '',
    expiryMonthYear: '',
    csc: '',
  },
};
