import React from 'react'
import useIsPl2k from '../../../context/theme/hooks/useIsPl2k'
import ParkHolidaysBrandLogo from './ParkHolidaysBrandLogo'
import ParkLeisureBrandLogo from './ParkLeisureBrandLogo'

const BrandLogo = ({logoName, width}) => {
  const isPl2k = useIsPl2k()
  return isPl2k ? (
    <ParkLeisureBrandLogo logoName={logoName} width={width} />
  ) : (
    <ParkHolidaysBrandLogo width={width} />
  )
}

export default BrandLogo
