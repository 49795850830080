import React from 'react';
import PropTypes from 'prop-types';
import ReactImage from 'react-image';

const styles = {
  maxWidth: '100%',
};

const Image = ({ src, alt, ...otherProps }) => (
  <ReactImage
    src={src}
    alt={alt}
    {...otherProps}
    style={{ ...styles, ...(otherProps.style || {}) }}
  />
);

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Image;
