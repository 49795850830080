import { gql } from 'apollo-boost';

const GET_BOOKING = gql`
  query($id: ID!) {
    booking(id: $id) {
      id
      bookingType
      arrivalDate
      noOfNights
      departureDate
      noOfAdults
      noOfChildren
      checkInTime
      checkOutTime
      discountAmount
      standardPrice
      bookingPrice
      totalExtras
      outstandingAmount
      confirmed
      tagged
      confirmedDate
      cancelled
      cancelledDate
      bookingAgentGradeTranslation {
        id
        originalLeadSource
        description
      }
      extras {
        code
        quantity
        description
        unitPrice
        amount
      }
      park {
        id
        code
        name
        address {
          addressLine1
          addressLine2
          town
          county
          postcode
        }
        logo {
          path
          alternateText
        }
      }
      hireType {
        id
        code
        description
        bedrooms
        berths
        grade {
          id
          name
        }
        accommodationType {
          id
          name
        }
      }
    }
  }
`;

export default GET_BOOKING;
