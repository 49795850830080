import React from 'react'
import { Helmet } from 'react-helmet-async'

const ParkLeisureHead = () => {
  return (
    <Helmet>
      <title>My Park Leisure</title>
      <link rel="canonical" href="https://my.parkleisure.com/" />
      <link rel="icon" href="/favicon-pl.ico" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
    </Helmet>
  )
}

export default ParkLeisureHead
