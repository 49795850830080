import { useQuery } from '@apollo/react-hooks'
import { format, parse, subDays } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'

import { ownerBalance } from '../../../helpers'
import GET_ACCOUNT_ACTIVITY from './graphql'

import Card, { CardContent, CardHeader } from '../../../components/ui/card'
import Container from '../../../components/ui/container'
import Divider from '../../../components/ui/divider'
import Typography from '../../../components/ui/typography'

import VisibleOn, { HiddenOn } from '../../../components/hoc/visible-on'
import Loader from '../../../components/ui/loading'

import Notification from '../../../components/Notification/Notification'
import Search from '../../../components/OwnersArea/AccountActivity/Search'
import Transaction from '../../../components/OwnersArea/AccountActivity/Transaction'
import Balance from '../../../components/OwnersArea/Balance'

import PagedResults from '../../../components/PagedResults'
import Theme from '../../../context/theme'
import useLineColor from '../../../context/theme/hooks/useLineColor'

const getShowing = (filters, selectedDateRange) => {
  const dateRange = `(${format(filters.startDate, 'dd/MM/yyyy')} - ${format(
    filters.endDate,
    'dd/MM/yyyy',
  )})`

  if (selectedDateRange === 'all') return `All transactions ${dateRange}`
  if (selectedDateRange === 'custom') return `Custom Range ${dateRange}`
  return `Last ${selectedDateRange} Days ${dateRange}`
}

/**
 * Account Activity / Balance Component View
 *
 * @param {*} props
 * @returns
 */
const AccountActivity = ({ match }) => {
  const { theme } = useContext(Theme)
  const [customerLedger, setCustomerLedger] = useState(null)
  const [filteredTransactions, setFilteredTransactions] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const { customerNo } = match.params

  const customerBalance = customerLedger
    ? ownerBalance(customerLedger.customerBalance).split(' ')
    : null
  const balance = customerBalance ? customerBalance[0] : null
  const balanceType = customerBalance ? customerBalance[1].toLowerCase() : null

  // SEARCH
  const today = new Date()
  const [defaultStartDate, setDefaultStartDate] = useState(
    new Date(new Date().getFullYear() - 2, 0, 1),
  )
  const [searchFilters, setSearchFilters] = useState({
    defaultStartDate,
    startDate: defaultStartDate,
    endDate: today,
  })

  const [selectedDateRange, setSelectedDateRange] = useState('all')
  const [showCustomDateRange, setShowCustomDateRange] = useState(false)
  const [showing, setShowing] = useState(
    getShowing(searchFilters, selectedDateRange),
  )

  // STYLES
  const styles = {
    header: {
      card: { marginBottom: theme.spacing(1) },
      cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: 0,
      },
      cardContent: { paddingBottom: 0 },
      divider: { marginTop: 0 },
    },
  }

  const { loading, error, data } = useQuery(GET_ACCOUNT_ACTIVITY, {
    variables: {
      criteria: {
        customerNo,
        open: false,
        documentType: 'all',
      },
    },
  })

  // TRANSACTIONS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterTransactions = React.useCallback(transactions => {
    return transactions.filter(
      transaction =>
        transaction.postingDate.substr(0, 10) >=
          format(searchFilters.startDate, 'yyyy-MM-dd') &&
        transaction.postingDate.substr(0, 10) <=
          format(searchFilters.endDate, 'yyyy-MM-dd'),
    )
  })

  const sortTransactions = transactions =>
    transactions.sort((a, b) => b.postingDate.localeCompare(a.postingDate))

  // CUSTOM METHODS
  const handleDateRangeChange = e => {
    setSelectedDateRange(e.target.value)
    setShowCustomDateRange(e.target.value === 'custom')

    if (e.target.value !== 'custom') {
      const startDate =
        e.target.value === 'all'
          ? searchFilters.defaultStartDate
          : subDays(today, e.target.value)
      const endDate = today
      const newFilters = { startDate, endDate }
      setSearchFilters({ ...searchFilters, ...newFilters })
    }
  }

  const getCustomDate = e => {
    let date = null

    if (e.target.name === 'startDate') {
      date = e.target.value === '' ? defaultStartDate : new Date(e.target.value)
    } else if (e.target.name === 'endDate') {
      date = e.target.value === '' ? new Date() : new Date(e.target.value)
    }

    return date
  }

  const handleCustomDateRangeChange = e => {
    setSearchFilters({
      ...searchFilters,
      ...{ [e.target.name]: getCustomDate(e) },
    })
  }

  const handleSearch = () => {
    setShowing(getShowing(searchFilters, selectedDateRange))
    setFilteredTransactions(
      sortTransactions(
        filterTransactions(
          customerLedger ? customerLedger.customerLedgerEntries : [],
        ),
      ),
    )
    setCurrentPage(1)
  }

  // const transactions = isMobile => filteredTransactions.map(transaction => (
  //   <Transaction key={transaction.entryNo} transaction={transaction} isMobile={isMobile} />
  // ));

  React.useEffect(() => {
    if (data) {
      setCustomerLedger(data.customerLedger)
    }
  }, [data, loading])

  React.useEffect(() => {
    if (customerLedger) {
      const filteredSortedTransactions = sortTransactions(
        filterTransactions(customerLedger.customerLedgerEntries),
      )
      setFilteredTransactions(filteredSortedTransactions)
      const earliestTransaction =
        filteredSortedTransactions[filteredSortedTransactions.length - 1]

      if (earliestTransaction) {
        setDefaultStartDate(parse(earliestTransaction.postingDate, 'yyyy-MM-dd HH:mm:SS', new Date()))
        setSearchFilters({
          ...searchFilters,
          startDate: parse(earliestTransaction.postingDate, 'yyyy-MM-dd HH:mm:SS', new Date()),
        })
        setShowing(
          getShowing(
            {
              ...searchFilters,
              startDate: parse(earliestTransaction.postingDate, 'yyyy-MM-dd HH:mm:SS', new Date()),
            },
            selectedDateRange,
          ),
        )
      }
      //console.log('set default start date = ', new Date(earliestTransaction.postingDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerLedger])

  const lineColor = useLineColor();

  if (error)
    return <Notification title="errors" messages={error.graphQLErrors} />
  return (
    <>
      <div
        style={{ borderTop: `4px solid ${lineColor}`, height: 0 }}
      />
      <Container style={{ marginTop: theme.spacing(2) }}>
        {/* HEADER */}
        <Card color="white" rounded={false} style={styles.header.card}>
          <CardHeader style={styles.header.cardHeader}>
            <Typography as="h3" weight="bold" noMargins>
              Account Activity
            </Typography>
            <Typography as="small" transform="uppercase">
              {customerNo}
            </Typography>
          </CardHeader>

          <CardContent style={styles.header.cardContent}>
            <Divider style={styles.header.divider} />
            <Balance value={balance} type={balanceType} />
          </CardContent>
        </Card>

        {/* SEARCH */}
        <Search
          filters={searchFilters}
          handleDateRangeChange={handleDateRangeChange}
          handleCustomDateRangeChange={handleCustomDateRangeChange}
          handleSearch={handleSearch}
          showCustomDateRange={showCustomDateRange}
          showing={showing}
        />

        {/* TRANSACTIONS */}
        {loading ? (
          <Loader
            sector="ownership"
            style={{
              position: 'relative',
              padding: theme.spacing(2),
              border: 'none',
            }}
          />
        ) : (
          <>
            <VisibleOn
              breakpoints={['xs', 'sm', 'md']}
              component={
                <PagedResults
                  items={filteredTransactions}
                  itemComponent={Transaction}
                  itemKey="entryNo"
                  isMobile
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              }
            />

            <HiddenOn
              breakpoints={['xs', 'sm', 'md']}
              component={
                <PagedResults
                  items={filteredTransactions}
                  itemComponent={Transaction}
                  itemKey="entryNo"
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              }
            />
          </>
        )}
      </Container>
    </>
  )
}

AccountActivity.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ customerNo: PropTypes.string }),
  }).isRequired,
}

export default React.memo(AccountActivity)
