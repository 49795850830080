import React from 'react'
import PropTypes from 'prop-types'

import Card, { CardContent } from '../card'
import Typography from '../typography'

const propTypes = {
  context: PropTypes.oneOf(['error', 'info', 'warning', 'success']),
  pale: PropTypes.bool,
  title: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const Notification = ({ context, pale, title, messages, ...otherProps }) => {
  if (!messages.length) return null

  let contextProps = {}
  if (context) {
    contextProps = {
      color: pale ? `${context}50` : context,
      $style: {
        ...(otherProps.style || {}),
      },
    }
  }

  return (
    <Card {...otherProps} {...contextProps}>
      <CardContent>
        {title && (
          <Typography as="h3" noMargins align="left" weight="bold">
            {title}
          </Typography>
        )}
        {messages.map(message => (
          <Typography as="p" key={message} align="left" noMargins>
            {message}
          </Typography>
        ))}
      </CardContent>
    </Card>
  )
}

Notification.propTypes = propTypes
Notification.defaultProps = { pale: false }
export default Notification
