import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import Card, { CardContent, CardActions } from '../../ui/card'
import Loading from '../../ui/loading'
import Icon from '../../ui/icon'
import Button, { LinkButton, IconButton } from '../../ui/button'
import Typography from '../../ui/typography'
import Theme from '../../../context/theme'

const parkDetailsPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  park: PropTypes.object.isRequired,
}

/**
 * Details on the park the booking is for
 *
 * @param {*} { park }
 */
const ParkDetails = ({ park }) => (
  <CardContent>
    <Typography as="h2" weight="bold" noMargins>
      {`${park.name} Holiday Park`}
    </Typography>

    <Typography color="text">
      {`${park.address.town}, ${park.address.county}, ${park.address.postcode}`}
    </Typography>
  </CardContent>
)

const accomodationPricePropTypes = {
  bookingPrice: PropTypes.number.isRequired,
  grade: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  berths: PropTypes.number.isRequired,
  noOfGuests: PropTypes.number.isRequired,
}

/**
 * Total price of the booking and details on accomodation
 *
 * @param {*} { booking }
 */
const AccomodationPrice = ({
  bookingPrice,
  grade,
  type,
  berths,
  noOfGuests,
}) => (
  <CardContent>
    <Typography
      as="h3"
      color="text"
      weight="bold"
      afterEnhancer={
        <Typography weight="bold" style={{ fontSize: 'inherit' }}>
          {`£${parseFloat(bookingPrice).toFixed(2)}`}
        </Typography>
      }
      noMargins
    >
      Accomodation Cost:
    </Typography>

    <Typography color="text">{`${grade} ${type}, ${berths} berth`}</Typography>
    <br />
    <Typography
      as="em"
      color="text"
    >{`Price includes up to ${noOfGuests} people`}</Typography>
  </CardContent>
)

const bookingDetailsPropTypes = {
  arrivalDate: PropTypes.string.isRequired,
  departureDate: PropTypes.string.isRequired,
  noOfNights: PropTypes.number.isRequired,
}

/**
 * Details on the booking
 *
 * @param {*} { arrivalDate, departureDate, noOfNights }
 */
const BookingDetails = ({ arrivalDate, departureDate, noOfNights }) => (
  <CardContent>
    <Typography as="p" color="text">
      <Typography weight="bold">Arriving on</Typography>
      <br />
      <Typography>
        {format(new Date(arrivalDate), 'EEEE do MMMM yyyy')}
      </Typography>
    </Typography>

    <Typography as="p" color="text">
      <Typography weight="bold">Departing on</Typography>
      <br />
      <Typography>
        {format(new Date(departureDate), 'EEEE do MMMM yyyy')}
      </Typography>
    </Typography>

    <Typography as="p" color="text" noMargins>
      <Typography weight="bold">Duration</Typography>
      <br />
      <Typography>{`${noOfNights} nights`}</Typography>
    </Typography>
  </CardContent>
)

const holidayExtrasPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  extras: PropTypes.array.isRequired,
}

/**
 * List of all the currently selected extras
 *
 * @param {*} { extras }
 */
const HolidayExtras = ({ extras, onRemoveExtra }) => {
  const price = extras.reduce(
    (prev, current) => prev + current.unitPrice * current.quantity,
    0,
  )
  return (
    <CardContent>
      <Typography
        as="h3"
        color="text"
        weight="bold"
        style={{ marginBottom: 8 }}
        afterEnhancer={
          <Typography weight="bold" style={{ fontSize: 'inherit' }}>
            {`£${parseFloat(price).toFixed(2)}`}
          </Typography>
        }
      >
        Added Extras
      </Typography>

      {extras.length > 0 ? (
        <table>
          <tbody>
            {extras.map(extra => (
              <tr key={extra.code}>
                <td>
                  <Typography
                    key={extra.code}
                    color={extra.bookable ? null : 'text'}
                  >
                    {extra.description}
                  </Typography>
                </td>

                <td>
                  <Typography
                    key={extra.code}
                    color={extra.bookable ? null : 'text'}
                  >{`x${extra.quantity}`}</Typography>
                </td>
                <td>
                  <Typography
                    color={extra.bookable ? null : 'text'}
                    style={{ fontSize: 'inherit' }}
                  >
                    {`£${parseFloat(extra.unitPrice * extra.quantity).toFixed(
                      2,
                    )}`}
                  </Typography>
                </td>
                <td style={{ lineHeight: 0 }}>
                  <IconButton
                    variant="text"
                    size="small"
                    color="error"
                    onClick={
                      extra.bookable ? () => onRemoveExtra(extra.code) : null
                    }
                    disabled={!extra.bookable}
                    style={{
                      padding: 0,
                      width: 16,
                      height: 16,
                      minHeight: 16,
                    }}
                  >
                    <Icon name="trash" size={16} />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Typography>No holiday extras selected</Typography>
      )}
    </CardContent>
  )
}

const bookingDetailsCardPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  booking: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  currentExtras: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedExtras: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  paymentShown: PropTypes.bool.isRequired,
  onSubmitClicked: PropTypes.func.isRequired,
}

const bookingDetailsCardDefaultProps = { booking: null }

/**
 * Booking Details Card Component
 * Contains booking details and continue button
 *
 * @returns
 */
const BookingDetailsCard = ({
  booking,
  currentExtras,
  selectedExtras,
  loading,
  paymentShown,
  onSubmitClicked,
  onRemoveExtra,
}) => {
  const { theme } = React.useContext(Theme)
  return (
    <Card elevation={5} color="corporate" outlined>
      <CardActions $style={{ paddingTop: '16px', paddingBotton: '16px' }}>
        <LinkButton
          to={`/bookings/${booking ? booking.id : ''}`}
          color="corporate"
          startIcon={<Icon name="chevron-left" />}
          fullWidth
          disabled={loading}
        >
          Return to Booking
        </LinkButton>
      </CardActions>

      {loading ? (
        <Loading
          text="Loading Booking Details.."
          style={{ position: 'relative', padding: 16, border: 'none' }}
        />
      ) : (
        <>
          <ParkDetails park={booking.park} />
          <BookingDetails
            arrivalDate={booking.arrivalDate}
            departureDate={booking.departureDate}
            noOfNights={booking.noOfNights}
          />

          <AccomodationPrice
            bookingPrice={booking.bookingPrice}
            grade={booking.hireType.grade.name}
            type={booking.hireType.accommodationType.name}
            berths={booking.hireType.berths}
            noOfGuests={booking.noOfAdults + booking.noOfChildren}
          />

          <HolidayExtras
            extras={[
              ...currentExtras.map(extra => ({ ...extra, bookable: false })),
              ...selectedExtras,
            ]}
            onRemoveExtra={onRemoveExtra}
          />

          <CardContent
            style={{
              marginTop: theme.spacing(2),
              padding: theme.spacing(2),
              backgroundColor: theme.palette.common.gray100,
            }}
          >
            <Typography
              as="h3"
              color="text"
              weight="bold"
              afterEnhancer={
                <Typography weight="bold" style={{ fontSize: 'inherit' }}>
                  {`£${parseFloat(
                    booking.bookingPrice +
                      booking.totalExtras +
                      selectedExtras.reduce(
                        (prev, current) =>
                          prev + current.unitPrice * current.quantity,
                        0,
                      ),
                  ).toFixed(2)}`}
                </Typography>
              }
              noMargins
            >
              Total
            </Typography>
          </CardContent>
        </>
      )}

      <CardActions $style={{ paddingTop: '16px', paddingBottom: '16px' }}>
        <Button
          color="corporate"
          fullWidth
          disabled={loading || selectedExtras.length === 0}
          onClick={() => onSubmitClicked(true)}
          style={{ marginBottom: 10 }}
        >
          {`${paymentShown ? 'Cancel' : 'Make'} Payment`}
        </Button>

        <Button
          color="corporate"
          fullWidth
          disabled={loading || selectedExtras.length === 0}
          onClick={() => onSubmitClicked(false)}
        >
          Add &amp; Pay Later
        </Button>
      </CardActions>
    </Card>
  )
}

ParkDetails.propTypes = parkDetailsPropTypes
AccomodationPrice.propTypes = accomodationPricePropTypes
BookingDetails.propTypes = bookingDetailsPropTypes
HolidayExtras.propTypes = holidayExtrasPropTypes
BookingDetailsCard.propTypes = bookingDetailsCardPropTypes
BookingDetailsCard.defaultProps = bookingDetailsCardDefaultProps

export default BookingDetailsCard
