import React from 'react';
import PropTypes from 'prop-types';

const PaymentAuthIframe = ({ payerAuthDetails, on3DSComplete }) => {
  const { acsUrl, md, paReq, termUrl } = payerAuthDetails;

  const payerAuthFormRef = React.useRef(null);

  const listenForPayerAuthResponse = e => {
    if (e.data.event === '3DS-authentication-complete') {
      on3DSComplete(e.data);
      window.removeEventListener('message', listenForPayerAuthResponse, false);
    }
  };

  React.useEffect(() => {
    if (payerAuthFormRef && payerAuthFormRef.current) {
      window.addEventListener('message', listenForPayerAuthResponse, false);
      payerAuthFormRef.current.submit();
    }
  }, [payerAuthFormRef]);

  return (
    <>
      <form action={acsUrl} method="POST" target="sca" ref={payerAuthFormRef}>
        <input type="hidden" name="TermUrl" value={termUrl} readOnly />
        <input type="hidden" name="MD" value={md} readOnly />
        <input type="hidden" name="PaReq" value={paReq} readOnly />
      </form>
      <iframe
        title="sca payment"
        name="sca"
        style={{ height: 470, width: 370, border: 'none' }}
      />
    </>
  );
};

export default PaymentAuthIframe;

PaymentAuthIframe.propTypes = {
  payerAuthDetails: PropTypes.shape({
    acsUrl: PropTypes.string.isRequired,
    termUrl: PropTypes.string.isRequired,
    paReq: PropTypes.string.isRequired,
    md: PropTypes.string.isRequired,
  }).isRequired,
  on3DSComplete: PropTypes.func.isRequired,
};
