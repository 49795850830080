import React from 'react'
import { Helmet } from 'react-helmet-async'

const ParkHolidaysHead = () => {
    return (
      <Helmet>
        <title>My Park Holidays</title>
        <link rel="canonical" href="https://my.parkholidays.com/" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/logo192.png" />
      </Helmet>
    )

}

export default ParkHolidaysHead
