import React from 'react'
import PropTypes from 'prop-types'

import Grid from '../ui/grid'
import Typography from '../ui/typography'
import Icon from '../ui/icon'
import Popover from '../ui/popover'

import Theme from '../../context/theme'

const Balance = ({ value, type }) => {
  const { theme } = React.useContext(Theme)
  const [popoverOpen, setPopoverOpen] = React.useState(false)

  const balanceValue = value || '£0.00'
  const textColor = value === 0 ? null : type === 'debit' ? 'red' : 'green'
  const balanceText = `${
    type === 'debit' ? `You owe` : `Your balance is`
  } ${balanceValue}`

  return (
    <Grid>
      <Grid item xs={12} md={4} style={{ marginBottom: 0 }}>
        <Grid alignItems="center">
          {/* style={{ flexShrink: 0 }} */}
          <Grid item xs={12} md="auto">
            <Typography
              as="h2"
              weight="bold"
              color={textColor}
              transform="uppercase"
              noMargins
              afterEnhancer={
                <Icon
                  name="info-circle"
                  size={22}
                  color="corporate"
                  style={{
                    marginLeft: 6,
                    marginBottom: -3.5,
                    cursor: 'pointer',
                  }}
                  onClick={() => setPopoverOpen(!popoverOpen)}
                />
              }
            >
              {balanceText}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {popoverOpen ? (
        <Grid item xs={12} md="auto" style={{ flex: 1 }}>
          <Popover
            color="corporate"
            title={
              <Typography as="strong">
                How your balance is calculated:
              </Typography>
            }
            trianglePosition={
              window.innerWidth < theme.breakpoints.width('sm') ? 'top' : 'left'
            }
          >
            <Typography as="p" noMargins>
              Your total balance is a running total, taking into account the
              deduction of any invoices and transactions. If your account is in
              credit when invoices are due, this will be deducted from your
              total balance, however the credit amount will not be allocated
              against an invoice unless requested.
            </Typography>
          </Popover>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default Balance

Balance.propTypes = {
  value: PropTypes.string || PropTypes.number,
  type: PropTypes.string,
}
Balance.defaultProps = { value: '£0.00', type: '' }
