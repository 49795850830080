/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect, { components } from 'react-select'

import Theme from '../../../context/theme'
import Icon from '../icon'
import FormLabel from '../formlabel'

import NativeSelectRoot from './style'

/**
 * Native Select Component
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
const NativeSelect = ({ children, ariaProps, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  const placeholder = otherProps.placeholder ? (
    <option hidden>{otherProps.placeholder}</option>
  ) : null

  return (
    <NativeSelectRoot
      {...otherProps}
      {...ariaProps}
      $as="select"
      $theme={theme}
    >
      {placeholder}
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          role: 'option',
          'aria-selected': child.props.value === otherProps.value,
        }),
      )}
    </NativeSelectRoot>
  )
}

/**
 * React Select Component
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
const CustomSelect = ({ options, ariaProps, inheritColor, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  const id = otherProps.id || null
  const newProps = {
    ...otherProps,
    isDisabled: otherProps.disabled,
    id: null,
  }

  const styles = {
    ...theme.typography.base,
    color: theme.palette.text,
  }

  const inheritedStyles = inheritColor
    ? {
        color: 'inherit',
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: 0,
      }
    : {}

  const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>,
  ) => (
    <components.DropdownIndicator {...props}>
      <Icon
        name="caret-down"
        color={inheritColor ? 'currentColor' : 'text'}
        size={18}
      />
    </components.DropdownIndicator>
  )

  const IndicatorSeparator = ({ innerProps }) => null // <span style={indicatorSeparatorStyle} {...innerProps} />;
  return (
    <ReactSelect
      {...newProps}
      options={options}
      isMulti={newProps.multiple}
      styles={{
        container: base => ({
          ...base,
          width: '100%',
        }),
        control: base => ({
          ...base,
          ...styles,
          padding: '4px 0',
          ...inheritedStyles,
        }),
        placeholder: base => ({
          ...base,
          ...inheritedStyles,
        }),
        singleValue: base => ({ ...base, ...inheritedStyles }),
        menu: base => ({ ...base, ...styles, zIndex: 1001 }),
      }}
      value={options.find(o => o.value === otherProps.value)}
      components={{ DropdownIndicator, IndicatorSeparator }}
      inputId={id ? otherProps.id : null}
      inputProps={{ ...ariaProps }}
    />
  )
}

/**
 *  Select Control
 *
 * @param {*} { children, native, options, rounded, fullWidth, helperText, ...otherProps }
 * @returns
 */
const Select = ({
  children,
  native,
  label,
  options,
  rounded,
  fullWidth,
  helperText,
  labelProps,
  onChange,
  ...otherProps
}) => {
  const fieldId = otherProps.id || `select_${new Date().valueOf()}`
  const labelId = `${fieldId}_label`

  const ariaProps = {
    'aria-label': label,
    'aria-labelledby': label ? labelId : null,
    'aria-expanded': false,
    'aria-required': otherProps.required || false,
  }

  const handleChange = event => {
    if (native) onChange(event)
    else {
      let value = ''
      if (event && !otherProps.multiple) value = event.value

      onChange({
        target: {
          id: fieldId,
          name: otherProps.name,
          value,
          selectedOptions: (!otherProps.multiple && event
            ? [event]
            : event || []
          ).map(option => ({
            ...option,
            text: option.label,
          })),
        },
      })
    }
  }

  const NativeComponent = () => (
    <NativeSelect
      $rounded={rounded}
      $fullWidth={fullWidth}
      {...otherProps}
      ariaProps={{ ...ariaProps }}
      onChange={handleChange}
    >
      {children}
    </NativeSelect>
  )

  return (
    <>
      {label ? (
        <FormLabel
          id={labelId}
          htmlFor={fieldId}
          fullWidth
          {...(labelProps || {})}
          style={{
            marginBottom: 8,
            ...((labelProps && labelProps.style) || {}),
          }}
        >
          {label}
        </FormLabel>
      ) : null}

      {native ? (
        <NativeComponent
          {...otherProps}
          id={fieldId}
          ariaProps={{ ...ariaProps }}
        />
      ) : (
        <CustomSelect
          {...otherProps}
          id={fieldId}
          options={options}
          ariaProps={ariaProps}
          onChange={handleChange}
        />
      )}
    </>
  )
}

/** Component Property Types */
NativeSelect.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  ariaProps: PropTypes.shape({
    ariaLabel: PropTypes.string,
    ariaLabelledby: PropTypes.string,
    ariaExpanded: PropTypes.bool,
    ariaRequired: PropTypes.bool,
  }).isRequired,
}

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
    }),
  ),
  ariaProps: PropTypes.shape({
    'aria-label': PropTypes.string,
    'aria-labelledby': PropTypes.string,
    'aria-expanded': PropTypes.bool,
    'aria-required': PropTypes.bool,
  }).isRequired,
  inheritColor: PropTypes.bool,
}

CustomSelect.defaultProps = { inheritColor: false, options: [] }

Select.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    }),
  ),
  native: PropTypes.bool,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  labelProps: PropTypes.object,
  onChange: PropTypes.func,
}

Select.defaultProps = {
  children: undefined,
  native: false,
  options: [],
  helperText: undefined,
  fullWidth: false,
  rounded: true,
  labelProps: {},
  onChange: () => {},
}

export default Select
