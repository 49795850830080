import React from 'react';
import PropTypes from 'prop-types';
import { InputWrapper, BaseInput, EndEnhancer } from './style';

const Input = ({ endEnhancer, ...restProps }) => {
  return (
    <InputWrapper>
      <BaseInput {...restProps} />
      {endEnhancer && (
        <EndEnhancer>
          {typeof endEnhancer === 'function' ? endEnhancer() : endEnhancer}
        </EndEnhancer>
      )}
    </InputWrapper>
  );
};

Input.propTypes = {
  // @ todo
};

Input.defaultProps = {
  autoComplete: 'on',
  autoFocus: false,
  disabled: false,
  name: '',
  required: false,
  endEnhancer: null,
};

export default Input;
