const isParkLeisureContext = () => {
  const allPlDomains = ["my-staging-pl.parkholidays.com","my.parkleisure.co.uk", "my2.parkleisure.co.uk"]
  const isPL = allPlDomains.includes(window.location.hostname)
  console.log('ISPLCONTEXT', isPL)
  return isPL
}

const isStagingDomain = () => {
  const stagingDomains = ["my-staging-pl.parkholidays.com","my-staging-ph.parkholidays.com"]
  const isStaging = stagingDomains.includes(window.location.hostname)
  console.log('ISSTAGING', isStaging)
  return isStaging
}

export {isParkLeisureContext, isStagingDomain}
