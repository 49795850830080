import React from 'react'
import Anchor from '../../components/ui/anchor'
import PropTypes from 'prop-types'
import { PH_HELP } from '../../config'

const ParkHolidaysHelp = ({ href, text }) => {
  const mailToLink = href ?? PH_HELP
  return <Anchor href={`mailto:${mailToLink}`}>{text ?? PH_HELP}</Anchor>
}

ParkHolidaysHelp.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
}

export default ParkHolidaysHelp
