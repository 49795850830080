import React from 'react'
import PropTypes from 'prop-types'

import Card, { CardContent } from '../ui/card'
import Typography from '../ui/typography'

const notificationsPropTypes = {
  title: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const Notification = ({ title, messages, ...otherProps }) => {
  if (!messages.length) return null

  return (
    <Card {...otherProps}>
      <CardContent>
        <Typography as="h3" noMargins align="left">
          {title}
        </Typography>
        {messages.map((message, index) => (
          <Typography
            as="p"
            key={message}
            align="left"
            noMargins={index === messages.length - 1}
          >
            {message}
          </Typography>
        ))}
      </CardContent>
    </Card>
  )
}

Notification.propTypes = notificationsPropTypes
export default Notification
