import React from 'react';
import Typography from '../ui/typography';

const PasswordRules = () => (
  <div style={{ paddingTop: 16 }}>
    <Typography as="p">Passwords must:</Typography>
    <ul style={{ marginBottom: 0 }}>
      <li>
        <Typography>Be between 8 and 30 characters.</Typography>
      </li>
      <li>
        <Typography>Contain at least one digit.</Typography>
      </li>
      <li>
        <Typography>Contain at least one uppercase character.</Typography>
      </li>
      <li>
        <Typography>Contain at least one lowercase character.</Typography>
      </li>
    </ul>
  </div>
);

export default PasswordRules;
