import { gql } from 'apollo-boost';

const GET_INITIAL_DATA = gql`
{
  contactPreferences {
    marketingProductCode
    communicationTypeCode
    optIn
  }

  allAccessLevels {
    id
    name
  }

  accountGroup {
    id
    name
    primaryUserId
    secondaryUsers {
      id
      email
      contactNo
      accessLevel
      accessLevelId
    }
  }
}
`;

const UPDATE_CONTACT_PREFERENCES = gql`
  mutation updateContact($input: UpdateContactPreferencesInput!) {
    updateContactPreferences(input: $input) {
      marketingProductCode
      communicationTypeCode
      optIn
    }
  }
`;

const UPDATE_ACCOUNT_GROUP_USERS = gql`
  mutation updateAccountGroupUsers($input: [UpdateAccountGroupUserInput!] ) {
    updateAccountGroupUsers(input: $input) {
      id
      name
      primaryUserId
      secondaryUsers {
        id
        email
        contactNo
        accessLevel
        accessLevelId
      }
    }
  }
`;

const REMOVE_ACCOUNT_GROUP_USER = gql`
  mutation removeAccountGroupUser($input: RemoveAccountGroupUserInput! ) {
    removeAccountGroupUser(input: $input) {
      id
      name
      primaryUserId
      secondaryUsers {
        id
        email
        contactNo
        accessLevel
      }
    }
  }
`;

const SEND_ACCOUNT_GROUP_USER_INVITE = gql`
  mutation sendAccountGroupUserInvite($input: AccountGroupUserInviteInput!) {
    sendAccountGroupUserInvite(input: $input) {
      accountGroupId
      accessLevelId
      email
      token
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput! ) {
    resetPassword(input: $input) {
      success
    }
  }
`;

export {
  RESET_PASSWORD,
  GET_INITIAL_DATA,
  REMOVE_ACCOUNT_GROUP_USER,
  UPDATE_ACCOUNT_GROUP_USERS,
  UPDATE_CONTACT_PREFERENCES,
  SEND_ACCOUNT_GROUP_USER_INVITE,
};
