import React, { useContext } from 'react'
import Image from '../../../components/ui/image'
import Theme from '../../../context/theme'
import {
  PL_LOGO_TRANSPARENT,
  PL_OWNERS_ACCOUNT_LOGO,
  OWNERS_ACCOUNT_LOGO,
} from '../../../constants'

const ParkLeisureBrandLogo = ({ logoName, width }) => {
  const {
    theme: { spacing },
  } = useContext(Theme)
  const isOwnersAccountLogo = logoName === OWNERS_ACCOUNT_LOGO

  return isOwnersAccountLogo ? (
    <Image
      src={PL_OWNERS_ACCOUNT_LOGO}
      alt="Park Leisure Owners Account logo"
      width={width}
      style={{ marginBottom: spacing(2) }}
    />
  ) : (
    <Image
      src={PL_LOGO_TRANSPARENT}
      alt="Park Leisure logo"
      width={width}
      style={{ marginBottom: spacing(2) }}
    />
  )
}

export default ParkLeisureBrandLogo
