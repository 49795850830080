import { styled } from 'styletron-react'

const SectionHeaderRoot = styled('header', ({ $color, $theme }) => ({
  paddingTop: `${$theme.spacing(1.5)}px`,
  paddingBottom: `${$theme.spacing(1.5)}px`,
  ...$theme.mixins.gutters(),
  ...$theme.styler({ color: $color }),
  marginBottom: `${$theme.spacing(2)}px`,
}))

export default SectionHeaderRoot
