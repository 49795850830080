import React, { useContext, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import AuthContext from '../../context/auth'

import Notification from '../../components/ui/notification'
import Container from '../../components/ui/container'

import PasswordResetForm from '../../components/password-reset-form'

const PasswordReset = () => {
  const auth = useContext(AuthContext)
  const { username, code } = useParams()
  
  const [completed, setCompleted] = useState(false)
  const [successful, setSuccessful] = useState(false)
  const [notification, setNotification] = useState(null)

  /** METHODS */
  const handleResetPassword = async (password) => {
    const status = await auth.resetPassword(
      username,
      code,
      password,
    )

    if (!status.ok) 
      setNotification({ 
        context: 'error', 
        title: 'Error', 
        messages: [status.message] 
      })
    else setSuccessful(true);
    
    setCompleted(true)      
  }

 const handleInvalidForm = () => setNotification({
    context: 'error',
    title: 'Error',
    messages: ['Your new password and confirmation do not match'],
  })

  if (completed  && successful) return <Redirect to="/" />

  return (
    <Container style={{ marginTop: 16 }}>
      <PasswordResetForm 
        isCompleted={completed}
        notificaiton={notification ? <Notification {...notification} /> : null}
        onSubmit={handleResetPassword}
        onInvalid={handleInvalidForm}
      />   
    </Container>
  );
};

export default PasswordReset;
