import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Button from '../ui/button'
import FormGroup from '../ui/formgroup'
import Icon from '../ui/icon'
import { PasswordField } from '../ui/formfield'
import Typography from '../ui/typography'
import Divider from '../ui/divider'

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,30}$/
const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onInvalid: PropTypes.func.isRequired,
  notification: PropTypes.node,
  isCompleted: PropTypes.bool,
};

function PasswordResetForm({ isCompleted, notification, onSubmit, onInvalid }) {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault()
    const isFormValid = PASSWORD_REGEX.test(newPassword) && confirmPassword === newPassword
    
    if (isFormValid) {
      setIsLoading(true)
      onSubmit(newPassword)
    } else onInvalid()
  }  

  useEffect(() => {
    if (isCompleted && isLoading) setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted])

  return (
    <>
      {notification}
      <Typography as="h4">Update your password?</Typography>
      <form onSubmit={e => handleSubmit(e)} autoComplete="off">
        <FormGroup>
          <PasswordField
            autoComplete="off"
            id="password"
            name="password"
            type="password"
            label="New Password"
            labelProps={{ color: 'white' }}
            fullWidth
            onChange={e => setNewPassword(e.target.value)}
            value={newPassword}
            pattern={PASSWORD_REGEX.pattern}
            required
          />
        </FormGroup>

        <FormGroup>
          <PasswordField
            autoComplete="off"
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            label="Confirm New Password"
            labelProps={{ color: 'white' }}
            fullWidth
            onChange={e => setConfirmPassword(e.target.value)}
            pattern={PASSWORD_REGEX.pattern}
            required
            value={confirmPassword}
          />
        </FormGroup>

        <Button
          type="submit"
          color="corporateDark"
          disabled={isLoading}
          endIcon={<Icon name="chevron-right" />}
          fullWidth
          align="center"
        >
          {isLoading ? 'Loading...' : 'Change Password'}
        </Button>

        <Divider style={{ backgroundColor: 'white' }} />

        <ul style={{ textAlign: 'left' }}>
          <li>Passwords must be between 8 and 30 characters.</li>
          <li>Contain at least one digit.</li>
          <li>Contain at least one uppercase character.</li>
          <li>Contain at least one lowercase character.</li>
        </ul>
      </form>
    </>
  )
}

PasswordResetForm.propTypes = propTypes
PasswordResetForm.defaultProps = {
  isCompleted: false,
  notification: undefined
}

export default PasswordResetForm