import { styled } from 'styletron-react';

export const Wrapper = styled('div', () => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const ButtonMenuRoot = styled('div', ({ $show, $isMobile, $theme }) => ({
  zIndex: $theme.zIndex.appBar,
  position: $isMobile ? 'absolute' : 'relative',
  top: 0,
  display: $show ? 'block' : 'none',
  ...($isMobile ? { width: '280px' } : {}),
  // paddingTop: `${$theme.spacing(1)}px`,
  // paddingBottom: `${$theme.spacing(1)}px`,
  backgroundColor: $theme.palette.common.white,
  [$theme.breakpoints.up('md')]: {
    position: 'relative',
    displaY: 'block',
  },
}));

export default ButtonMenuRoot;