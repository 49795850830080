import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'styletron-react'

import Grid from '../ui/grid'
import Typography from '../ui/typography'
import Image from '../ui/image'

import { parkAddress } from '../../helpers'

const componentPropTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  br: PropTypes.bool,
}

const Component = ({ title, value, br, ...otherProps }) => {
  return (
    <>
      <Typography {...otherProps}>
        {`${title}:`}
        &nbsp;
        <Typography as="strong">{value}</Typography>
      </Typography>
      {br ? <br /> : null}
    </>
  )
}

const parkDetailsPropTypes = {
  park: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.object.isRequired,
    logo: PropTypes.shape({
      path: PropTypes.string.isRequired,
      alternateText: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  booking: PropTypes.shape({
    arrivalDate: PropTypes.string.isRequired,
    departureDate: PropTypes.string.isRequired,
    noOfNights: PropTypes.number.isRequired,
    noOfAdults: PropTypes.number.isRequired,
    noOfChildren: PropTypes.number.isRequired,
  }).isRequired,
}

const ParkDetails = ({ park, booking }) => {
  const arrivalDate = new Date(booking.arrivalDate).toLocaleString('en-GB', {
    dateStyle: 'full',
  })

  const departureDate = new Date(booking.departureDate).toLocaleString(
    'en-GB',
    { dateStyle: 'full' },
  )

  const [css] = useStyletron()

  return (
    <Grid>
      <Grid item md={8} className={css({ flex: 1 })}>
        <Typography as="h3" color="corporate" weight="bold" noMargins>
          {`${park.name} Holiday Park`}
        </Typography>

        <Typography color="corporate">{parkAddress(park.address)}</Typography>
        <br />

        <Component title="Arriving" value={arrivalDate} />
        <Component title="Duration" value={`${booking.noOfNights} nights`} />
        <Component title="Departing" value={departureDate} br />

        <Typography as="strong">
          {`${booking.noOfAdults} Adults`}
          {booking.noOfChildren > 0 && `, ${booking.noOfChildren} Children`}
        </Typography>
      </Grid>

      <Grid item md={4} style={{ flex: 0 }}>
        <Image
          src={`//assets.parkholidays.com/assets/${park.logo.path}`}
          alt={park.logo.alternateText || `${park.name} logo`}
          className={css({ maxWidth: 120 })}
        />
      </Grid>
    </Grid>
  )
}

ParkDetails.propTypes = parkDetailsPropTypes
Component.propTypes = componentPropTypes

Component.defaultProps = { br: false }
export default ParkDetails
