import useIsPl2k from '../context/theme/hooks/useIsPl2k'
import {PH_PREFERENCES, PL_PREFERENCES} from '../constants'

const useContactPreferenceOptions = () => {
  const isPl2k = useIsPl2k()
  const contactPreferences = isPl2k ? PL_PREFERENCES : PH_PREFERENCES
  const getPrefNames = (communicationTypeCode) => {
    const prefName = isPl2k
      ? PH_PREFERENCES[PL_PREFERENCES.indexOf(communicationTypeCode)].toLowerCase()
      : communicationTypeCode.toLowerCase()
    return prefName
  }
  return { contactPreferences, getPrefNames }
}

export default useContactPreferenceOptions
