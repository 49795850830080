import React, { useState } from 'react'

import RegisterParkHolidays from './RegisterParkHolidays'
import RegisterParkLeisure from './RegisterParkLeisure'
import SignUp from '../../components/Auth/SignUp'
import SuccessMessage from '../../components/Auth/SuccessMessage'
import Container from '../../components/ui/container'

import useIsPl2k from '../../context/theme/hooks/useIsPl2k'

const Register = () => {
  const [completed, setCompleted] = useState(false)
 const isPl2k = useIsPl2k()

  const handleSignup = registered => {
    setCompleted(registered)
  }

  return (
    <>
      <Container style={{ marginTop: 32 }}>
         {isPl2k ? <RegisterParkLeisure/> : <RegisterParkHolidays/>}
        {completed ? <SuccessMessage /> : null}
      </Container>
      {!completed ? <SignUp handleSignup={handleSignup} /> : null}
    </>
  )
}

export default Register
