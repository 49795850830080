/* eslint-disable import/prefer-default-export */
import { styled } from 'styletron-react';

const Nav = styled('nav', {
  display: 'flex',
  flexDirection: 'column',
});

/**
 * Nav Link Component
 *
 * @returns
 */
export const NavLink = styled('a', ({ $active, $theme }) => {
  const activeStyles = $theme.styler({ color: 'corporate' });

  return {
    ...$theme.typography.base,
    display: 'block',
    padding: `${$theme.spacing(2)}px ${$theme.spacing(3)}px`,
    marginTop: -1,
    textDecoration: 'none',
    borderTop: `1px solid ${$theme.palette.common.gray50}`,
    borderBottom: `1px solid ${$theme.palette.common.gray50}`,
    fontSize: 18,
    fontWeight: 500,
    cursor: 'pointer',
    ...($active ? activeStyles : { color: $theme.palette.text }),
    ':first-child': { marginTop: 0 },
    ':hover': activeStyles,
    ':focus': activeStyles,
  };
});

export default Nav;
