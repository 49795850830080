import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'styletron-react'

import Accordian, { AccordianItem } from '../ui/accordian'
import Card from '../ui/card'
import Image from '../ui/image'

import Theme from '../../context/theme'

import ContentRoot from './styled'

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

/**
 * Holiday Extras Card Content
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
export const HolidayExtrasContent = ({ children, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  return (
    <ContentRoot {...otherProps} $theme={theme}>
      {children}
    </ContentRoot>
  )
}

/**
 * Holiday Extras Card Hidden Content
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
export const HolidayExtrasHidden = ({ children, ...otherProps }) => {
  const { theme } = React.useContext(Theme)
  const [isOpen, setIsOpen] = React.useState(false)
  const [css] = useStyletron()

  return (
    <Accordian {...otherProps} onItemToggle={open => setIsOpen(open)}>
      <AccordianItem
        title={!isOpen ? 'more' : 'less'}
        contentProps={{ style: { order: -1, paddingTop: theme.spacing(3) } }}
        className={css({
          borderBottomLeftRadius: theme.shape.medium,
          borderBottomRightRadius: theme.shape.medium,
        })}
      >
        {/* <div style={{ padding: 16 }}> */}
        {children}
        {/* </div> */}
      </AccordianItem>
    </Accordian>
  )
}

/**
 * Holiday Extras Card Image
 *
 * @param {*} {  }
 * @returns
 */
export const HolidayExtrasImage = ({ image }) => {
  const { theme } = React.useContext(Theme)
  const [css] = useStyletron()
  const imageSrc = `https://park-holidays.s3.eu-west-2.amazonaws.com/assets/checkout/illustration-${image}.png` // images[image] || '';

  const styles = {
    wrapper: {
      zIndex: 1,
      height: 114,
      backgroundColor: theme.palette.holidays,
      borderTopLeftRadius: theme.shape.medium,
      borderTopRightRadius: theme.shape.medium,
    },
    img: { display: 'block', margin: '0 auto', width: 128, height: 128 },
  }

  return (
    <div className={css(styles.wrapper)}>
      <Image src={imageSrc} alt={image} className={css(styles.img)} />
    </div>
  )
}

/**
 *  Holiday Extras Card
 *
 * @param {*} { children }
 * @returns
 */
const HolidayExtrasCard = ({ children }) => {
  const [css] = useStyletron()
  return (
    <Card elevation={3} className={css({ overflow: 'visible' })}>
      {children}
    </Card>
  )
}

HolidayExtrasCard.propTypes = { children: propTypes.children.isRequired }
HolidayExtrasContent.propTypes = { children: propTypes.children.isRequired }
HolidayExtrasHidden.propTypes = { children: propTypes.children.isRequired }
export default HolidayExtrasCard
