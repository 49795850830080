import { styled } from '@parkholidays/legacy_components'

const ContentRoot = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  ...$theme.styler({ color: 'corporate', swap: false, outlined: false }),
}))

export const ContentContainer = styled('div', ({ $theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  textAlign: 'center',
  // ...$theme.styler({ color: 'corporate', swap: false, outlined: false }),
  paddingTop: `${$theme.spacing(4)}px`,
  paddingBottom: `${$theme.spacing(4)}px`,
  ...$theme.mixins.gutters(),
}))

export default ContentRoot
