import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'styletron-react'

import FormGroup from '../../components/ui/formgroup'
import FormField from '../../components/ui/formfield'
import Button from '../../components/ui/button'
import Icon from '../../components/ui/icon'
import Typography from '../../components/ui/typography'
import Anchor from '../../components/ui/anchor'
import Notification from '../../components/Notification/Notification'

import Theme from '../../context/theme'
import AuthContext from '../../context/auth'

import ContentRoot, { ContentContainer } from './style'

const styles = {
  content: {
    width: '100%',
    maxWidth: '320px',
  },
  list: { paddingLeft: '16px', textAlign: 'left' },
}

const successMessagePropTypes = {
  email: PropTypes.string.isRequired,
  onResendClick: PropTypes.func.isRequired,
}

const SuccessMessage = ({ email, onResendClick }) => {
  const [css] = useStyletron()
  return (
    <div className={css({ ...styles.content, maxWidth: '450px' })}>
      <Typography as="h3">Success!</Typography>
      <Typography as="p">
        {`An email has been sent to ${email}.`}
        <br />
        Please click the link within the email and complete the form to reset
        your password.
      </Typography>
      <Anchor
        href="/login"
        color="inherit"
        className={css({ textDecoration: 'underline' })}
      >
        Return to Login
      </Anchor>
      &nbsp;&nbsp;
      <Anchor
        native
        onClick={onResendClick}
        color="inherit"
        className={css({ textDecoration: 'underline', cursor: 'pointer' })}
      >
        Resend email
      </Anchor>
    </div>
  )
}

const ForgotPassword = () => {
  const { theme } = React.useContext(Theme)
  const auth = React.useContext(AuthContext)
  /** STATE */
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)

  const [notification, setNotification] = useState({
    title: '',
    messages: [],
  })

  const [css] = useStyletron()

  /** CUSTOM METHODS */
  const handleChange = e => setEmail(e.target.value)

  const requestPasswordReset = async e => {
    e.preventDefault()
    setLoading(true)

    const status = await auth.forgotPassword(email)
    if (!status.ok)
      setNotification({ title: 'Error', messages: [status.message] })

    setCompleted(status.ok)
    setLoading(false)
  }

  return (
    <ContentRoot $theme={theme}>
      <ContentContainer $theme={theme}>
        <Notification
          title={notification.title}
          messages={notification.messages}
          color="error"
          className={css({ marginBottom: 16 })}
        />

        {completed ? (
          <SuccessMessage
            email={email}
            onResendClick={() => setCompleted(false)}
          />
        ) : null}
        {!completed ? (
          <>
            <Typography as="h2">Forgot your password?</Typography>
            <Typography as="p" noMargins>
              Follow these simple steps to reset your password
            </Typography>

            <ul className={css(styles.list)}>
              <li>Enter you email address below and click the link.</li>
              <li>A email will be sent with a password reset link.</li>
              <li>Enter your new Password.</li>
            </ul>

            <form
              className={css(styles.content)}
              onSubmit={e => requestPasswordReset(e)}
            >
              <FormGroup>
                <FormField
                  type="email"
                  id="email"
                  name="email"
                  label="Your Email Address"
                  labelProps={{ color: 'inherit' }}
                  placeholder="i.e. Joebloggs@gmail.com"
                  fullWidth
                  value={email}
                  onChange={e => handleChange(e)}
                  required
                />
              </FormGroup>

              <Button
                color="corporateDark"
                fullWidth
                align="center"
                endIcon={<Icon name="chevron-right" />}
                // className={css({ marginBottom: `${theme.spacing(2)}px` })}
                style={{ marginBottom: `${theme.spacing(2)}px` }} // Set back to style property as for some reason class not working.
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Send Password Reset Link'}
              </Button>

              <Anchor
                href="/login"
                color="inherit"
                className={css({ textDecoration: 'underline' })}
              >
                Cancel
              </Anchor>
            </form>
          </>
        ) : null}
      </ContentContainer>
    </ContentRoot>
  )
}

SuccessMessage.propTypes = successMessagePropTypes
export default ForgotPassword
