import React from 'react'
import PropTypes from 'prop-types'
import { useApolloClient } from '@apollo/react-hooks'

import CardPayment from '@parkholidays/plutus'

import Container from '../../components/ui/container'
import Card, { CardHeader, CardContent } from '../../components/ui/card'
import Typography from '../../components/ui/typography'

import Divider from '../../components/ui/divider'
import Grid from '../../components/ui/grid'
import Loading from '../../components/ui/loading'
import Notification from '../../components/ui/notification'

import Theme from '../../context/theme'

import CancellationProtectionCard from '../../components/HolidayExtras/CancellationProtectionCard'
import DiningOptionsCard from '../../components/HolidayExtras/DiningOptionsCard'
import PetsCard from '../../components/HolidayExtras/PetsCard'
import EarlyCheckInCard from '../../components/HolidayExtras/EarlyCheckInCard'
import CotsHighChairsCard from '../../components/HolidayExtras/CotsHighChairsCard'

import BookingDetailsCard from '../../components/UpdateBooking/AddExtras/BookingDetailsCard'

import { GET_BOOKING, SET_EXTRAS } from './graphql'
import { HolidayExtrasBackground } from './style'

/**
 *  Page Header Component
 *
 */
const PageHeader = () => {
  const { theme } = React.useContext(Theme)
  return (
    <Card
      color="white"
      style={{ marginBottom: theme.spacing(2) }}
      rounded={false}
    >
      <CardContent style={{ paddingTop: 16, paddingBottom: 12 }}>
        <Typography as="h2" weight="bold" noMargins>
          Holiday Extras
        </Typography>
        <Divider />

        <Typography as="p" noMargins>
          Choose from our list of optional extras tailored to make your holiday
          more relaxing
        </Typography>
      </CardContent>
    </Card>
  )
}

const holidayExtrasPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  booking: PropTypes.any,
  selectedExtras: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

const holidayExtrasDefaultProps = { booking: null }

/**
 * Holiday Extras Options
 *
 * @returns
 */
const HolidayExtras = ({ booking, loading, selectedExtras, onChange }) => {
  /** EXTRAS */
  const bookableExtras = (booking ? booking.extras || [] : []).filter(
    extra => extra.bookable,
  )

  const earlyCheckIn = bookableExtras.find(extra => extra.code === 'EARLY')
  const diningExtras = bookableExtras.filter(extra => extra.diningOptions)
  const cancellationProtectionPlan = bookableExtras.find(
    extra => extra.code.substring(0, 3) === 'CAN',
  )

  const petExtra = bookableExtras.find(
    extra => extra.code.substring(0, 3) === 'PET',
  )

  let cotHighChairExtras = booking
    ? booking.extras.filter(extra => ['HIGHC', 'COT'].includes(extra.code))
    : []

  if (cotHighChairExtras.filter(extra => extra.bookable).length === 0)
    cotHighChairExtras = []

  /** METHODS */
  const handleAddRemoveCancellationProtection = () => {
    const newSelectedExtras = [...selectedExtras]
    const index = newSelectedExtras.findIndex(
      extra => extra.code === cancellationProtectionPlan.code,
    )
    if (index > -1) newSelectedExtras.splice(index, 1)
    else {
      newSelectedExtras.push({
        code: cancellationProtectionPlan.code,
        quantity: 1,
        unitPrice: cancellationProtectionPlan.unitPrice,
        description: cancellationProtectionPlan.description,
        bookable: true, // cancellationProtectionPlan.bookable,
      })
    }

    onChange(newSelectedExtras)
  }

  const handleAddRemoveEarlyCheckIn = () => {
    const newSelectedExtras = [...selectedExtras]
    const index = newSelectedExtras.findIndex(
      extra => extra.code === earlyCheckIn.code,
    )
    if (index > -1) newSelectedExtras.splice(index, 1)
    else {
      newSelectedExtras.push({
        code: earlyCheckIn.code,
        quantity: 1,
        unitPrice: earlyCheckIn.unitPrice,
        description: earlyCheckIn.description,
        bookable: true, // earlyCheckIn.bookable,
      })
    }

    onChange(newSelectedExtras)
  }

  const handleAddRemovePets = quantity => {
    const newSelectedExtras = [...selectedExtras]
    const index = newSelectedExtras.findIndex(
      extra => extra.code === petExtra.code,
    )

    if (index > -1) newSelectedExtras.splice(index, 1)
    if (quantity > 0) {
      newSelectedExtras.push({
        code: petExtra.code,
        quantity,
        unitPrice: petExtra.unitPrice,
        description: petExtra.description,
        bookable: true, // petExtra.bookable,
      })
    }

    onChange(newSelectedExtras)
  }

  const handleAddRemoveCotHighChairOptions = newExtras => {
    const newSelectedExtras = [
      ...selectedExtras.filter(
        extra =>
          cotHighChairExtras.findIndex(
            chcExtra => chcExtra.code === extra.code,
          ) === -1,
      ),
      ...newExtras,
    ]

    onChange(newSelectedExtras)
  }

  const handleAddRemoveDiningOptions = newExtras => {
    const newSelectedExtras = [
      ...selectedExtras.filter(
        extra =>
          diningExtras.findIndex(
            diningExtra => diningExtra.code === extra.code,
          ) === -1,
      ),
      ...newExtras,
    ]

    onChange(newSelectedExtras)
  }

  return (
    <Grid>
      {loading ? (
        <Grid item xs={12}>
          <Loading
            text="Loading Holiday Extras.."
            style={{ position: 'relative', padding: 16, border: 'none' }}
          />
        </Grid>
      ) : (
        <>
          {bookableExtras.length === 0 ? (
            <Grid item xs={12}>
              <Typography as="p" noMargins>
                There are currently no Holiday Extras available for you to add
                to your booking.
              </Typography>
            </Grid>
          ) : null}

          {cancellationProtectionPlan ? (
            <Grid item xs={12}>
              <CancellationProtectionCard
                extra={cancellationProtectionPlan}
                included={
                  selectedExtras.findIndex(
                    extra => extra.code === cancellationProtectionPlan.code,
                  ) > -1
                }
                onChange={handleAddRemoveCancellationProtection}
              />
            </Grid>
          ) : null}

          {petExtra ? (
            <Grid item xs={12}>
              <PetsCard
                extra={petExtra}
                includedQty={
                  (
                    selectedExtras.find(
                      extra => extra.code === petExtra.code,
                    ) || {}
                  ).quantity || 0
                }
                onSubmit={handleAddRemovePets}
              />
            </Grid>
          ) : null}

          {cotHighChairExtras.length > 0 ? (
            <Grid item xs={12}>
              <CotsHighChairsCard
                options={cotHighChairExtras}
                onSubmit={handleAddRemoveCotHighChairOptions}
                includedExtras={selectedExtras.filter(extra =>
                  ['HIGHC', 'COT'].includes(extra.code),
                )}
              />
            </Grid>
          ) : null}

          {diningExtras.length > 0 ? (
            <Grid item xs={12}>
              <DiningOptionsCard
                options={diningExtras}
                includedExtras={selectedExtras.filter(extra =>
                  diningExtras.find(e => e.code === extra.code),
                )}
                noOfAdults={booking.noOfAdults}
                noOfChildren={booking.noOfChildren}
                noOfNights={booking.noOfNights}
                onSubmit={handleAddRemoveDiningOptions}
              />
            </Grid>
          ) : null}

          {earlyCheckIn ? (
            <Grid item xs={12}>
              <EarlyCheckInCard
                extra={earlyCheckIn}
                included={
                  selectedExtras.findIndex(
                    extra => extra.code === earlyCheckIn.code,
                  ) > -1
                }
                onChange={handleAddRemoveEarlyCheckIn}
              />
            </Grid>
          ) : null}
        </>
      )}
    </Grid>
  )
}

const initialState = {
  booking: null,
  selectedExtras: [],
  loading: true,
  processing: false,
}

const addExtrasPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
}

/**
 * Add Extras Page
 *
 * @returns
 */
const AddExtras = ({ match }) => {
  const { theme } = React.useContext(Theme)
  const [state, setState] = React.useState(initialState)
  const [showPayment, setShowPayment] = React.useState(false)
  const [notification, setNotification] = React.useState(null)

  const { bookingNo } = match.params
  const client = useApolloClient()
  const selectedExtrasPrice = state.selectedExtras.reduce(
    (prev, current) => prev + current.unitPrice * current.quantity,
    0,
  )

  const getInitialData = async () => {
    try {
      const { data } = await client.query({
        query: GET_BOOKING,
        variables: { id: bookingNo },
      })

      setState({
        ...state,
        booking: data.booking || null,
        selectedExtras: [],
        loading: false,
      })
    } catch (err) {
      setNotification({
        color: 'error',
        title: 'Error',
        messages: err.graphQLErrors.map(e => e.message),
      })
    }
  }

  const handleRemoveExtra = code => {
    const newExtras = [...state.selectedExtras]
    const index = newExtras.findIndex(extra => extra.code === code)
    if (index > -1) newExtras.splice(index, 1)
    setState({ ...state, selectedExtras: newExtras })
  }

  const handleUpdateBooking = () => {
    const apiExtras = [...state.selectedExtras].map(apiExtra => ({
      code: apiExtra.code,
      quantity: apiExtra.quantity,
    }))

    setShowPayment(false)
    setState({
      ...state,
      processing: true,
    })

    client
      .mutate({
        mutation: SET_EXTRAS,
        variables: {
          input: {
            bookingNo: state.booking.id,
            extras: apiExtras,
          },
        },
      })
      .catch(err => {
        setState({
          ...state,
          processing: false,
        })

        setNotification({
          color: 'error',
          title: 'Error',
          messages: err.graphQLErrors.map(e => e.message),
        })
      })
      .then(response => response.data.updateExtras)
      .then(() => {
        // Booking updated with new extras so reset state and reload booking
        getInitialData()
        setState(initialState)
        setNotification({
          color: 'success',
          title: 'Success',
          messages: [
            'The extras have been added successfully to your booking.',
          ],
        })
      })
  }

  React.useEffect(() => {
    getInitialData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  React.useEffect(() => {
    if (state.selectedExtras.length === 0 && showPayment) setShowPayment(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedExtras])

  return (
    <HolidayExtrasBackground $theme={theme}>
      <Container style={{ marginTop: theme.spacing(2) }}>
        <PageHeader />
        {state.processing ? (
          <Loading
            text="Updating your Booking.."
            color="white"
            style={{
              zIndex: theme.zIndex.modal,
              padding: theme.spacing(2),
              border: 'none',
              backgroundColor: 'rgb(0,0,0, 0.5)',
            }}
          />
        ) : null}

        {notification ? (
          <Notification
            {...notification}
            style={{ marginBottom: theme.spacing(2) }}
          />
        ) : null}

        <Grid alignItems={state.loading ? 'center' : null}>
          <Grid item xs={12} md={6} lg={7} xl={8}>
            <HolidayExtras
              booking={state.booking}
              loading={state.loading}
              selectedExtras={state.selectedExtras}
              onChange={extras =>
                setState({ ...state, selectedExtras: extras })
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <BookingDetailsCard
              booking={state.booking}
              currentExtras={
                state.booking
                  ? state.booking.extras.filter(extra => extra.quantity > 0)
                  : []
              }
              selectedExtras={state.selectedExtras}
              loading={state.loading}
              paymentShown={showPayment}
              onSubmitClicked={payNow => {
                setShowPayment(payNow ? !showPayment : false)
                if (!payNow) handleUpdateBooking()
              }}
              onRemoveExtra={handleRemoveExtra}
            />

            {showPayment ? (
              <Card color="white" style={{ marginTop: theme.spacing(2) }}>
                <CardHeader style={{ paddingTop: theme.spacing(2) }}>
                  <Typography as="h3" transform="uppercase" noMargins>
                    Make Payment
                  </Typography>
                </CardHeader>
                <CardContent>
                  <CardPayment
                    amount={selectedExtrasPrice}
                    apolloClient={client}
                    minAmount={selectedExtrasPrice || 20}
                    parkId={parseInt(state.booking.park.id)}
                    reference={bookingNo}
                    onSuccess={handleUpdateBooking}
                  />
                </CardContent>
              </Card>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </HolidayExtrasBackground>
  )
}

HolidayExtras.propTypes = holidayExtrasPropTypes
HolidayExtras.defaultProps = holidayExtrasDefaultProps
AddExtras.propTypes = addExtrasPropTypes
export default AddExtras
