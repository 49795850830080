import React, { useContext } from 'react'
import { useStyletron } from 'styletron-react'

import AccountsContext from '../../context/accounts'
import Theme from '../../context/theme'
import Container from '../ui/container'
import Loading from '../ui/loading'

import OwnerAccountCard from './OwnerAccountCard'
import SectionHeader from './SectionHeader'
import useApplyColor from '../../context/theme/hooks/useApplyColor'

const Ownership = () => {
  const { theme } = useContext(Theme)
  const accounts = useContext(AccountsContext)
  const applyColor = useApplyColor()
  const [css] = useStyletron()

  React.useEffect(() => {
    const advertContainer = document.getElementById('owners_advert_banner')

    if (advertContainer) {
      const script = document.createElement('script')
      script.src =
        '//cdn.bannerflow.com/bf-placements/5e749240bf82580a0003f02f?responsive=on&targeturl='
      script.async = true

      advertContainer.appendChild(script)
    }
  }, [])

  return (
    <>
      <SectionHeader color={applyColor}>My Account</SectionHeader>
      <Container
        id="owners_advert_banner"
        className={css({ marginTop: `-${theme.spacing(2)}px` })}
      >
        <div id="placeholder_div" />
      </Container>

      <Container className={css({ marginTop: `${theme.spacing(2)}px` })}>
        {accounts.isLoading ? (
          <div className={css({ position: 'relative', height: '206px' })}>
            <Loading
              text="Loading, ownership accounts"
              className={css({ border: 0 })}
            />
          </div>
        ) : null}

        {accounts.ownership.map(account => (
          <OwnerAccountCard
            key={account.customerNo}
            account={account}
            className={css({ marginBottom: theme.spacing(2) })}
          />
        ))}
      </Container>
    </>
  )
}

export default Ownership
