import React from 'react';

import Appbar, { AppbarBrand } from '../../components/ui/appbar';
import BrandLogo from '../../components/ui/brandLogo';
import Spacer from '../../components/ui/spacer';

import { CORPORATE, OWNERS_ACCOUNT_LOGO } from '../../constants'
/**
 * Application Footer Component
 *
 * @returns
 */
const AppFooter = () => {

  return (
  <Appbar
    $as="footer"
    role={null}
    color={CORPORATE}
    $style={{ position: 'relative', marginBottom: 0 }}
  >
    <AppbarBrand to="/">
      <BrandLogo logoName={OWNERS_ACCOUNT_LOGO} width='150'/>
    </AppbarBrand>

    <Spacer />
  </Appbar>
  )
};

export default AppFooter;
