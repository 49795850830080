import React from 'react'

import Container from '../ui/container'
import Typography from '../ui/typography'
import useIsPl2k from '../../context/theme/hooks/useIsPl2k'
import { PL_HELP, PH_HELP } from '../../config'

const SuccessMessage = () => {
  const isPl2k = useIsPl2k()
  const emailContact =  `mailto:${isPl2k ? PL_HELP : PH_HELP}`;

  return <Container style={{ backgroundColor: '#EFEFEF', padding: '32px 20px' }}>
    <Typography as="h3">Confirm your email address</Typography>
    <Typography as="p">
      Thank you for registering with us, in order to complete your account
      setup, we have sent an email with a confirmation link to your email
      address.
    </Typography>
    <Typography as="p">
      In order to complete the sign-up process, please click the confirmation
      link.
    </Typography>
    <Typography as="p">
      If you do not receive a confirmation email, please check your spam folder.
      Also, please verify that you entered a valid email address in the sign-up
      process.
    </Typography>
    <Typography as="p">
        {' If you need assistance, please '}
        <a href={ emailContact } color="inherit" style={{ textDecoration: 'underline' }}>contact us</a>
    </Typography>
    {/* <LinkButton to="/login">Login</LinkButton> */}
  </Container>
}

export default SuccessMessage
