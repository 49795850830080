/** @flow */
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

/** Styled Components */
import Button from '../button'
import Icon from '../icon'

const menuButtonPropTypes = {
  color: PropTypes.string,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

const menuButtonDefaultProps = { color: 'inherit' }

/**
 * Button Navigation Item
 *
 * @returns
 */
const MenuButton = ({ to, text, color, ...otherProps }) => (
  <Button
    $as={otherProps.disabled ? null : Link}
    fullWidth
    variant="outlined"
    color={color}
    endIcon={<Icon name="chevron-right" />}
    to={otherProps.disabled ? null : to}
    {...otherProps}
  >
    {text}
  </Button>
)

MenuButton.propTypes = menuButtonPropTypes
MenuButton.defaultProps = menuButtonDefaultProps

export default MenuButton
