import {datadogLogs} from '@datadog/browser-logs'

const initDatadogConsoleLogger = () => {
  datadogLogs.init({
    clientToken: 'pubd140329fb40780b911662482247a94d4',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: window.location.hostname
  });
}

const logger = datadogLogs.logger

export {initDatadogConsoleLogger, logger as datadogLogger}
