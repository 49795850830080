import { gql } from 'apollo-boost'

export const GET_OWNER_ACCOUNTS = gql`
  query UserOwnerDetails {
    userOwnerDetails {
      customerNo
      dealNo
      parkCode
      parkId
      parkName
      plotNo
      groupPermission
      isUserPrimary
    }
  }
`

export const GET_HOLIDAY_ACCOUNTS = gql`
  query UserHolidayDetails {
    userHolidayDetails {
      bookingNo
      parkCode
      parkId
      arrivalDate
      dateAdded
      groupPermission
      isUserPrimary
    }
  }
`
