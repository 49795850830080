import React, { useContext } from 'react'

import Theme from '../../context/theme';
import Typography from '../../components/ui/typography';

import PrimaryButton from '@parkholidays/legacy_components/dist/button/PrimaryButton'
import Text from '@parkholidays/legacy_components/dist/text/Text'

import ContentRoot, { ContentContainer } from './style'

const Migration = () => {

  const { theme } = useContext(Theme)

  return (
    <ContentRoot $theme={theme} style={{ backgroundColor: theme.palette.corporate }}>
      <ContentContainer $theme={theme} style={{
        maxWidth: 400,
      }}>
        <Typography as="h1" weight="bold" align="center">
          Migration to Park Holidays
        </Typography>
        <Typography as="p" noMargins align="center">
          Amble Links is becoming a Park Holidays park, and your account should now be migrated to My Park Holidays.
        </Typography>
        <Typography as="p" noMargins align="center" style={{ paddingBottom: 16 }}>
          All you need to do is visit https://my.parkholidays.com and log in using the same email address and password as you currently use here. The migration will be processed automatically and you'll be logged straight in.
        </Typography>
        <PrimaryButton
          href='https://my.parkholidays.com/'
          openInNewWindow
          $style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#08375f'
          }}
        >
          <Text color="white">Visit My Park Holidays</Text>
        </PrimaryButton>
      </ContentContainer>
    </ContentRoot>
  );

};

export default Migration;
