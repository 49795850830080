import React from 'react'

import Container from '../../components/ui/container'
import Divider from '../../components/ui/divider'
import Grid from '../../components/ui/grid'
import Typography from '../../components/ui/typography'
import Ownership from '../../components/Home/Ownership'
import LinkCard from '../../components/Home/LinkCard'

import useIsOwner from '../../hooks/useIsOwner'

const Home = () => {
  const {isOwner, isParkHolidaysOwner} = useIsOwner()

  return (
    <>
   { isOwner ? <Ownership /> : null}
      <Container>
        <Divider />
        <Grid>
          <Grid item xs={12} md={6}>
            <Typography as="h2" weight="bold">
              Account Details
            </Typography>

            <Typography as="p">
              Change your password and amend your marketing preferences here.
            </Typography>

            <LinkCard to="/profile">View and Edit Account Details</LinkCard>
          </Grid>

          {isParkHolidaysOwner ? (
            <Grid item xs={12} md={6}>
              <Typography as="h2" weight="bold">
                Owner Loyalty Card
              </Typography>

              <Typography as="p">
                Manage your Owner Loyalty Card online here.
              </Typography>

              <LinkCard to="https://touchtopup.net/login">
                Manage Loyalty Card
              </LinkCard>
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </>
  )
}

export default Home
