import PropTypes from 'prop-types'
import React from 'react'

import Button from '../../ui/button'
import Container from '../../ui/container'
import FormGroup from '../../ui/formgroup'
import Grid from '../../ui/grid'
import Select from '../../ui/select'
import Typography from '../../ui/typography'

const MaintenanceJobRequest = ({
  handlePayment,
  handleChange,
  maintenanceJobs,
  newJob,
}) => {
  const { jobType, permissionToEnter } = newJob

  const getFormattedDescription = (description, price) =>
    `${description} --- ${price.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
    })}`
  const sortedJobs = maintenanceJobs.sort()

  React.useEffect(() => {
    const job = maintenanceJobs.find(job => job.code === jobType)
    handleChange({ target: { name: 'jobPrice', value: job ? job.price : 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobType])

  return (
    <Container fluid style={{ paddingTop: 16, paddingBottom: 8 }}>
      <Typography
        as="h2"
        color="corporate"
        weight="bold"
        style={{ marginBottom: 8 }}
      >
        New Request
      </Typography>
      <form onSubmit={handlePayment}>
        <Grid alignItems="center">
          <Grid item xs={12} md="auto">
            <FormGroup style={{ marginBottom: 0 }}>
              <Select
                id="jobType"
                name="jobType"
                label="Job Type"
                value={jobType}
                placeholder="Please select"
                onChange={e => handleChange(e)}
                required
                fullWidth
                options={sortedJobs.map(({ code, description, price }) => ({
                  label: getFormattedDescription(description, price),
                  value: code,
                }))}
              />
            </FormGroup>

            <Typography
              as="p"
              style={{ marginTop: 8 }}
            >
              * Prices may vary depending on type of service and park.
            </Typography>

          </Grid>

          <Grid item xs={12} md="auto">
            <FormGroup style={{ marginBottom: 0 }}>
              <Select
                id="permissionToEnter"
                name="permissionToEnter"
                label="Permission to enter caravan"
                value={permissionToEnter}
                placeholder="Please select"
                onChange={e => handleChange(e)}
                required
                fullWidth
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
              />
            </FormGroup>
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            lg={4}
            xl={3}
            style={{ alignSelf: 'flex-end' }}
          >
            <Button
              type="submit"
              color="corporate"
              fullWidth
              disabled={!jobType}
            >
              Request Service
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

export default MaintenanceJobRequest

MaintenanceJobRequest.propTypes = {
  handlePayment: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  maintenanceJobs: PropTypes.arrayOf(PropTypes.object).isRequired,
  newJob: PropTypes.shape({
    jobType: PropTypes.string,
    permissionToEnter: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }).isRequired,
}
