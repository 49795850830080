import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

/** Components */
import Theme from '../context/theme'
import ContentRoot from './style'

import AppHeader from './header'
import AppFooter from './footer'
import SideMenu from './side-menu'

/**
 * Page Layout component
 *
 * @param {*} { children, noHeader, noFooter }
 * @returns
 */
const Layout = ({ children, isLoggedIn, location, routes }) => {
  const { theme } = React.useContext(Theme)
  const [sideMenuOpen, setSideMenuOpen] = React.useState(false)
  const [showLayout, setShowLayout] = React.useState(false)

  React.useEffect(() => {
    /**
     * URL AND ROUTE COMPARISON
     * Uses regEx to get REF number from url (Booking/Customer) then gets
     * Ref placeholder from route path (:bookingRef/:customerNo). Replaces the placeholder with ref number
     * and then compares both urls for a match. If matches then checks prop hideLayout for route to
     * determine if layout should be hidden.
     */
    const hideLayout =
      routes.findIndex(route => {
        const ref = location.pathname.match(/(\w{3}\d{1,})/g)
        const placeholder = route.path.match(/:\w{1,}/g)
        const isMatch =
          ref && placeholder
            ? route.path.replace(placeholder, ref) === location.pathname
            : route.path === location.pathname

        return isMatch && route.hideLayout
      }) > -1

    setShowLayout(!hideLayout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <>
      {isLoggedIn && showLayout ? (
        <SideMenu open={sideMenuOpen} onClose={() => setSideMenuOpen(false)} />
      ) : null}

      {showLayout ? (
        <AppHeader
          sideMenuOpen={sideMenuOpen}
          onMenuButtonClick={() => setSideMenuOpen(!sideMenuOpen)}
          noMenu={!isLoggedIn}
        />
      ) : null}

      {!showLayout ? (
        children
      ) : (
        <ContentRoot $theme={theme}>{children}</ContentRoot>
      )}

      {showLayout ? <AppFooter /> : null}
    </>
  )
}

/** Component Property Types */
Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    state: PropTypes.object,
    key: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  routes: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool,
}

Layout.defaultProps = { isLoggedIn: false }

export default React.memo(withRouter(Layout))
