import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import AuthContext from '../../context/auth'
import Notification from '../ui/notification'
import PasswordRules from './PasswordRules'

import Theme from '../../context/theme'
import Button, { IconButton } from '../ui/button'
import Checkbox from '../ui/checkbox'
import Container from '../ui/container'
import FormField from '../ui/formfield'
import FormLabel from '../ui/formlabel'
import Grid from '../ui/grid'
import Icon from '../ui/icon'

import { PL_PRIVACY_POLICY, PL_TNC} from '../../config'

const initialUser = {
  password: {
    show: false,
    value: '',
  },
  confirmPassword: {
    show: false,
    value: '',
  },
  email: '',
}

const SignUp = ({ handleSignup }) => {
  const { theme: { isPl2k } } = useContext(Theme)
  const auth = React.useContext(AuthContext)
  const [user, setUser] = React.useState(initialUser)
  const [loading, setLoading] = React.useState(false)
  const [notification, setNotification] = React.useState({
    title: '',
    messages: [],
    context: 'error',
    pale: false,
  })

  const handleChange = e => {
    const newUser = { ...user }

    if (['password', 'confirmPassword'].includes(e.target.name)) {
      newUser[e.target.name].value = e.target.value
    } else {
      newUser[e.target.name] = e.target.value
    }

    setUser(newUser)
  }

  const handleClick = element => {
    const newUser = { ...user }
    newUser[element].show = !user[element].show
    setUser(newUser)
  }

  const isFormValid = () => {
    const { password, confirmPassword } = user
    const isValid = confirmPassword.value === password.value

    if (!isValid) {
      setNotification({
        title: 'Error',
        messages: ['Your new password and confirmation do not match'],
        context: 'error',
        pale: true,
      })
    }

    return isValid
  }

  const createAccount = async e => {
    e.preventDefault()
    if (!isFormValid()) return false

    setLoading(true)
    const status = await auth.signUp(user.email.toLowerCase(), user.password.value)
    if (!status.ok)
      setNotification({ title: 'Error', messages: [status.message] })
    handleSignup(status.ok)
    setLoading(false)
  }

  const { email, password, confirmPassword } = user

  return (
    <Container style={{ backgroundColor: '#EFEFEF', padding: '32px 20px' }}>
      <Notification {...notification} />
      <form onSubmit={e => createAccount(e)} autoComplete="off">
        <Grid style={{ marginBottom: 16 }}>
          <Grid item xs={12} md={5} lg={4} style={{ justifyContent: 'center' }}>
            <FormLabel htmlFor="email">Email Address</FormLabel>
          </Grid>

          <Grid item xs={12} md={7} lg={6}>
            <FormField
              id="email"
              type="email"
              name="email"
              label="Email"
              labelProps={{ srOnly: true }}
              value={email}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={5} lg={4} style={{ justifyContent: 'center' }}>
            <FormLabel htmlFor="password">Password</FormLabel>
          </Grid>

          <Grid item xs={12} md={7} lg={6}>
            <FormField
              autoComplete="off"
              id="password"
              type={password.show ? 'text' : 'password'}
              name="password"
              label="password"
              labelProps={{ srOnly: true }}
              value={password.value}
              onChange={handleChange}
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,30}$"
              required
              fullWidth
              afterEnhancer={
                <IconButton
                  type="button"
                  color="white"
                  tabIndex="-1"
                  onClick={() => handleClick('password')}
                  style={{ borderColor: 'rgb(217, 217, 217)', borderLeft: 0 }}
                >
                  <Icon name={password.show ? 'eye-slash' : 'eye'} />
                </IconButton>
              }
            />
          </Grid>

          <Grid item xs={12} md={5} lg={4} style={{ justifyContent: 'center' }}>
            <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
          </Grid>

          <Grid item xs={12} md={7} lg={6}>
            <FormField
              autoComplete="off"
              id="confirmPassword"
              type={confirmPassword.show ? 'text' : 'password'}
              name="confirmPassword"
              label="confirm password"
              labelProps={{ srOnly: true }}
              value={confirmPassword.value}
              onChange={handleChange}
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,30}$"
              required
              fullWidth
              afterEnhancer={
                <IconButton
                  type="button"
                  color="white"
                  tabIndex="-1"
                  onClick={() => handleClick('confirmPassword')}
                  style={{ borderColor: 'rgb(217, 217, 217)', borderLeft: 0 }}
                >
                  <Icon name={confirmPassword.show ? 'eye-slash' : 'eye'} />
                </IconButton>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <PasswordRules />
          </Grid>

          <Grid item xs={12}>
            <FormLabel control={<Checkbox name="terms" required />}>
              {'I have read and agree to your '}
              { isPl2k ? (
                <Link
                  to={{ pathname: PL_PRIVACY_POLICY }}
                  target="_blank"
                  style={{ textDecoration: 'underline' }}
                >
                  privacy policy
                </Link>
              ) : (
                <Link
                  to="/privacy-policy"
                  style={{ textDecoration: 'underline' }}
                >
                  privacy policy
                </Link>
              )}

              {' and '}
              { isPl2k ? (
                <Link
                  to={{ pathname: PL_TNC }}
                  target="_blank"
                  style={{ textDecoration: 'underline' }}
                >
                  terms of use
                </Link>
              ) : (
              <Link
                to="/terms-conditions"
                style={{ textDecoration: 'underline' }}
              >
                terms of use
              </Link>
              )}
            </FormLabel>
          </Grid>

          <Grid item xs={12} md={6} mdOffset={3} lg={4} lgOffset={4}>
            <Button
              type="submit"
              color="corporate"
              disabled={loading}
              fullWidth
            >
              Create Account
            </Button>
          </Grid>
        </Grid>

        <Link to="/login" style={{ textDecoration: 'underline' }}>
          Already have an account?
        </Link>
      </form>
    </Container>
  )
}

export default SignUp

SignUp.propTypes = { handleSignup: PropTypes.func.isRequired }
